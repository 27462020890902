import React, { useEffect } from "react";
import { Button } from "primereact/button";

const SelectBillOption = ({ processFn, perGuest = false }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid w-12">
        <div className="field col-12 md:col-4">
          <Button
            label="One Bill"
            icon="pi pi-money-bill"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Single")}
          />
        </div>
        {perGuest && (
          <div className="field col-12 md:col-4">
            <Button
              label="Split Per Guests"
              icon="pi pi-user"
              className="text-2xl p-button-outlined"
              onClick={(e) => processFn("Guests")}
            />
          </div>
        )}
        <div className="field col-12 md:col-4">
          <Button
            label="Two Equal Bills"
            icon="fas fa-dice-two"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Two")}
          />
        </div>
        <div className="field col-12 md:col-4">
          <Button
            label="Three Equal Bills"
            icon="fas fa-dice-three"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Three")}
          />
        </div>
        <div className="field col-12 md:col-4">
          <Button
            label="Four Equal Bills"
            icon="fas fa-dice-four"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Four")}
          />
        </div>
        <div className="field col-12 md:col-4">
          <Button
            label="Five Equal Bills"
            icon="fas fa-dice-five"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Five")}
          />
        </div>
        <div className="field col-12 md:col-4">
          <Button
            label="Six Equal Bills"
            icon="fas fa-dice-six"
            className="text-2xl p-button-outlined"
            onClick={(e) => processFn("Six")}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectBillOption;
