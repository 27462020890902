import React, { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../../common/AppContext";
import Authentication from "../../common/Auth/Authentication";
import Tools from "../../common/Tools";
import AppTools from "../AppTools";
import BPrc from "../business/BusinessPrc";

const AfterPaymentRedirect = (props) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const {
    setError,
    setToken,
    initLookups,
    initConfigs,
    setProfile,
    setParty,
    setHint,
  } = useContext(AppContext);

  const getCredit = (payment) => {
    if (payment.reason === "Entry") return 0;
    let credit = 0;
    if (payment.amount === 1000) {
      credit = 1000;
    } else if (payment.amount === 1000) {
      credit = 1500;
    } else if (payment.amount === 3000) {
      credit = 5000;
    } else if (payment.amount === 5000) {
      credit = 10000;
    } else if (payment.amount === 10000) {
      credit = 30000;
    } else {
    }
    return credit;
  };

  const saveInfo = (party, credit, token) => {
    let data = {
      ...party,
      credit: credit + party.credit,
    };

    fetch(process.env.REACT_APP_HOST_URL + "party", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          setHint("Your profile is updated.");
          navigate("/profile");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("Error:", error);
      });
  };

  useEffect(() => {
    if (
      searchParams.get("payment_intent_client_secret") != null &&
      searchParams.get("redirect_status") === "succeeded"
    ) {
      BPrc.retrievePayment(
        searchParams.get("payment_intent_client_secret"),
        null,
        (msg) => setError(msg),
        (payment) => {
          if (
            payment.party_id != null &&
            payment.token != null &&
            payment.token.length !== 0
          ) {
            // Login the user back
            Authentication.retrieveLogin(
              payment.token,
              (msg) => {
                setError(msg);
                navigate("/");
              },
              (pr, pa) => {
                setToken(payment.token);
                initLookups(payment.token);
                if (pr.role === AppTools.BUSINESS) {
                  initConfigs(payment.token);
                }
                setProfile({ ...pr });
                setParty({ ...pa, credit: pa.credit + getCredit(payment) });
                if (payment.campaign_qr != null && payment.reason === "Entry") {
                  // Redirect user to play page
                  navigate(
                    "/play?paid=" +
                      payment.secret +
                      "&qr=" +
                      payment.campaign_qr
                  );
                } else if (payment.reason === "Credit") {
                  saveInfo(pa, getCredit(payment), payment.token);
                } else {
                  navigate("/");
                }
              }
            );
          } else {
            if (payment.campaign_qr != null && payment.reason === "Entry") {
              navigate(
                "/play?paid=" + payment.secret + "&qr=" + payment.campaign_qr
              );
            } else {
              navigate("/");
            }
          }
        }
      );
    } else {
      setError("Payment failed! please try again.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default AfterPaymentRedirect;
