import { Fragment } from "react";

const CRUDRow = ({ fld, lpos = "before", obj = null, children }) => {
  const mandatoryCheck = () => {
    if (typeof fld.mandatory === "boolean" && fld.mandatory) return true;
    if (
      typeof fld.mandatory === "function" &&
      obj !== null &&
      typeof obj[fld.field] !== "undefined" &&
      fld.mandatory(obj)
    )
      return true;
    return false;
  };

  const getLable = (where) => {
    if (where === "b" && lpos === "before")
      return (
        <Fragment>
          <label htmlFor="nickname1" className="font-medium">
            {fld.label}
          </label>
          {mandatoryCheck() && (
            <span className="font-medium text-orange-500"> *</span>
          )}
        </Fragment>
      );
    if (where === "a" && lpos === "after")
      return (
        <Fragment>
          <label htmlFor="nickname1" className="font-medium">
            {fld.label}
          </label>
          {mandatoryCheck() && (
            <span className="font-medium text-orange-500"> *</span>
          )}
        </Fragment>
      );
  };

  if (typeof fld.visible === "boolean" && !fld.visible) return "";
  if (typeof fld.visible === "function" && obj != null && !fld.visible(obj))
    return "";
  return (
    <Fragment>
      {typeof fld.newline !== "undefined" && fld.newline && (
        <div className="div col-12"></div>
      )}
      <div
        className={
          "field col-12" +
          (fld.cols === 2
            ? " md:col-6"
            : fld.cols === 3
            ? " md:col-4"
            : fld.cols === 4
            ? " md:col-3"
            : "") +
          (fld.type === "TOG" ? " flex align-items-center" : "")
        }
      >
        {getLable("b")}
        {children}
        {getLable("a")}
      </div>
    </Fragment>
  );
};

export default CRUDRow;
