import Tools from "../../common/Tools";

const BPrc = {
  doNothing: () => {},
  blockFn: null,

  setBlockFn: (f) => {
    BPrc.blockFn = f;
  },

  getCampaignType: (mode) => {
    return mode === 0
      ? "Spin & Win"
      : mode === 1
      ? "Stamp"
      : mode === 2
      ? "Draw"
      : "Discount";
  },

  getDiscountCampaign: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/getDiscountCampaign", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.discount);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  createPayment: (
    amount,
    campaign,
    email,
    phone,
    party,
    reason,
    token,
    onError,
    onSuccess
  ) => {
    let data = {
      amount,
      campaign,
      email,
      phone,
      party,
      reason,
      testing: true,
    };

    fetch(process.env.REACT_APP_HOST_URL + "payment/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.stripe);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  retrievePayment: (secret, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "payment/" + secret, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.payment);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  registerBusiness: (business, token, onError, onSuccess) => {
    const data = { ...business };

    fetch(process.env.REACT_APP_HOST_URL + "party/registerSignUp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.business);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  bookDemo: (email, onError, onSuccess) => {
    const data = { email };

    fetch(process.env.REACT_APP_HOST_URL + "admin/requestDemo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError("System Error, try again later.");
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },
  businessesAroundMe: (coordinate, onError, onSuccess) => {
    const data = { lat: coordinate.latitude, lng: coordinate.longitude };

    fetch(process.env.REACT_APP_HOST_URL + "party/businessesAroundMe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.list);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  registerOrUpdateBusiness: (business, onError, onSuccess) => {
    const data = { ...business };

    fetch(process.env.REACT_APP_HOST_URL + "party/businessSignUp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.business, data.token);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  addOrUpdateCampaign: (campaign, prizes, token, onError, onSuccess) => {
    let data = {
      campaign: campaign.id,
      qr: campaign.qr,
      name: campaign.name,
      mode: campaign.mode,
      run_interval: campaign.run_interval,
      start: null,
      end: null,
      costlimit: campaign.costlimit,
      entry_cost: campaign.entry_cost,
      status: 1,
      commission: campaign.commission,
      entry: campaign.entry,
      items: [],
    };

    for (let i in prizes) {
      let prize = prizes[i];
      let p = {
        prize: prize.name,
        price: prize.cost,
        odds: prize.odds,
        limits: prize.max,
        id: prize.fresh ? 0 : prize.id,
      };

      data.items.push(p);
    }

    fetch(process.env.REACT_APP_HOST_URL + "campaign/addOrUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  emailCampaignQR: (qr, token, onError, onSuccess) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/emailCampaignQR", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  emailCampaignQR2: (qr, token) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/emailCampaignQR", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    });
  },

  endCampaign: (qr, token, onError, onSuccess) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/endCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  runCampaign: (qr, token, onError, onSuccess) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/runDrawCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.winners, data.id);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  archiveCampaign: (qr, token, onError, onSuccess) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/archiveCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  CloneCampaign: (qr, token, onError, onSuccess) => {
    let data = {
      campaign: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/cloneCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  getResult: (cqr, bqr, phone, email, onError, onSuccess) => {
    let data = {
      cqr,
      bqr,
      phone,
      email,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/getWinings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.customer, data.business, data.result, data.stamp);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  // redeemPrize: (qr, amount, token, onError, onSuccess) => {
  //   let data = {
  //     qr,
  //     amount,
  //   };

  //   fetch(process.env.REACT_APP_HOST_URL + "campaign/claimWinningCampaign", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       token: token,
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.code === Tools.OK) {
  //         onSuccess(data.redeemed_at);
  //       } else {
  //         onError(data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       onError("Error:", error);
  //     });
  // },

  sendPrize: (method, customer, prize, onError, onSuccess) => {
    let data = {
      method,
      customer,
      prize,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/sendPrize", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  runPaidCampaign: (secret, onError, onSuccess) => {
    let data = {
      secret,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/runPaidCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  runCampaignForGuest: (
    fromBusiness,
    player,
    add,
    CamQr,
    token,
    onError,
    onSuccess
  ) => {
    let data = {
      campaign: CamQr,
      customer_phone: player.phone,
      customer_email: player.email,
      customer_firstname: player.firstname,
      customer_lastname: player.lastname,
      add: add,
      amount: player.amount,
      fromBusiness: fromBusiness,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/enterCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  runCampaignForClient: (
    fromBusiness,
    cusQr,
    CamQr,
    amount,
    token,
    onError,
    onSuccess
  ) => {
    let data = {
      campaign: CamQr,
      customer: cusQr,
      amount,
      fromBusiness: fromBusiness,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/enterCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  claimPrize: (qr, token, onError, onSuccess) => {
    let data = {
      qr: qr,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/claimWinningCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  claimSPrize: (campaign, item, customer, token, onError, onSuccess) => {
    let data = {
      campaign,
      item,
      customer,
    };

    fetch(process.env.REACT_APP_HOST_URL + "campaign/claimStampCampaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.prize);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  getCampaign: (qr, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + `campaign/getCampaignInfo/${qr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getPrizeInfo: (qr, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + `campaign/getPrizeInfo/${qr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getMyCampaigns: (qr, archived, token, onError, onSuccess) => {
    fetch(
      process.env.REACT_APP_HOST_URL +
        `campaign/getBusinessCampaigns/${qr}?noarchived=${!archived}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: null,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.campaigns);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },
};
export default BPrc;
