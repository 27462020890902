import React, { useState, useEffect } from "react";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

const ExceptionPage = ({ item, processFn }) => {
  const [exception] = useState(
    item.exception == null ? null : JSON.parse(item.exception)
  );
  const [fromTime, setFromTime] = useState(
    exception == null ? "00:00" : exception.fromTime
  );
  const [toTime, setToTime] = useState(
    exception == null ? "23:59" : exception.toTime
  );
  const [price, setPrice] = useState(
    exception == null ? item.price : exception.price
  );

  const [weekday, setWeekday] = useState([
    exception == null ? false : exception.weekday[0],
    exception == null ? false : exception.weekday[1],
    exception == null ? false : exception.weekday[2],
    exception == null ? false : exception.weekday[3],
    exception == null ? false : exception.weekday[4],
    exception == null ? false : exception.weekday[5],
    exception == null ? false : exception.weekday[6],
  ]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExceptionStr = () => {
    let s =
      "From " +
      fromTime +
      " to " +
      toTime +
      ((weekday[0] &&
        weekday[1] &&
        weekday[2] &&
        weekday[3] &&
        weekday[4] &&
        weekday[5] &&
        weekday[6]) ||
      (!weekday[0] &&
        !weekday[1] &&
        !weekday[2] &&
        !weekday[3] &&
        !weekday[4] &&
        !weekday[5] &&
        !weekday[6])
        ? " every day of the week"
        : " on " +
          (weekday[1] ? " Monday" : "") +
          (weekday[2] ? " Tuesday" : "") +
          (weekday[3] ? " Wednesday" : "") +
          (weekday[4] ? " Thursday" : "") +
          (weekday[5] ? " Friday" : "") +
          (weekday[6] ? " Saturday" : "") +
          (weekday[6] ? " Sunday" : ""));

    return s;
  };

  return (
    <div className="flex justify-content-center w-12">
      <div className="grid formgrid p-fluid">
        <div className="field col-12">
          <span className="text-orange-600 text-lg">
            {price === item.price
              ? "No price exception is defined"
              : "Price exception: " + getExceptionStr()}
          </span>
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Monday</label>
          <InputSwitch
            className="flex"
            checked={weekday[1]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[1] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Tuesday</label>
          <InputSwitch
            className="flex"
            checked={weekday[2]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[2] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Wednesday</label>
          <InputSwitch
            className="flex"
            checked={weekday[3]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[3] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Thursday</label>
          <InputSwitch
            className="flex"
            checked={weekday[4]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[4] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Friday</label>
          <InputSwitch
            className="flex"
            checked={weekday[5]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[5] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Saturday</label>
          <InputSwitch
            className="flex"
            checked={weekday[6]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[6] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-6 md:col-3">
          <label className="flex">Sunday</label>
          <InputSwitch
            className="flex"
            checked={weekday[0]}
            onChange={(e) => {
              let newV = [...weekday];
              newV[0] = e.value;
              setWeekday(newV);
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="flex">From:</label>
          <InputMask
            value={fromTime}
            onChange={(e) => setFromTime(e.value)}
            mask="99:99"
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="flex">To:</label>
          <InputMask
            value={toTime}
            onChange={(e) => setToTime(e.value)}
            mask="99:99"
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="flex">Price:</label>
          <InputNumber
            mode="currency"
            currency="CAD"
            value={price}
            onChange={(e) => {
              setPrice(e.value);
            }}
          />
        </div>
        <div className="field col-12 md:col-3">
          <Button
            label="Save"
            className="p-button-success p-button-outlined"
            icon="pi pi-check"
            onClick={(e) => processFn({ weekday, fromTime, toTime, price })}
          />
        </div>
      </div>
    </div>
  );
};

export default ExceptionPage;
