import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import QrScanner from "qr-scanner";
import { Dialog } from "primereact/dialog";
import LoadingPage from "./LoadingPage";

function WScanner(
  { header = "QR code", readyFn, errorFn, scanFn, abortFn },
  ref
) {
  const [ready, setReady] = useState(false);
  const [wMsg, setWMsg] = useState(null);
  const [scan, setScan] = useState(false);
  const [scanner, setScanner] = useState(null);

  useImperativeHandle(ref, () => ({
    start: () => {
      setScan(true);
    },
    stop: () => {
      setScan(false);
      setReady(true);
    },
  }));

  useEffect(() => {
    setWMsg("Checking your camera ...");
    (async () => {
      let hasCamera = await QrScanner.hasCamera();
      if (hasCamera) {
        setReady(true);
        readyFn();
      } else {
        setReady(true);
        setTimeout(() => {
          errorFn();
        }, 5000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return <LoadingPage message={wMsg} />;
  return (
    <div className="flex justify-content-center">
      <Dialog
        dlgLabel="Display"
        position="top"
        visible={scan}
        style={{ width: "440px" }}
        header={"Camera - Scan " + header}
        onHide={() => {
          scanner.stop();
          setScanner(null);
          setScan(false);
          abortFn();
        }}
        onShow={() => {
          const aScanner = new QrScanner(
            document.getElementById("QAScanVideo"),
            (result) => {
              let qr = result;
              let pos = qr.indexOf("=");
              if (pos !== -1) {
                qr = qr.substring(pos + 1);
              }
              aScanner.stop();
              setScanner(null);
              setScan(false);
              setWMsg("Processing ...");
              setReady(false);
              scanFn(qr);
            }
          );

          aScanner.start();
          setScanner(aScanner);
        }}
      >
        <video
          id="QAScanVideo"
          style={{ width: "400px", height: "225px", backgroundColor: "red" }}
        ></video>
      </Dialog>
    </div>
  );
}

// eslint-disable-next-line no-func-assign
WScanner = forwardRef(WScanner);

export default WScanner;
