import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { AdminContext } from "./POSAdminContextProvider";

import { DataTable } from "primereact/datatable";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import LoadingPage from "../../common/Utils/LoadingPage";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import POSAdminPrc from "./POSAdminPrc";

const MenuTree = ({ deal }) => {
  const { token, setError, setHint } = useContext(AppContext);

  const { menus, menuTree, findMenu } = useContext(AdminContext);

  const [showSearch, setShowSearch] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const [dealMenus, setDealMenus] = useState([]);
  const [selectedSourceMenus, setSelectedSourceMenus] = useState([]);
  const [selectedDealMenus, setSelectedDealMenus] = useState([]);

  useEffect(() => {
    POSAdminPrc.getDealMenus(
      deal.id,
      token,
      (message) => setError(message),
      (data) => {
        let addToSellected = [];
        data.forEach((m) => {
          let menu = findMenu(m.menu_id, menus);
          addToSellected.push({
            key: addToSellected.length + "-" + menu.id,
            data: { name: menu.name, ...m },
          });
        });
        //updateDMInfo(addToSellected, data);
        setDealMenus(
          addToSellected.sort((a, b) => a.data.group_id - b.data.group_id)
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOut = (key, head) => {
    let newHead = head.filter((a) => a.key !== key);
    if (head.length !== newHead.length) {
      let pos = 0;
      for (; pos !== head.length; pos++) {
        if (head[pos].key === key) break;
      }
      head.splice(pos, 1);
    } else
      head.forEach((node) => {
        if (typeof node.children !== "undefined") filterOut(key, node.children);
      });
  };

  const removeFromDeal = () => {
    let sMmenus = Object.values(selectedDealMenus);

    if (sMmenus.length === 0) {
      setError(
        "Please select items from menu item list to remove from the deal"
      );
      return;
    }
    let newSelected = [...dealMenus];
    sMmenus.forEach((node) => filterOut(node.key, newSelected));
    setSelectedDealMenus([]);
    setDealMenus(newSelected);
    setShowSave(true);
  };

  const addToMenu = () => {
    let sMenus = Object.keys(selectedSourceMenus);

    if (sMenus.length === 0) {
      setError("Please select items from available list to add to the deal");
      return;
    }
    let addToSellected = [...dealMenus];
    sMenus.forEach((key) => {
      let menu = findMenu(parseInt(key), menus);
      addToSellected.push({
        key: addToSellected.length + "-" + menu.id,
        changed: true,
        data: {
          name: menu.name,
          count: 1,
          free_options: 0,
          group_id: addToSellected.length,
          id: 0,
          optional: false,
          deal_id: deal.id,
          menu_id: menu.id,
          price: 0,
          upgrade: "",
        },
      });
    });
    setSelectedSourceMenus([]);
    setShowSave(true);
    setDealMenus(
      addToSellected.sort((a, b) => a.data.group_id - b.data.group_id)
    );
  };

  const getUpgradeOption = (key) => {
    return dealMenus
      .filter((dm) => dm.key !== key)
      .map((dm) => {
        return { name: dm.data.name, code: dm.data.menu_id };
      });
  };

  const findInSelMenus = (n, head) => {
    if (typeof head === "undefined") return null;
    let node = head.find((i) => i.key === n);
    if (typeof node === "undefined") {
      for (let i in head) {
        node = findInSelMenus(n, head[i].children);
        if (node != null) {
          return node;
        }
      }
    } else {
      return node;
    }
    return null;
  };

  const priceTemplate = (node) => {
    return cellTemplate(node, "price");
  };
  const freeOptionTemplate = (node) => {
    return cellTemplate(node, "free_options");
  };
  const groupTemplate = (node) => {
    return cellTemplate(node, "group_id");
  };
  const upgradeTemplate = (node) => {
    if (node.data["upgrade"].length === 0) return "";
    else
      return (
        <span className={node.changed ? "text-pink-600" : ""}>
          {findMenu(node.data["upgrade"], menus).name}
        </span>
      );
  };
  const nameTemplate = (node) => {
    return cellTemplate(node, "name");
  };

  const cellTemplate = (node, field) => {
    return (
      <span className={node.changed ? "text-pink-600" : ""}>
        {node.data[field]}
      </span>
    );
  };

  const cellEditor = (props) => {
    if (props.field === "optional")
      return (
        <InputSwitch
          checked={props.rowData.data[props.field]}
          onChange={(e) => {
            updateDealMenus(dealMenus, props, e.value);
          }}
        />
      );
    if (props.field === "price")
      return (
        <InputNumber
          mode="currency"
          currency="CAD"
          inputClassName="text-xs"
          size={7}
          value={props.rowData.data[props.field]}
          onChange={(e) => {
            updateDealMenus(dealMenus, props, e.value);
          }}
        />
      );
    if (props.field === "upgrade")
      return (
        <Dropdown
          value={props.rowData.data[props.field]}
          options={getUpgradeOption(props.rowData.key)}
          onChange={(e) => updateDealMenus(dealMenus, props, e.value)}
          optionLabel="name"
          optionValue="code"
        />
      );
    if (
      props.field === "free_options" ||
      props.field === "count" ||
      props.field === "group_id"
    )
      return (
        <InputNumber
          mode="decimal"
          inputClassName="text-xs"
          size={7}
          value={props.rowData.data[props.field]}
          onChange={(e) => {
            updateDealMenus(dealMenus, props, e.value);
          }}
        />
      );
  };

  const updateDealMenus = (dealMenus, props, value) => {
    let newS = JSON.parse(JSON.stringify(dealMenus));
    let node = findInSelMenus(props.rowData.key, newS);
    node.data[props.field] = value;
    node.changed = true;
    setShowSave(true);
    setDealMenus(newS);
  };

  const getDMObject = (node) => {
    let obj = {
      deal_id: deal.id,
      menu_id: node.data.menu_id,
      price: 0,
      free_options: 0,
      count: 0,
      group_id: 0,
      upgrade: "",
      optional: false,
    };
    if (typeof node.data.price !== "undefined") obj.price = node.data.price;
    if (typeof node.data.free_options !== "undefined")
      obj.free_options = node.data.free_options;
    if (typeof node.data.count !== "undefined") obj.count = node.data.count;
    if (typeof node.data.group_id !== "undefined")
      obj.group_id = node.data.group_id;
    if (typeof node.data.upgrade !== "undefined")
      obj.upgrade = node.data.upgrade;
    if (typeof node.data.optional !== "undefined")
      obj.optional = node.data.optional;

    return obj;
  };

  const makeItFlat = (head, flat) => {
    head.forEach((node) => {
      flat.push(getDMObject(node));
      if (typeof node.children !== "undefined") makeItFlat(node.children, flat);
    });
  };
  const markAllUnchanged = (head) => {
    head.forEach((node) => {
      node.changed = false;
      if (typeof node.children !== "undefined") markAllUnchanged(node.children);
    });
  };
  const saveData = () => {
    let toBeSaved = [];
    makeItFlat(dealMenus, toBeSaved);
    POSAdminPrc.updateDealMenu(
      toBeSaved,
      deal.id,
      token,
      (message) => setError(message),
      () => {
        setHint("Deal is updated.");
        setShowSave(false);
        markAllUnchanged(dealMenus);
        setDealMenus(JSON.parse(JSON.stringify(dealMenus)));
      }
    );
  };

  if (menuTree == null) return <LoadingPage />;
  return (
    <div
      className="flex justify-content-around align-content-center"
      style={{ height: "750px" }}
    >
      <div className="flex  flex-column">
        <h3>All available Menus</h3>
        <TreeTable
          value={menuTree}
          selectable={true}
          scrollable
          selectionMode="multiple"
          selectionKeys={selectedSourceMenus}
          onSelectionChange={(e) => setSelectedSourceMenus(e.value)}
          scrollHeight="550px"
          style={{ width: "400px" }}
          //className="w-5"
        >
          <Column
            field="name"
            header="Name"
            expander
            filter={showSearch}
          ></Column>
        </TreeTable>
      </div>
      <div className="flex flex-column">
        <div className="flex flex-grow-1 flex-column justify-content-center align-content-center">
          <Button
            icon="pi pi-search text-3xl "
            className="p-button-text "
            onClick={(e) => setShowSearch(!showSearch)}
          />
          <Button
            icon="pi pi-chevron-circle-right text-3xl "
            className="p-button-text "
            onClick={(e) => addToMenu()}
          />
          <Button
            icon="pi pi-chevron-circle-left text-3xl "
            className="p-button-text "
            onClick={(e) => removeFromDeal()}
          />
          <Button
            icon="pi pi-save text-3xl "
            className="p-button-text "
            onClick={(e) => saveData()}
            disabled={!showSave}
          />
        </div>{" "}
      </div>
      <div className="flex flex-column">
        <h3>Menus in {deal.name}</h3>
        <DataTable
          value={dealMenus}
          draggable={true}
          selectionMode="multiple"
          selection={selectedDealMenus}
          onSelectionChange={(e) => setSelectedDealMenus(e.value)}
          scrollable
          scrollHeight="550px"
          style={{ width: "900px" }}
        >
          <Column
            field="name"
            header="Name"
            body={nameTemplate}
            expander
            style={{ width: "250px" }}
          ></Column>
          <Column
            field="price"
            editor={cellEditor}
            body={priceTemplate}
            header="Price"
          ></Column>
          <Column
            field="free_options"
            editor={cellEditor}
            body={freeOptionTemplate}
            header="Free Options"
          ></Column>
          {/* <Column
            field="count"
            editor={cellEditor}
            body={countTemplate}
            header="Count"
          ></Column> */}
          <Column
            field="upgrade"
            editor={cellEditor}
            body={upgradeTemplate}
            header="Upgrade"
          ></Column>
          <Column
            field="group_id"
            editor={cellEditor}
            body={groupTemplate}
            header="Group"
          ></Column>
          {/* <Column
            field="optional"
            body={optionalTemplate}
            editor={cellEditor}
            header="Optional"
          ></Column> */}
        </DataTable>
      </div>
    </div>
  );
};

export default MenuTree;
