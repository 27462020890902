import React, { useState, useContext, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { AppContext } from "../../common/AppContext";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import Tools from "../../common/Tools";
import LoadingPage from "../../common/Utils/LoadingPage";
//import ReactPasswordStrength from "react-password-strength";
import LocationSearchFInput from "../../common/Utils/LocationSearchFInput";
import { InputNumber } from "primereact/inputnumber";
import BPrc from "./BusinessPrc";
import AdminPrc from "../../common/AdminPrc";
import { Chips } from "primereact/chips";
import { SocialIcon } from "react-social-icons";

function AdminRegisterForm() {
  const { token, setError, setHint, profile } = useContext(AppContext);
  const [errorsHint, setErrorsHint] = useState("");
  const [busy, setBusy] = useState(false);
  const [category, setCategory] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState(" ");
  const [name, setName] = useState("");
  const [commission, setCommission] = useState(5);
  const [discount, setDiscount] = useState(20);
  const [address, setAddress] = useState("");
  const [coordinate, setCoordinate] = useState(null);
  const [phone, setPhone] = useState("");
  const [device, setDevice] = useState(null);
  const [bCategories, setBCategories] = useState([]);
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();

  const invalidData = () => {
    let tst = Tools.validateEmail(username);
    tst = tst && name.length > 2;
    tst = tst && password_confirmation === password;
    tst = tst && coordinate != null;
    tst = tst && category.length !== 0;
    tst = tst && Tools.validatePhone(phone);
    tst = tst && device != null;
    return !tst;
  };

  useEffect(() => {
    if (!Tools.validateEmail(username)) {
      setErrorsHint("Invalid email address.");
    } else if (name.length < 3) {
      setErrorsHint("Business name has to be more than 3 characters.");
    } else if (password_confirmation !== password) {
      setErrorsHint("Passwords don't match");
    } else if (coordinate == null) {
      setErrorsHint("Invalid business address.");
    } else if (category.length === 0) {
      setErrorsHint("Please select a business category.");
    } else if (!Tools.validatePhone(phone)) {
      setErrorsHint("Invalid phone number.");
    } else if (device == null) {
      setErrorsHint("Invalid tablet option.");
    } else {
      setErrorsHint(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    username,
    password,
    password_confirmation,
    coordinate,
    category,
    phone,
    name,
    device,
  ]);

  useEffect(() => {
    AdminPrc.findEntity(
      "Lookup",
      { fields: ["cat"], values: ["BTP"] },
      token,
      (msg) => setError(msg),
      (data) => {
        setBCategories(
          data
            .sort((c1, c2) => c1.item.localeCompare(c2.item))
            .map((c) => {
              return { name: c.item, code: c.item };
            })
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goHome = () => {
    navigate("/");
  };

  const callSignup = () => {
    setBusy(true);

    let data = {
      email: username,
      name,
      password,
      phone,
      address,
      coordinate,
      commission,
      category,
      discount,
      device: device.code,
      options: options.join(","),
      organization: profile.organization,
    };

    BPrc.registerBusiness(
      data,
      token,
      (msg) => {
        setBusy(false);
        setError(msg);
      },
      (data) => {
        Tools.processEmail();
        setHint("This business is registered now.");
        setBusy(false);
        navigate("/");
      }
    );
  };

  const socialMediaItem = (sm) => {
    return (
      <div>
        <span className="mr-2">{sm}</span>
        <SocialIcon url={sm} />
      </div>
    );
  };

  if (busy)
    return (
      <LoadingPage message="Please wait while we are registering you ..." />
    );
  else
    return (
      <div className="flex align-items-center justify-content-center mt-3">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Registering a business
            </div>
          </div>

          <div className="grid formgrid p-fluid">
            <div className="field col-12 md:col-6">
              <label className="text-900 font-medium mb-2">Email</label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                autoComplete="off"
                value={username.trimStart()}
                keyfilter="email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Business Name</label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                autoComplete="off"
                value={name.trimStart()}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="password" className="text-900 font-medium mb-2">
                Password
              </label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                type="password"
                autoComplete="off"
                value={password.trimStart()}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <ReactPasswordStrength
              minLength={8}
              changeCallback={(a, b) => {
                setPassword(a.password);
                setPassIsValid(a.isValid);
              }}
              inputProps={{ autoComplete: "off", className: "w-full" }}
            /> */}
            </div>
            <div className="field col-12 md:col-6">
              <label className="text-900 font-medium mb-2">
                Confirm Password
              </label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                autoComplete="off"
                type="password"
                value={password_confirmation.trimStart()}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Address</label>
              <span className="font-medium text-orange-800"> *</span>
              <LocationSearchFInput
                value={address}
                setLatLng={(latlng, v) => {
                  setAddress(v);
                  setCoordinate(latlng);
                }}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Category</label>
              <Dropdown
                //value={myParty.category}
                value={bCategories.find((c) => c.code === category)}
                options={bCategories}
                onChange={(e) => {
                  setCategory(e.value.code);
                }}
                optionLabel="name"
                placeholder="Select a category"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Phone</label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                keyfilter="pnum"
                autoComplete="off"
                value={phone.trimStart()}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                onBlur={(e) => {
                  setPhone(phone.replace(/\D+/g, ""));
                }}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Commission</label>
              <InputNumber
                value={commission}
                step={1}
                autoComplete="off"
                mode="decimal"
                showButtons
                min={1}
                max={100}
                onValueChange={(e) => setCommission(e.value)}
                prefix="%"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Customer Discount</label>
              <InputNumber
                value={discount}
                step={1}
                autoComplete="off"
                mode="decimal"
                showButtons
                min={1}
                max={100}
                onValueChange={(e) => setDiscount(e.value)}
                prefix="%"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Tablet option</label>
              <span className="font-medium text-orange-800"> *</span>
              <Dropdown
                value={device}
                options={[
                  { name: "Own Device", code: "O" },
                  { name: "Paid Tablet", code: "P" },
                  { name: "Rent To Own Tablet", code: "R" },
                ]}
                onChange={(e) => {
                  setDevice(e.value);
                }}
                optionLabel="name"
                placeholder="Running on"
              />
            </div>
            <div className="field col-12">
              <label className="font-medium">Social network and URLs</label>
              <Chips
                value={options}
                onAdd={(e) => {
                  if (
                    e.value.toUpperCase().indexOf("HTTP://") === -1 &&
                    e.value.toUpperCase().indexOf("HTTPS://") === -1
                  ) {
                    setError("URL should start with http:// or https://");
                    return false;
                  }
                }}
                onChange={(e) => {
                  setOptions(e.target.value);
                }}
                itemTemplate={socialMediaItem}
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button
                label="Register"
                icon="pi pi-user-plus"
                className="p-button-success w-full"
                disabled={busy}
                onClick={callSignup}
                {...(invalidData() ? { disabled: true } : {})}
              />
              <span className="font-medium text-orange-800">{errorsHint}</span>
            </div>
            <div className="field col-12 md:col-6">
              <Button
                label="Back"
                icon="pi pi-times"
                className="p-button-warning w-full"
                onClick={goHome}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default AdminRegisterForm;
