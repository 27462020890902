import { EncryptStorage } from "encrypt-storage";
import { Button } from "primereact/button";

const Tools = {
  YES: "YES",
  NO: "NO",
  TEMP: "TEMP",
  OK: "OK",
  NOTFOUND: "NF",
  ERROR: "ER",
  ADMIN: "ADMIN",
  // hostURL: "http://localhost:8000/",
  // hostImageURL: "http://localhost:8000/profilephotos/",

  lookups: [],

  doNothing: () => {},

  formatter: new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }),

  secondsToDhms: (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    // var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    var dDisplay = d > 0 ? d + "D " : "";
    var hDisplay = h > 0 ? h + "H " : "";
    var mDisplay = m > 0 ? m + "M " : "";
    var sDisplay = s > 0 ? s + "S " : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  },

  encryptStorage: new EncryptStorage("BSmarterSecretKey", {
    prefix: "@AHP",
  }),

  currencyFormat: (num) => {
    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  getCookie: (key) => {
    const value = Tools.encryptStorage.getItem(key);
    if (value == null) {
      return "";
    }
    return value;
  },
  setCookie: function (key, value) {
    if (value == null || value === "") {
      Tools.encryptStorage.removeItem(key);
    } else {
      Tools.encryptStorage.setItem(key, value);
    }
  },
  validateEmail: function (email) {
    if (
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  },
  validatePhone: function (phone) {
    if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone)) {
      return true;
    }
    return false;
  },
  isDate: function (date) {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  },
  getCardTitle: function (label, editFunction, view = false) {
    return (
      <div className="p-grid">
        <div className="p-col">
          <h5 className="gap0">{label}</h5>
        </div>
        {view && (
          <div className="p-col-fixed p-text-right" style={{ width: "100px" }}>
            <i
              class="far fa-edit pointer"
              onClick={(e) => {
                editFunction();
              }}
            ></i>
          </div>
        )}
      </div>
    );
  },

  formatPhoneNumber: (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  },

  getDefaultImage: () =>
    process.env.REACT_APP_HOST_IMAGE_URL + "placeholder.png",

  getNoImage: () => process.env.REACT_APP_HOST_IMAGE_URL + "placeholder2.png",

  getImagePath: (id, tag) => {
    let fileName =
      process.env.REACT_APP_HOST_IMAGE_URL +
      tag +
      "/" +
      Math.floor(id / 1000) +
      "/" +
      id +
      (tag === "flyer" ? ".pdf" : ".jpg?dummy=" + Math.random());
    return fileName;
  },

  getMediaPath: (media, etype, eid, id) => {
    return (
      (media === "vid"
        ? process.env.REACT_APP_HOST_VIDEO_URL
        : process.env.REACT_APP_HOST_AUDIO_URL) +
      etype +
      "/" +
      Math.floor(eid / 1000) +
      "/" +
      media +
      "_" +
      (eid % 1000) +
      "_" +
      id +
      (media === "vid" ? ".webm" : ".wav")
    );
  },

  uploadImage: (file, id, tag, token, onError, onSuccess) => {
    const data = new FormData();
    data.append("image", file);
    data.append("id", id);
    data.append("token", token);
    data.append("tag", tag);

    fetch(process.env.REACT_APP_HOST_URL + "helper/storeimage", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  fetchWithAuthentication: function (url) {
    //const authToken = "cGVyZmVjdC1tYXRjaDpnYW5kb20xODk=";
    const authToken = "YW1pcjpnYW5kb20=";
    const headers = new Headers();
    headers.set("Authorization", "Basic " + authToken);
    return fetch(url, { headers });
  },

  displayProtectedImage: async function (imageElement, imageUrl) {
    // Fetch the image.
    if (imageElement == null) return;
    const response = await Tools.fetchWithAuthentication(imageUrl);

    // Create an object URL from the data.
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    // Update the source of the image.
    imageElement.src = objectUrl;
    imageElement.onload = () => URL.revokeObjectUrl(objectUrl);
  },

  getProtectedImageUrl: async function (imageUrl) {
    // Fetch the image.
    const response = await Tools.fetchWithAuthentication(imageUrl);

    // Create an object URL from the data.
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  },

  validateKeys(keys) {
    let ukeys = keys.map((k) => k.toUpperCase());
    return [...new Set(ukeys)];
  },

  getMultiMedia: (entity, id, key, token, onError, onSuccess) => {
    fetch(
      process.env.REACT_APP_HOST_URL + entity + "/" + id + "/multimedia/" + key,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: null,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  storeMultimedia: (
    entity,
    id,
    key,
    keywords,
    description,
    cat,
    toPublic,
    media,
    token,
    onError,
    onSuccess,
    action = "UPD"
  ) => {
    const data = new FormData();
    if (media !== null) {
      if (cat === 2) {
        data.append("data", media);
        data.append("video", null);
      } else {
        data.append("video", media);
        data.append("data", null);
      }
    }
    data.append("token", token);
    data.append("id", id);
    data.append("category", cat);
    data.append("key", key);
    data.append("action", action);
    data.append("keywords", keywords.toUpperCase());
    data.append("description", description);
    data.append("toPublic", toPublic ? 1 : 0);

    fetch(process.env.REACT_APP_HOST_URL + entity + "/multimedia", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  formatDate: (aDate) => {
    if (aDate == null || aDate === "" || !Tools.isDate(aDate)) return null;
    let dt = new Date(aDate);
    return `${(dt.getMonth() + 1).toString().padStart(2, "0")}/${dt
      .getDate()
      .toString()
      .padStart(2, "0")}/${dt.getFullYear().toString().padStart(4, "0")} @${dt
      .getHours()
      .toString()
      .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  },

  getDateFormat: (aDate, len = 10) => {
    if (aDate == null || aDate === "" || !Tools.isDate(aDate)) return null;
    return new Date(aDate).toISOString().substring(0, len);
  },

  justifyTemplate: (option) => {
    if (option === "Yes") {
      return (
        <span>
          <i className="pi pi-check-circle greenBG"></i> Yes
        </span>
      );
    } else {
      return (
        <span>
          <i className="pi pi-times-circle redBG"></i> No
        </span>
      );
    }
  },

  //// PlayParty Code ====>

  getParty: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "party", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  processEmail: () => {
    // setTimeout(() => {
    //   fetch(process.env.REACT_APP_HOST_URL + "email/process", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: null,
    //   });
    // }, 5000);
  },

  formatCurrency: (num) => {
    const formatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });

    return formatter.format(num);
  },

  dialogCloseIcon: (func) => (
    <Button
      icon="pi pi-times"
      label="Close"
      onClick={(e) => func(false)}
      className="p-button-rounded p-button-danger p-button-outlined"
    />
  ),
};
export default Tools;
