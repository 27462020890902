import React, { useState, useEffect, useContext } from "react";
import Campaign from "./Campaign";
import { InputText } from "primereact/inputtext";
import { AppContext } from "../../common/AppContext";
import BPrc from "./BusinessPrc";
import { useNavigate } from "react-router-dom";

function RunCampaign() {
  const [campaigns, setCampaigns] = useState([]);
  const { token, setError, setHint, party } = useContext(AppContext);
  const navigate = useNavigate();

  const getCampaigns = () => {
    if (party != null) {
      BPrc.getMyCampaigns(
        party.qr,
        false,
        token,
        (message) => {
          setError(message);
        },
        (data) => {
          if (typeof data === "undefined") {
            setHint(
              "You don't have a campaign yet, define your first one now!"
            );
          } else {
            setCampaigns([...data]);
            if (data.length === 1) {
              navigate(`/play?qr=${data[0].campaign.qr}`);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    getCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 md:p-6 lg:p-8">
      <div className="surface-card" style={{ minHeight: "20rem" }}>
        <div className="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
          <div className="mb-3 lg:mb-0">
            <div className="text-3xl font-medium text-900 mb-3">Campaigns</div>
            <div className="text-500 mr-0 md:mr-3 flex align-items-center">
              Select a campaign to run
            </div>
          </div>
          <span className="p-input-icon-left w-full md:w-auto"></span>
          <span className="p-input-icon-left w-full md:w-auto">
            <i className="pi pi-search"></i>
            <InputText placeholder="Search" className="w-full md:w-auto" />
          </span>
        </div>

        <div className="grid grid-nogutter">
          {campaigns.map((c, index) => (
            <Campaign
              campaignInfo={c}
              key={index}
              run={true}
              token={token}
              setError={setError}
              setHint={setHint}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RunCampaign;
