import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { AdminContext } from "./POSAdminContextProvider";
import { Chip } from "primereact/chip";
import { OverlayPanel } from "primereact/overlaypanel";
import "react-simple-keyboard/build/css/index.css";
import OrderItemMenuOptionAttr from "./OrderItemMenuOptionAttr";

const OrderItemMenuOptionSelector = ({
  oiId,
  allOptions,
  myOptions,
  processFuc,
}) => {
  const {
    modifiers,
    modTree,
    findModifier,
    addToRightChild,
    findModInMenu,
    user,
  } = useContext(AdminContext);
  const [mine, setMine] = useState([...myOptions]);
  const [optionTree, setOptionTree] = useState(null);
  const [selectedOptions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const opd = useRef(null);
  const [optDtl, setOptDtl] = useState({
    modifier: {},
    menuMod: {},
    free: false,
    easy: false,
    hlf: false,
    dbl: false,
    trp: false,
    left: false,
    right: false,
    etc_item: null,
    note: "",
  });

  const updateOption = (opt) => {
    let newOptions = mine.filter((o) => o.id !== opt.id);
    if (typeof opt.delete !== "boolean") {
      newOptions.push(opt);
      setOptDtl(opt);
    } else {
      opd.current.hide();
    }
    setMine([...newOptions]);
  };

  const setSelectedOptionsPrc = (e) => {
    let mod = findModifier(parseInt(e.value), modifiers);
    let menuMod = allOptions.find((o) => o.modifier_id === parseInt(e.value));
    let option = getSelectedOption(mod.id);
    if (typeof mod.children === "undefined") {
      if (option != null) {
        setOptDtl(option);
        opd.current.toggle(e);
      } else {
        // Check for exclusives
        let newMine = [...mine];
        if (mod.exclusive) {
          newMine = newMine.filter(
            (a) => !a.modifier.exclusive || a.modifier.parent !== mod.parent
          );
        }
        option = {
          id: -1 * (mine.length + 1),
          oiId: oiId,
          modifier: mod,
          menuMod: menuMod,
          free: false,
          easy: false,
          hlf: false,
          dbl: false,
          trp: false,
          left: false,
          right: false,
          etc_item: null,
          note: "",
        };
        setOptDtl(option);
        setMine([...newMine, option]);
      }
    } else {
      let newKeys = [];
      if (expandedKeys[mod.id]) setExpandedKeys([]);
      else {
        newKeys[mod.id] = true;
        setExpandedKeys(newKeys);
      }
    }
  };

  const getSelectedOption = (id) => {
    let found = mine.find((o) => o.modifier.id === id);
    return typeof found !== "undefined" ? found : null;
  };

  const getOptionLable = (id) => {
    let m = mine.find((o) => o.modifier.id === id);
    if (typeof m === "undefined") return "";

    return (
      <Fragment>
        {m.free && <Chip label="Free" className="mr-2" />}
        {m.easy && <Chip label="Easy" className="mr-2" />}
        {m.hlf && <Chip label="Half" className="mr-2" />}
        {m.dbl && <Chip label="Double" className="mr-2" />}
        {m.trp && <Chip label="Triple" className="mr-2" />}
        {m.left && <Chip label="On Left" className="mr-2" />}
        {m.right && <Chip label="On Right" className="mr-2" />}
        {m.etc_item != null && <Chip label={m.etc_item} className="mr-2" />}
        {m.note.length !== 0 && (
          <Chip label={`Note: ${m.note}`} className="mr-2" />
        )}
      </Fragment>
    );
  };

  const selectionTemplate = (node) => {
    if (typeof node.children !== "undefined") return "";
    return (
      <span className="">
        {getSelectedOption(node.data.id) != null ? (
          <i className="pi pi-check-circle text-2xl text-green-500"></i>
        ) : (
          ""
        )}
      </span>
    );
  };

  const optionsTemplate = (node) => {
    if (typeof node.children !== "undefined") return "";
    return getOptionLable(node.data.id);
  };

  useEffect(() => {
    let addToSellected = [];
    allOptions.forEach((m) => {
      let modifier = findModifier(m.modifier_id, modifiers);
      if (typeof modifier.children === "undefined") {
        let mod = findModInMenu(m.modifier_id, modTree, modifier);
        let fs = addToSellected.find((a) => a.key === mod.key);
        if (typeof fs === "undefined") {
          addToSellected.push(mod);
        } else {
          addToRightChild(fs, mod);
        }
      } else {
      }
    });
    setOptionTree(addToSellected);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    processFuc(mine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mine]);

  return (
    <div className="p-fluid w-full">
      <div id="opdSpot"></div>
      <OverlayPanel
        ref={opd}
        showCloseIcon
        style={{
          width: "500px",
          backgroundColor: "rgba(209,110,44,1)",
          borderRadius: "25px",
        }}
        dismissable
        appendTo="self"
      >
        <OrderItemMenuOptionAttr
          optDtl={optDtl}
          updateOption={updateOption}
          user={user}
        />
      </OverlayPanel>
      <TreeTable
        value={optionTree}
        selectable={true}
        scrollable
        selectionMode="single"
        selectionKeys={selectedOptions}
        onSelectionChange={(e) => setSelectedOptionsPrc(e)}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        //scrollHeight="550px"
        //style={{ width: "400px" }}
      >
        <Column
          field="name"
          header="Modifier"
          expander
          style={{ width: "350px" }}
        ></Column>
        <Column
          field="id"
          header="Selected"
          style={{ width: "100px" }}
          body={selectionTemplate}
        ></Column>
        <Column
          field="deleted"
          header="Options"
          //editor={selectionEditor}
          body={optionsTemplate}
        ></Column>
      </TreeTable>{" "}
    </div>
  );
};

export default OrderItemMenuOptionSelector;
