import React, { useState, useEffect, useContext } from "react";

import { Button } from "primereact/button";
import { AppContext } from "../AppContext";
import CRUDBody from "./CRUDBody";
import AdminPrc from "../AdminPrc";

const CRUDForm = ({
  steps = false,
  schema,
  eCls,
  eId = 0,
  eObj = null,
  eName = "Entity",
  saveFunction = null,
  cancelFunction = null,
  hidFields = {},
  token = "TKN-f0a1fb1f256d14959f97d8785154f7e0",
}) => {
  const [obj, setObj] = useState(eObj === null ? { id: 0 } : { ...eObj });
  const [step, setStep] = useState(steps ? 1 : 0);
  const [maxstep, setMaxstep] = useState(steps ? 1 : 0);
  const { setError, setHint } = useContext(AppContext);

  const mandatoryCheck = (fld) => {
    if (typeof fld.mandatory === "boolean" && fld.mandatory) return true;
    if (
      typeof fld.mandatory === "function" &&
      obj !== null &&
      typeof obj[fld.field] !== "undefined" &&
      fld.mandatory(obj)
    )
      return true;
    return false;
  };
  const isDataInvalid = () => {
    let invalid = false;
    schema.forEach((fld) => {
      let val = obj[fld.field];
      if (
        fld.type !== "HID" &&
        mandatoryCheck(fld) &&
        (!val || val === "undefined" || val === "")
      )
        invalid = true;
    });
    return invalid;
  };

  useEffect(() => {
    if (!steps) return;
    schema.forEach((fld) => {
      if (typeof fld.step !== "undefined" && fld.step > maxstep)
        setMaxstep(fld.step);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="surface-section w-full">
      {steps && (
        <div className="grid flex align-items-center mb-3">
          <div className="col-10">
            {/* <span className="font-medium text-2xl text-900">{eName}</span> */}
          </div>
          <div className="col-1">
            {steps && (
              <Button
                className="p-button-outlined p-button-help"
                icon="pi pi-chevron-circle-left"
                onClick={() => {
                  setStep(step - 1);
                }}
                label="Back"
                disabled={step <= 1}
              />
            )}
          </div>
          <div className="col-1">
            {steps && (
              <Button
                className="p-button-outlined p-button-help"
                icon="pi pi-chevron-circle-right"
                iconPos="right"
                onClick={() => {
                  setStep(step + 1);
                }}
                label="Next"
                disabled={step === maxstep}
              />
            )}
          </div>
        </div>
      )}
      <CRUDBody
        step={step}
        schema={schema}
        obj={obj}
        setObj={setObj}
        eId={eId}
        eCls={eCls}
        token={token}
        setError={setError}
        hidFields={hidFields}
      />

      <div className=" border-top-1 surface-border pt-3">
        <div className="grid formgrid p-fluid">
          {cancelFunction !== null && (
            <div className="field mb-4 col-2">
              <Button
                icon="pi pi-times"
                onClick={() => {
                  if (cancelFunction != null) cancelFunction(obj);
                }}
                label="Cancel"
                className="p-button-outlined p-button-warning"
              />
            </div>
          )}
          <div className="field mb-4 col-6 md:col-2">
            <Button
              className="p-button-outlined p-button-success"
              icon="pi pi-check"
              iconPos="right"
              onClick={() => {
                AdminPrc.addOrUpdateEntity(
                  eCls,
                  obj,
                  token,
                  function (message) {
                    setError(message);
                  },
                  function (data) {
                    if (saveFunction != null) {
                      saveFunction(obj);
                    }
                    if (eId === 0) {
                      setHint(eName + " was added successfully");
                    } else {
                      setHint(eName + " was updated successfully");
                    }
                  }
                );
              }}
              disabled={isDataInvalid()}
              label="Save"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDForm;
