import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "./StripeCheckout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIP);

const StripePayment = ({ amount, strip_secret = null, processFn }) => {
  const appearance = {
    theme: "stripe",
    labels: "floating",
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: strip_secret,
    appearance,
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (strip_secret == null) return <h1>No secret suplied!</h1>;
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckout processFn={processFn} amount={amount} />
    </Elements>
  );
};

export default StripePayment;
