import React, { useEffect } from "react";
import { Button } from "primereact/button";

const SelectGuest = ({ guests, oi, processFn }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildGuests = () => {
    let gs = [];
    if (oi.guest !== 0) {
      gs.push(
        <div className="field col-12 md:col-3">
          <Button
            label="Table"
            icon="pi pi-tablet"
            className="text-xl p-button-outlined"
            onClick={(e) => processFn(0)}
          />
        </div>
      );
    }
    for (let i = 1; i <= guests; i++) {
      if (oi.guest !== i)
        gs.push(
          <div className="field col-12 md:col-3">
            <Button
              label={"Guest " + i}
              icon="pi pi-user"
              className="text-xl p-button-outlined"
              onClick={(e) => processFn(i)}
            />
          </div>
        );
    }
    return gs;
  };

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid w-12">
        <div className="field col-12">
          Please select the guest you want to move{" "}
          {oi.menu === null ? oi.deal.name : oi.menu.name} to:
        </div>
        {buildGuests()}
      </div>
    </div>
  );
};

export default SelectGuest;
