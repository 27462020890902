import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const OrderCancelationDlg = ({ processFn }) => {
  const [reason, setReadon] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid" style={{ width: "450px" }}>
        <div className="field col-12">
          Why do you need to cancel this order?
        </div>
        <div className="field col-12">
          <Dropdown
            value={reason}
            options={[
              "Customer changed their mind",
              "Delay delivery",
              "Wrong address/person",
              "Dummy order",
              "Other",
            ]}
            onChange={(e) => setReadon(e.value)}
            placeholder="Select a reason"
          />
        </div>
        <div className="field col-12">
          <Button
            disabled={reason == null}
            className="p-button-danger"
            label="Cancel this  Order"
            icon="pi pi-times-circle"
            onClick={(e) => {
              processFn(reason);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderCancelationDlg;
