import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import CRUDRow from "./CRUDRow";
import LocationSearchFInput from "../Utils/LocationSearchFInput";
import AdminPrc from "../AdminPrc";
import { AppContext } from "../AppContext";
import LoadingPage from "../Utils/LoadingPage";
import Tools from "../Tools";

const CRUDBody = ({
  schema,
  obj,
  setObj,
  eId,
  eCls,
  token,
  setError,
  step,
  hidFields = {},
}) => {
  const [ready, setReady] = useState(false);
  const { getLookup, getConfig } = useContext(AppContext);

  useEffect(() => {
    if (eId !== 0) {
      AdminPrc.getEntity(
        eCls,
        eId,
        token,
        function (message) {
          setError(message);
        },
        function (data) {
          setObj({ ...data });
          setReady(true);
        }
      );
    } else {
      let tObj = { id: 0 };
      schema.forEach((fld) => {
        if (fld.type === "HID") {
          if (typeof hidFields[fld.field] === "undefined")
            tObj[fld.field] = fld.value;
          else tObj[fld.field] = hidFields[fld.field];
        } else {
          tObj[fld.field] = "";
        }
      });
      setObj({ ...tObj });
      setReady(true);
    }

    schema.forEach((fld) => {
      if (fld.type === "DBL") {
        fld.options = [...getOptionsFor(fld.selector)];
      } else if (fld.type === "CFG") {
        fld.options = [...getConfigsFor(fld.selector)];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldDisableStatus = (fld) => {
    if (typeof fld.disabled === "boolean" && fld.disabled) return true;
    if (
      typeof fld.disabled === "function" &&
      obj !== null &&
      typeof obj[fld.field] !== "undefined"
    )
      return fld.disabled(obj);
    return false;
  };

  const getOptionsFor = (cat) => {
    let mySet = [];
    getLookup(cat, "*", (lSet) => {
      mySet = lSet.map((l) => {
        return { name: l.item, code: l.id };
      });
    });
    return mySet;
  };

  const getConfigsFor = (cat) => {
    let mySet = [];
    getConfig(cat, (lSet) => {
      mySet = lSet.map((l) => {
        return { name: l.name, code: l.value };
      });
    });
    return mySet;
  };

  if (!ready) {
    return <LoadingPage />;
  } else
    return (
      <div className="surface-section">
        <div className="grid formgrid p-fluid">
          {schema.map((fld, index) => {
            if (step === 0 || step === fld.step) {
              switch (fld.type) {
                case "LOC":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      {typeof obj[fld.field] !== "undefined" && (
                        <LocationSearchFInput
                          disabled={getFieldDisableStatus(fld)}
                          value={obj[fld.field]}
                          setLatLng={(latlng, v) => {
                            setObj({
                              ...obj,
                              coordinate: latlng,
                              [fld.field]: v,
                            });
                          }}
                        />
                      )}
                    </CRUDRow>
                  );
                case "TTL":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <InputText
                        keyfilter="pnum"
                        autoComplete="off"
                        disabled={getFieldDisableStatus(fld)}
                        value={obj[fld.field]}
                        {...(Tools.validatePhone(obj[fld.field]) ||
                        obj[fld.field].length === 0
                          ? {}
                          : { className: "p-invalid" })}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          setObj({
                            ...obj,
                            [fld.field]: obj[fld.field].replace(/\D+/g, ""),
                          });
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "HID":
                  return "";
                case "TXT":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <InputText
                        autoComplete="nope"
                        type="text"
                        disabled={getFieldDisableStatus(fld)}
                        value={obj[fld.field]}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "NUM":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <InputNumber
                        autoComplete="nope"
                        mode={fld.mode}
                        prefix={fld.prefix}
                        suffix={fld.suffix}
                        currency={
                          fld.currency !== null ? fld.currency : undefined
                        }
                        disabled={getFieldDisableStatus(fld)}
                        value={obj[fld.field]}
                        maxFractionDigits={2}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.value });
                          if (fld.onChange) fld.onChange(e.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "PAS":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <Password
                        autoComplete="nope"
                        toggleMask
                        disabled={getFieldDisableStatus(fld)}
                        value={obj[fld.field]}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "TEM":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <InputText
                        autoComplete="off"
                        type="text"
                        keyfilter="email"
                        disabled={getFieldDisableStatus(fld)}
                        value={obj[fld.field]}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "TOG":
                  return (
                    <CRUDRow fld={fld} key={index} lpos="after" obj={obj}>
                      <InputSwitch
                        className="mr-2"
                        disabled={getFieldDisableStatus(fld)}
                        checked={obj[fld.field] || obj[fld.field] === 1}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.value });
                          if (fld.onChange) fld.onChange(e.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.value);
                        }}
                      />
                    </CRUDRow>
                  );
                case "DRP":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <Dropdown
                        value={obj[fld.field]}
                        options={fld.options}
                        disabled={getFieldDisableStatus(fld)}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                        optionLabel={fld.optionLabel}
                        optionValue={fld.optionValue}
                      />
                    </CRUDRow>
                  );
                case "DBL":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <Dropdown
                        value={obj[fld.field]}
                        options={fld.options}
                        disabled={getFieldDisableStatus(fld)}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                        optionLabel={fld.optionLabel}
                        optionValue={fld.optionValue}
                      />
                    </CRUDRow>
                  );
                case "CFG":
                  return (
                    <CRUDRow fld={fld} key={index} obj={obj}>
                      <Dropdown
                        value={obj[fld.field]}
                        options={fld.options}
                        disabled={getFieldDisableStatus(fld)}
                        onChange={(e) => {
                          setObj({ ...obj, [fld.field]: e.target.value });
                          if (fld.onChange) fld.onChange(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (fld.onBlur) fld.onBlur(e.target.value);
                        }}
                        optionLabel={fld.optionLabel}
                        optionValue={fld.optionValue}
                      />
                    </CRUDRow>
                  );
                default:
                  return (
                    <div className="field mb-4 col-12" key={index}>
                      Bad type:{fld.type}
                    </div>
                  );
              }
            } else return "";
          })}
        </div>
      </div>
    );
};

export default CRUDBody;
