import React, { useState, useContext, useEffect, Fragment } from "react";
import { AppContext } from "../AppContext";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import LocationSearchFInput from "../Utils/LocationSearchFInput";
import Tools from "../Tools";
import SelectEntity from "../CRUD/SelectEntity";
import StripePaymentPage from "../../components/common/StripePaymentPage";
import { useNavigate } from "react-router-dom";
import ManagedDialog from "../Utils/ManagedDialog";
import AppTools from "../../components/AppTools";
import PictureComp from "../Utils/PictureComp";
import AdminPrc from "../AdminPrc";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { Chips } from "primereact/chips";
import { SocialIcon } from "react-social-icons";

const ProfilePage = ({ inParty = null, inProfile = null }) => {
  const { token, setError, setHint, profile, party, setParty } =
    useContext(AppContext);
  //const [setPhotoUpload] = useState(false);
  const myProfile = inProfile == null ? profile : inProfile;
  const theParty = inParty == null ? party : inParty;
  const navigate = useNavigate();

  const [myParty, setMyParty] = useState({
    ...theParty,
    name: theParty == null ? null : theParty.firstname,
    email: theParty == null ? null : myProfile.username,
  });
  const [options, setOptions] = useState(
    typeof myParty === "undefined" ||
      myParty === null ||
      typeof myParty.options === "undefined" ||
      myParty.options === null ||
      myParty.options.length === 0
      ? []
      : myParty.options.split(",")
  );
  const [bCategories, setBCategories] = useState([]);
  const [amount, setAmount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const processPurchase = (item) => {
    setAmount(item.amount);
    setCredit(item.credit);
    setShowPurchaseOptions(false);
    setShowPayment(true);
  };

  const socialMediaItem = (sm) => {
    return (
      <div>
        <span className="mr-2">{sm}</span>
        <SocialIcon url={sm} />
      </div>
    );
  };

  const purchaseMenu = [
    {
      amount: 1000,
      credit: 1000,
      name: "1,000 for $10.00",
      icon: "pi pi-dollar text-5xl text-green-300",
    },
    {
      amount: 1500,
      credit: 2000,
      name: "2,000 for $15.00",
      icon: "pi pi-dollar text-5xl text-green-400",
    },
    {
      amount: 3000,
      credit: 5000,
      name: "5,000 for $30.00",
      icon: "pi pi-dollar text-5xl text-green-500",
    },
    {
      amount: 5000,
      credit: 10000,
      name: "10,000 for $50.00",
      icon: "pi pi-dollar text-5xl text-pink-500",
    },
    {
      amount: 10000,
      credit: 30000,
      name: "30,000 for $100",
      icon: "pi pi-dollar text-5xl text-pink-700",
    },
  ];

  const [showPayment, setShowPayment] = useState(false);
  const processPayment = (data) => {
    if (data.error == null) {
      saveInfo();
    }
    setShowPayment(false);
  };

  useEffect(() => {
    if (myParty == null || myParty.email == null) {
      navigate("/");
      return;
    }

    if (myParty.role === Tools.BUSINESS)
      AdminPrc.findEntity(
        "Lookup",
        { fields: ["cat"], values: ["BTP"] },
        token,
        (msg) => setError(msg),
        (data) => {
          setBCategories(
            data
              .sort((c1, c2) => c1.item.localeCompare(c2.item))
              .map((c) => {
                return { name: c.item, code: c.item };
              })
          );
        }
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateData = () => {
    if (!myParty.firstname) return false;
    if (myParty.role === AppTools.CONSUMER && !myParty.lastname) return false;
    if (!myParty.address || !myParty.coordinate) return false;
    if (!myParty.phone || !Tools.validatePhone(myParty.phone)) return false;
    return true;
  };

  const saveInfo = () => {
    let data = {
      ...myParty,
      options: options.join(","),
      credit: myParty.credit + credit,
    };

    fetch(process.env.REACT_APP_HOST_URL + "party", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          setHint("Your profile is updated.");
          setParty({ ...myParty, credit: myParty.credit + credit });
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("Error:", error);
      });
  };

  return (
    <div className="flex justify-content-center">
      <ManagedDialog
        closeFn={setShowPayment}
        visible={showPayment}
        maximized={false}
        header={"Payment Processing: " + Tools.formatCurrency(amount / 100)}
      >
        <StripePaymentPage
          pType="Credit"
          amount={amount}
          processFn={processPayment}
          party={myParty == null ? 0 : myParty.id}
        />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowPurchaseOptions}
        visible={showPurchaseOptions}
        maximized={false}
        header="Purchase options?"
      >
        <SelectEntity
          objectSelection={purchaseMenu}
          processFn={processPurchase}
        />
      </ManagedDialog>
      <div className="grid formgrid p-fluid m-5 shadow-2 m-3 p-3 w-9">
        <div className="justify-content-center flex flex-grow-1">
          <span className="text-3xl font-semibold text-orange-600">
            Profile
          </span>
        </div>
        <div className="field col-12 justify-content-center flex pt-3">
          <PictureComp
            id={myParty.profile_id}
            tag="profile"
            token={token}
            onError={(msg) => setError(msg)}
          />
        </div>
        {myProfile != null && myProfile.role === AppTools.BUSINESS && (
          <div className="field col-12 md:col-6">
            <label className="font-medium">Business Name</label>
            <span className="font-medium text-orange-800"> *</span>
            <InputText
              className="w-full mb-3"
              autoComplete="off"
              value={myParty.name}
              onChange={(e) => setMyParty({ ...myParty, name: e.target.value })}
            />
          </div>
        )}
        {myProfile != null && myProfile.role === AppTools.BUSINESS && (
          <div className="field col-12 md:col-6">
            <label className="font-medium">Credit left: {myParty.credit}</label>
            <Button
              label="Buy MoreCredit"
              icon="pi pi-dollar"
              className="button-sm p-button-info"
              iconPos="right"
              onClick={(e) => setShowPurchaseOptions(true)}
            />
          </div>
        )}
        {myProfile != null && myProfile.role === AppTools.CONSUMER && (
          <Fragment>
            <div className="field col-12 md:col-6">
              <label className="font-medium">First Name</label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                autoComplete="off"
                value={myParty.firstname}
                onChange={(e) =>
                  setMyParty({ ...myParty, firstname: e.target.value })
                }
              />
            </div>
            <div className="field col-12 md:col-6">
              <label className="font-medium">Last Name</label>
              <span className="font-medium text-orange-800"> *</span>
              <InputText
                className="w-full mb-3"
                autoComplete="off"
                value={myParty.lastname}
                onChange={(e) =>
                  setMyParty({ ...myParty, lastname: e.target.value })
                }
              />
            </div>
          </Fragment>
        )}
        {myProfile != null &&
          myProfile.role === AppTools.BUSINESS &&
          bCategories.length !== 0 && (
            <div className="field col-12 md:col-6">
              <label className="font-medium">Category</label>
              <Dropdown
                //value={myParty.category}
                value={bCategories.find((c) => c.code === myParty.category)}
                options={bCategories}
                onChange={(e) => {
                  setMyParty({ ...myParty, category: e.value.code });
                }}
                optionLabel="name"
                placeholder="Select a category"
              />
            </div>
          )}
        <div className="field col-12 md:col-6">
          <label className="font-medium">Address</label>
          <span className="font-medium text-orange-800"> *</span>
          <LocationSearchFInput
            value={myParty.address}
            setLatLng={(latlng, v) => {
              setMyParty({
                ...myParty,
                coordinate: latlng,
                address: v,
              });
            }}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="font-medium">Phone</label>
          <span className="font-medium text-orange-800"> *</span>
          <InputText
            keyfilter="pnum"
            value={myParty.phone}
            onChange={(e) => {
              setMyParty({ ...myParty, phone: e.target.value });
            }}
            onBlur={(e) => {
              setMyParty({
                ...myParty,
                phone: myParty.phone.replace(/\D+/g, ""),
              });
            }}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="font-medium">Email</label>
          <span className="font-medium text-orange-800"> *</span>
          <InputText
            type="text"
            keyfilter="email"
            disabled={myParty !== null}
            value={myParty.email}
            onChange={(e) => {
              setMyParty({ ...myParty, email: e.target.value });
            }}
          />
        </div>
        {myProfile != null && myProfile.role === AppTools.BUSINESS && (
          <div className="field col-12">
            <label className="font-medium">Social network and URLs</label>
            <Chips
              value={options}
              onAdd={(e) => {
                if (
                  e.value.toUpperCase().indexOf("HTTP://") === -1 &&
                  e.value.toUpperCase().indexOf("HTTPS://") === -1
                ) {
                  setError("URL should start with http:// or https://");
                  return false;
                }
              }}
              onChange={(e) => {
                setOptions(e.target.value);
              }}
              itemTemplate={socialMediaItem}
            />
          </div>
        )}
        <div className="col-12">
          <Button
            label="Save"
            icon="pi pi-check-circle"
            className="button-sm p-button-success"
            iconPos="right"
            disabled={!validateData()}
            onClick={() => saveInfo()}
          />
        </div>
        {myProfile != null && myProfile.role === AppTools.BUSINESS && (
          <div className="col-12">
            <Divider align="center" type="dashed">
              <h3>Latest Flyer/Brochure/Business Card</h3>
            </Divider>
            <PictureComp
              id={myProfile.id}
              token={token}
              avatar={false}
              inWidth="100%"
              tag="flyer"
              onError={setError}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
