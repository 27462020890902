import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import POSAdminPrc from "./POSAdminPrc";

const OrderItemMenuSelector = ({ type, head, processFuc }) => {
  const [nodes, setNodes] = useState(head);
  const [owner, setOwner] = useState([]);
  const [parent, setParent] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (m) => {
    if (!m.orderable) return false;
    switch (type) {
      case POSAdminPrc.DINE_IN:
        return m.available_dinein;
      case POSAdminPrc.WALK_IN:
        return m.available_walkin;
      case POSAdminPrc.PICK_UP:
        return m.available_pickup;
      case POSAdminPrc.DELIVER:
        return m.available_delivery;
      default:
        return false;
    }
  };
  return (
    <div className="grid p-fluid w-full">
      {parent.map((menu, index) => (
        <div
          className="flex flex-row flex-wrap col-12 md:col-3 justify-content-center"
          key={index}
        >
          <Button
            label={menu.name}
            iconPos="top"
            icon="pi pi-chevron-circle-left text-3xl"
            className="text-3xl flex h-4rem p-button-outlined m-2 h-6rem p-button-warning"
            onClick={(e) => {
              let n = parent.length - index;
              let oldOwner = owner.pop();
              parent.pop();
              for (; n !== 1; n--) {
                parent.pop();
                oldOwner = owner.pop();
              }
              setParent([...parent]);
              setOwner([...owner]);
              setNodes(oldOwner);
            }}
          />
        </div>
      ))}
      <div className="col-12"></div>
      {nodes
        .filter((m) => validate(m))
        .map((menu, index) => (
          <div
            className="flex flex-row flex-wrap col-12 md:col-3 justify-content-center"
            key={index}
          >
            <Button
              iconPos="top"
              icon={
                typeof menu.children === "undefined"
                  ? "pi pi-check-circle text-3xl "
                  : menu.price === 0
                  ? "pi pi-chevron-circle-right text-3xl "
                  : "pi pi-check-circle text-3xl text-orange-500"
              }
              label={menu.name}
              className={
                "text-3xl flex h-4rem p-button-outlined m-2 h-6rem " +
                (typeof menu.children !== "undefined"
                  ? "p-button-info"
                  : "p-button-success")
              }
              onClick={(e) => {
                if (typeof menu.children === "undefined" || menu.price !== 0) {
                  processFuc(menu);
                } else {
                  setOwner([...owner, nodes]);
                  setParent([...parent, menu]);
                  setNodes(menu.children);
                }
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default OrderItemMenuSelector;
