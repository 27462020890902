import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../common/AppContext";
import { QRCode } from "react-qrcode-logo";
import Tools from "../../common/Tools";
import BPrc from "./BusinessPrc";
import LoadingPage from "../../common/Utils/LoadingPage";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import MessageBox from "../../common/Utils/MessageBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import WScanner from "../../common/Utils/WScanner";
import { InputNumber } from "primereact/inputnumber";
import GetNumber from "../common/GetNumber";
import GetText from "../common/GetText";
import StripePaymentPage from "../common/StripePaymentPage";
import POSAdminPrc from "../admin/POSAdminPrc";
import Authentication from "../../common/Auth/Authentication";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import AppTools from "../AppTools";
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method

const PlayCampaign = (props) => {
  const { token, setError, profile, party, setHideMenu } =
    useContext(AppContext);
  let [searchParams] = useSearchParams();

  const [arrowHeight, setArrowHeight] = useState(100);

  const scannerRef = useRef(null);
  const [cameraDisable, setCameraDisable] = useState(true);

  const [keyInput, setKeyInput] = useState("");
  const [key, setKey] = useState("");

  const [paidInfo, setPaidInfo] = useState(null);
  const [business, setBusiness] = useState(null);
  const [bAddress, setBAddress] = useState(null);
  const [bPhone, setBPhone] = useState(null);

  const [showPayment, setShowPayment] = useState(false);
  const [processPayment, setProcessPayment] = useState(null);
  const getPayment = (fn) => {
    if (campaign.entry_cost !== 0 && !paidInfo) {
      setProcessPayment(() => (data) => {
        if (data.error == null) {
          fn();
        }
        setShowPayment(false);
      });
      setShowPayment(true);
    } else {
      fn();
    }
  };

  const [showGetNumber, setShowGetNumber] = useState(false);
  const processGetNumber = (n) => {
    setShowGetNumber(false);
    if (n > 0) {
      setPlayer({ ...player, amount: n });
      scannerRef.current.start();
    }
  };

  const [showInputBox, setShowInputBox] = useState(false);
  const [inputField, setInputField] = useState(null);
  const [inputMode, setInputMode] = useState(0);
  const processInput = (inp) => {
    setShowInputBox(false);
    if (inputField === "Email") {
      setPlayer({ ...player, email: inp });
    } else if (inputField === "Amount") {
      setPlayer({ ...player, amount: parseFloat(inp) });
    } else if (inputField === "Phone") {
      setPlayer({ ...player, phone: inp.replace(/\D+/g, "") });
    } else if (inputField === "First Name") {
      setPlayer({ ...player, firstname: inp });
    } else if (inputField === "Last Name") {
      setPlayer({ ...player, lastname: inp });
    }
  };

  const qr = searchParams.get("qr");
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  const [confirm, setConfirm] = useState(false);
  const [conf_header, setConf_header] = useState(null);
  const [conf_message, setConf_message] = useState(null);
  const [conf_qr, setConf_qr] = useState(null);
  const [conf_func, setConf_func] = useState(null);
  const [guest, setGuest] = useState(false);
  const [player, setPlayer] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    party: 0,
    amount: 0.0,
  });
  const [campaign, setCampaign] = useState(null);

  const validateData2 = () => {
    if (
      (player.email.length === 0 && player.phone.length === 0) ||
      (player.email.phone !== 0 && !Tools.validatePhone(player.phone)) ||
      (player.email.length !== 0 && !Tools.validateEmail(player.email)) ||
      (campaign.mode === 3 && (player.amount == null || player.amount === 0))
    )
      return false;
    return true;
  };

  const drawPrizes = (counter) => {
    let canWin = campaign.items.filter((i) => i.odds <= counter);
    if (canWin.length === 0) {
      return (
        <ul>
          <li>Keep playing!</li>
        </ul>
      );
    } else {
      return (
        <ul>
          You have enough points to redeem for:
          {canWin.map((p) => (
            <li>
              {p.prize} - {p.odds} stamps
            </li>
          ))}
        </ul>
      );
    }
  };

  const Msg = (header, message, qr = null, func = null) => {
    setConf_header(header);
    setConf_message(message);
    setConf_qr(qr);
    setConf_func(func);
    setConfirm(true);
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      function (event) {
        setKey(event.key);
      },
      true
    );

    setPaidInfo(searchParams.get("paid"));

    if (qr != null && qr.length !== 0) {
      BPrc.getCampaign(
        qr,
        (message) => {
          setError(message);
        },
        (data) => {
          setBusiness(data.business);
          setBAddress(data.address);
          setBPhone(data.phone);
          setCampaign(data.campaign);
          if (searchParams.get("paid") != null) {
            BPrc.runPaidCampaign(
              searchParams.get("paid"),
              (message) => {
                setError(message);
                navigate("/");
              },
              (data) => {
                showResult(data);
                if (party === null) setTimeout(() => navigate("/"), 20000);
              }
            );
            return;
          } else if (profile !== null && profile.role === AppTools.CONSUMER)
            getPayment(() =>
              BPrc.runCampaignForGuest(
                false,
                { email: profile.username },
                false,
                qr,
                token,
                (message) => {
                  setError(message);
                  navigate("/");
                },
                (data) => {
                  showResult(data);
                }
              )
            );
          else console.log("Not yet!");
        }
      );
      setHideMenu(true);
    } else navigate("/");

    return () => setHideMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConsumerMessage = () => {
    return (
      <div>
        <h3>Saving opportunity at {business}</h3>
        <br /> Become a memmber and participate in <b>{campaign.name}</b> and
        save{" "}
        <b>
          <i>%{campaign.items[0].odds}</i>
        </b>{" "}
        on all your purchases.
        <br />
        <br />
        Address: {bAddress}
        <br />
        Phone: {bPhone}
      </div>
    );
  };
  useEffect(() => {
    if (
      campaign !== null &&
      campaign.mode === 3 &&
      (profile === null || profile.role === AppTools.CONSUMER)
    ) {
      confirmDialog({
        message: getConsumerMessage,
        header: "Congratulations",
        icon: "pi pi-bell",
        acceptLabel: "Ok",
        rejectClassName: "hidden",
        accept: () => {},
      });
      navigate("/");
      return;
    }
    if (document.getElementById("arrow_div") == null) return;
    setArrowHeight(
      window.innerHeight -
        document.getElementById("arrow_div").getBoundingClientRect().top -
        140
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    if (key.length !== 0) {
      if (key === "Shift") {
      } else if (key === "Enter") {
        Authentication.autoLogin(
          keyInput,
          (message) => setError("Invalid membership!"),
          (tk, pr) => {
            getPayment(() => {
              if (campaign.mode === 3) setShowGetNumber(true);
              else
                BPrc.runCampaignForClient(
                  profile.role === AppTools.BUSINESS,
                  pr.qr,
                  qr,
                  0,
                  tk,
                  (message) => setError(message),
                  (data) => {
                    Tools.processEmail();
                    showResult(data);
                  }
                );
            });
          }
        );
        setKeyInput("");
      } else {
        setKeyInput(keyInput + key);
        setKey("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const showResult = (data) => {
    switch (data.mode) {
      case 0:
        if (data.prize == null) {
          Msg(
            "Thank you for playing",
            `Dear ${
              data.customer.firstname.length <= 1
                ? "player"
                : data.customer.firstname + " " + data.customer.lastname
            }, you didn't win any prize, good luck next time.`
          );
        } else {
          Msg(
            "Congradulations",
            `Dear ${
              data.customer.firstname.length <= 1
                ? "player"
                : data.customer.firstname + " " + data.customer.lastname
            }, you just won! To redeam your ${
              data.prize.prize
            } please take a photo of QR below and present it to us. If you are registered or provided your email address a confirmation is also sent to your email.`,
            data.qr,
            () => {}
          );
        }
        break;
      case 1:
        Msg("Thank you for playing", [
          `Dear ${
            data.customer.firstname.length <= 1
              ? "player"
              : data.customer.firstname + " " + data.customer.lastname
          }, you have `,
          <strong>{data.entry.counter}</strong>,
          " stamps from this campaign!",
          <ul> {drawPrizes(data.entry.counter)}</ul>,
        ]);
        break;
      case 2:
        Msg(
          "Thank you for playing",
          `Dear ${
            data.customer.firstname.length <= 1
              ? "player"
              : data.customer.firstname + " " + data.customer.lastname
          }, good luck! Your name is added to the draw list.`,
          null
        );
        break;
      case 3:
        Msg(
          "Congradulations",
          [
            `Dear ${
              data.customer.firstname.length <= 1
                ? "customer"
                : data.customer.firstname + " " + data.customer.lastname
            }, you just earned `,
            <strong> {data.prize.odds} </strong>,
            `% discount on your purchase. Now you only pay `,
            <strong>
              {" "}
              {Tools.formatCurrency(
                ((100 - data.prize.odds) * player.amount) / 100
              )}
            </strong>,
            `.${
              party == null || profile.role === AppTools.CONSUMER
                ? " Please take a photo of this QR code and present it to the business to redeem your prize. If you provided an email or phone number you can also receive this QR via email or SMS."
                : ""
            }`,
          ],
          data.qr,
          () => {}
        );
        break;
      default:
        break;
    }
  };

  if (qr == null) {
    return <div></div>;
  } else if (qr != null && qr.length !== 0 && campaign == null) {
    return <LoadingPage />;
  } else if (profile !== null && profile.role === AppTools.CONSUMER) {
    if (confirm) {
      return (
        <MessageBox
          show={confirm}
          icon="fas fa-trophy"
          header={conf_header}
          message={conf_message}
          qr={conf_qr}
          okFunc={conf_func}
          hideFunc={() => {
            setConfirm(false);
            navigate("/");
          }}
        />
      );
    } else {
      return <LoadingPage />;
    }
  } else {
    return (
      <div>
        <ManagedDialog
          closeFn={setShowPayment}
          visible={showPayment}
          maximized={false}
          header={
            "Payment Processing: " + Tools.formatCurrency(campaign.entry_cost)
          }
        >
          <StripePaymentPage
            amount={campaign == null ? 0 : campaign.entry_cost * 100}
            campaign={campaign.qr}
            processFn={processPayment}
            email={player.email}
            phone={player.phone}
            party={player.party}
          />
        </ManagedDialog>
        <ManagedDialog
          closeFn={setShowGetNumber}
          visible={showGetNumber}
          maximized={false}
          header="Enter the amount"
        >
          <GetNumber init={0} processFn={processGetNumber} />
        </ManagedDialog>
        <MessageBox
          show={confirm}
          icon="fas fa-trophy"
          header={conf_header}
          message={conf_message}
          qr={conf_qr}
          okFunc={conf_func}
          hideFunc={() => setConfirm(false)}
        />
        <div className="flex justify-content-center">
          <div className="grid formgrid p-fluid w-9">
            <div className="col-12 flex justify-content-center flex-grow-1">
              <span className="md:text-3xl font-semibold text-yellow-600">
                {campaign.name}-{BPrc.getCampaignType(campaign.mode)}
              </span>
            </div>
            {campaign.mode === 2 && campaign.entry_cost !== 0 && (
              <div className="col-12 flex justify-content-center flex-grow-1">
                <span className="md:text-2xl font-semibold text-pink-600">
                  Cost: {Tools.formatCurrency(campaign.entry_cost)}
                </span>
              </div>
            )}
            {campaign.entry_cost === 0 && (
              <div className="col-12 flex justify-content-center flex-grow-1">
                <span className="md:text-2xl font-semibold text-green-600">
                  Play for free!
                </span>
              </div>
            )}
            <div className="col-12 flex justify-content-start flex-grow-1">
              <ul className="list-disc">
                {/* <p className=" text-600 font-medium text-xl"> Prizes</p> */}
                {campaign.items.map((p, index) => (
                  <li key={index} className=" text-600 font-medium">
                    {p.prize +
                      (campaign.mode === 1 ? " for " + p.odds + " stamps" : "")}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-content-center">
          <WScanner
            ref={scannerRef}
            header="Membership QR Code"
            readyFn={() => {
              setCameraDisable(false);
            }}
            abortFn={() => {}}
            errorFn={() => {}}
            scanFn={(data) => {
              scannerRef.current.stop();

              Authentication.autoLogin(
                data,
                (message) => setError("Invalid membership!"),
                (tk, pr) => {
                  setPlayer({ ...player, party: pr.id });
                  getPayment(() =>
                    BPrc.runCampaignForClient(
                      profile.role === AppTools.BUSINESS,
                      pr.qr,
                      qr,
                      player.amount,
                      tk,
                      (message) => setError(message),
                      (data) => {
                        Tools.processEmail();
                        showResult(data);
                      }
                    )
                  );
                }
              );
            }}
          />

          <Dialog
            className="ahpDialog"
            position="top"
            visible={showInputBox}
            style={{
              width: "90%",
              maxWidth: "500px",
            }}
            header={"Please enter " + inputField}
            onHide={() => {
              setShowInputBox(false);
            }}
            onShow={() => {}}
          >
            <GetText processFn={processInput} mode={inputMode} />
          </Dialog>
          {/* <Dialog
            className="ahpDialog"
            position="top"
            visible={guest}
            style={{ width: "90%" }}
            header="Play as a guest, just tell us how to deliver the prize"
            onHide={() => {
              setGuest(false);
            }}
            onShow={() => {
              setPlayer({
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                party: 0,
                amount: 0.0,
              });
            }}
          >
            <div className="flex justify-content-center">
              <div className="grid formgrid p-fluid w-9">
                <div className="field col-12 md:col-6">
                  <label className="font-medium">First Name</label>
                  <InputText
                    className="w-full mb-3"
                    autoComplete="off"
                    placeholder="Guest"
                    value={player.firstname}
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("First Name");
                        setInputMode(0);
                        setShowInputBox(true);
                      }
                    }}
                    onChange={(e) =>
                      setPlayer({
                        ...player,
                        firstname: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label className="font-medium">Last Name</label>
                  <InputText
                    className="w-full mb-3"
                    autoComplete="off"
                    placeholder="Player"
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("Last Name");
                        setInputMode(0);
                        setShowInputBox(true);
                      }
                    }}
                    value={player.lastname}
                    onChange={(e) =>
                      setPlayer({ ...player, lastname: e.target.value })
                    }
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label className="font-medium">Phone</label>
                  <InputText
                    keyfilter="pnum"
                    value={player.phone}
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("Phone");
                        setInputMode(2);
                        setShowInputBox(true);
                      }
                    }}
                    onChange={(e) => {
                      setPlayer({ ...player, phone: e.target.value });
                    }}
                    onBlur={(e) => {
                      setPlayer({
                        ...player,
                        phone: player.phone.replace(/\D+/g, ""),
                      });
                    }}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label className="font-medium">Email</label>
                  <InputText
                    type="text"
                    keyfilter="email"
                    value={player.email}
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("Email");
                        setInputMode(0);
                        setShowInputBox(true);
                      }
                    }}
                    onChange={(e) => {
                      setPlayer({ ...player, email: e.target.value });
                    }}
                  />
                </div>
                {campaign.mode === 3 && (
                  <div className="field col-12">
                    <label className="font-medium">Amount</label>
                    <InputNumber
                      autoComplete="nope"
                      mode="currency"
                      currency="CAD"
                      value={player.amount}
                      onFocus={(e) => {
                        if (window.outerHeight > 1500) {
                          setInputField("Amount");
                          setInputMode(1);
                          setShowInputBox(true);
                        } else {
                          e.target.select();
                        }
                      }}
                      onChange={(e) => {
                        setPlayer({ ...player, amount: e.value });
                      }}
                    />
                  </div>
                )}
                <div className="field col-12 md:col-6">
                  <Button
                    label="Play"
                    icon="pi pi-play"
                    className="p-button-sm p-button-success"
                    iconPos="right"
                    disabled={!validateData2()}
                    onClick={() => {
                      setGuest(false);
                      getPayment(() =>
                        BPrc.runCampaignForGuest(
                          profile != null && profile.role === AppTools.BUSINESS,
                          player,
                          true,
                          qr,
                          token,
                          (message) => setError(message),
                          (data) => {
                            showResult(data);
                          }
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            className="ahpDialog"
            position="top"
            visible={login}
            style={{ width: "90%" }}
            header="Find your account using your email or phone"
            onHide={() => {
              setLogin(false);
            }}
            onShow={() => {
              setPlayer({
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                amount: 0.0,
                party: 0,
              });
            }}
          >
            <div className="flex justify-content-center">
              <div className="grid formgrid p-fluid w-9">
                <div className="field col-12 md:col-6">
                  <label className="font-medium">Phone</label>
                  <InputText
                    keyfilter="pnum"
                    value={player.phone}
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("Phone");
                        setInputMode(2);
                        setShowInputBox(true);
                      }
                    }}
                    onChange={(e) => {
                      setPlayer({ ...player, phone: e.target.value });
                    }}
                    onBlur={(e) => {
                      setPlayer({
                        ...player,
                        phone: player.phone.replace(/\D+/g, ""),
                      });
                    }}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label className="font-medium">Email</label>
                  <InputText
                    type="text"
                    keyfilter="email"
                    value={player.email}
                    onFocus={(e) => {
                      if (window.outerHeight > 1500) {
                        setInputField("Email");
                        setInputMode(0);
                        setShowInputBox(true);
                      }
                    }}
                    onChange={(e) => {
                      setPlayer({ ...player, email: e.target.value });
                    }}
                  />
                </div>
                {campaign.mode === 3 && (
                  <div className="field col-12">
                    <label className="font-medium">Amount</label>
                    <InputNumber
                      autoComplete="nope"
                      mode="currency"
                      currency="CAD"
                      onFocus={(e) => {
                        if (window.outerHeight > 1500) {
                          setInputField("Amount");
                          setInputMode(1);
                          setShowInputBox(true);
                        }
                      }}
                      value={player.amount}
                      onChange={(e) => {
                        setPlayer({ ...player, amount: e.value });
                      }}
                    />
                  </div>
                )}
                <div className="field col-12 md:col-6">
                  <Button
                    label="Find and Play"
                    icon="pi pi-search"
                    className="p-button-sm p-button-success"
                    iconPos="right"
                    disabled={!validateData2()}
                    onClick={() => {
                      setLogin(false);
                      getPayment(() =>
                        BPrc.runCampaignForGuest(
                          profile != null && profile.role === AppTools.BUSINESS,
                          player,
                          false,
                          qr,
                          token,
                          (message) => setError(message),
                          (data) => {
                            showResult(data);
                          }
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </Dialog> */}

          <div className="grid formgrid p-fluid w-full">
            {profile !== null && campaign != null && campaign.mode !== 3 && (
              <div className="field col-12 md:col-6 flex justify-content-center align-content-end flex-grow-1">
                <QRCode
                  value={
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/play?qr=" +
                    campaign.qr
                  }
                  eyeRadius={2}
                  size={300}
                  removeQrCodeBehindLogo={true}
                  qrStyle="dots"
                  logoImage="/images/qr-logo.png"
                  ecLevel="H"
                />
              </div>
            )}
            {profile !== null && (
              <div
                className={
                  campaign.mode !== 3
                    ? " col-12 md:col-6 flex justify-content-center align-items-center flex-grow-1"
                    : " col-12 flex justify-content-center align-items-center flex-grow-1"
                }
              >
                <div className="flex justify-content-center">
                  <div className="grid formgrid p-fluid w-full">
                    <div className="field col-12 md:col-6">
                      <span className="p-float-label">
                        <InputText
                          className="w-full p-inputtext-sm"
                          autoComplete="off"
                          value={player.firstname}
                          onFocus={(e) => {
                            if (window.outerHeight > 1500) {
                              setInputField("First Name");
                              setInputMode(0);
                              setShowInputBox(true);
                            }
                          }}
                          onChange={(e) =>
                            setPlayer({
                              ...player,
                              firstname: e.target.value,
                            })
                          }
                        />
                        <label className="font-medium">First Name</label>
                      </span>
                    </div>
                    <div className="field col-12 md:col-6">
                      <span className="p-float-label">
                        <InputText
                          className="w-full p-inputtext-sm"
                          autoComplete="off"
                          onFocus={(e) => {
                            if (window.outerHeight > 1500) {
                              setInputField("Last Name");
                              setInputMode(0);
                              setShowInputBox(true);
                            }
                          }}
                          value={player.lastname}
                          onChange={(e) =>
                            setPlayer({ ...player, lastname: e.target.value })
                          }
                        />
                        <label className="font-medium">Last Name</label>
                      </span>
                    </div>
                    <div className="field col-12 md:col-6">
                      <span className="p-float-label">
                        <InputText
                          type="tel"
                          className="w-full p-inputtext-sm"
                          keyfilter="pnum"
                          value={player.phone}
                          onFocus={(e) => {
                            if (window.outerHeight > 1500) {
                              setInputField("Phone");
                              setInputMode(2);
                              setShowInputBox(true);
                            }
                          }}
                          onChange={(e) => {
                            setPlayer({ ...player, phone: e.target.value });
                          }}
                          onBlur={(e) => {
                            setPlayer({
                              ...player,
                              phone: player.phone.replace(/\D+/g, ""),
                            });
                          }}
                        />
                        <label className="font-medium">Phone</label>
                      </span>
                    </div>
                    <div className="field col-12 md:col-6">
                      <span className="p-float-label">
                        <InputText
                          type="email"
                          className="w-full p-inputtext-sm"
                          keyfilter="email"
                          value={player.email}
                          onFocus={(e) => {
                            if (window.outerHeight > 1500) {
                              setInputField("Email");
                              setInputMode(0);
                              setShowInputBox(true);
                            }
                          }}
                          onChange={(e) => {
                            setPlayer({ ...player, email: e.target.value });
                          }}
                        />
                        <label className="font-medium">Email</label>
                      </span>
                    </div>
                    {campaign.mode === 3 && (
                      <div className="field col-12">
                        <span className="p-float-label">
                          <InputNumber
                            autoComplete="nope"
                            className="w-full p-inputtext-sm"
                            mode="currency"
                            currency="CAD"
                            value={player.amount}
                            onFocus={(e) => {
                              if (window.outerHeight > 1500) {
                                setInputField("Amount");
                                setInputMode(1);
                                setShowInputBox(true);
                              } else {
                                e.target.select();
                              }
                            }}
                            onChange={(e) => {
                              setPlayer({ ...player, amount: e.value });
                            }}
                          />
                          <label className="font-medium">Amount</label>
                        </span>
                      </div>
                    )}
                    <div className="field col-12 md:col-6">
                      <Button
                        label="Play"
                        icon="pi pi-play text-3xl"
                        className="p-button-info p-button-outlined p-button-lg"
                        iconPos="right"
                        disabled={!validateData2()}
                        onClick={() => {
                          setGuest(false);
                          getPayment(() =>
                            BPrc.runCampaignForGuest(
                              profile != null &&
                                profile.role === AppTools.BUSINESS,
                              player,
                              true,
                              qr,
                              token,
                              (message) => setError(message),
                              (data) => {
                                showResult(data);
                                setPlayer({
                                  firstname: "",
                                  lastname: "",
                                  phone: "",
                                  email: "",
                                  party: 0,
                                  amount: 0.0,
                                });
                              }
                            )
                          );
                        }}
                      />
                    </div>
                    {!cameraDisable && (
                      <div className="field col-12 md:col-6 pb-3">
                        <Button
                          disabled={cameraDisable}
                          className="p-button-success p-button-outlined p-button-lg"
                          label="Membership"
                          icon="pi pi-camera text-3xl"
                          onClick={(e) => {
                            if (campaign.mode === 3) setShowGetNumber(true);
                            else scannerRef.current.start();
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* <div className="field col-12 md:col-6 flex justify-content-center flex-grow-1">
              <Button
                className="p-button-info p-button-outlined p-button-lg"
                label="Guests"
                icon="pi pi-user text-3xl"
                onClick={(e) => setGuest(true)}
              />
            </div>
            <div className="field col-12 md:col-6 flex justify-content-center flex-grow-1">
              <Button
                className="p-button-sucess p-button-outlined p-button-lg"
                label="Members"
                icon="pi pi-search text-3xl"
                onClick={(e) => setLogin(true)}
              />
            </div> */}

            {window.outerHeight > 1500 && (
              <div
                id="arrow_div"
                className="field col-12 p-2 flex flex-row flex-wrap align-items-center justify-content-center card-container relative"
                style={{ height: arrowHeight + "px" }}
              >
                <span
                  className="text-4xl absolute text-bluegray-500"
                  style={{ bottom: "320px" }}
                >
                  Members: Please Scan your ID to play
                </span>
                <div className="arrow absolute bottom-0"></div>
              </div>
            )}
            {window.outerHeight <= 1500 && (
              <div
                className="field col-12 p-2 flex flex-row flex-wrap align-items-center justify-content-center card-container relative"
                style={{ height: arrowHeight + "px" }}
              >
                <div className="absolute bottom-0"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default PlayCampaign;
