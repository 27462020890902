import { Button } from "primereact/button";
import React, { useState } from "react";
import Tools from "../../common/Tools";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import POSAdminPrc from "../admin/POSAdminPrc";
import BPrc from "./BusinessPrc";

function Campaign({
  campaignInfo,
  token,
  run = false,
  setError,
  setHint,
  editCampaign,
  emailQR,
  endCampaign,
  showCampaignEntries,
  showCampaignWinners,
  runCampaign,
  archiveCampaign,
  CloneCampaign,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="col-12 md:col-6 xl:col-4 p-3">
      <Dialog
        dlgLabel="Display"
        position="top"
        header={
          <div className="flex align-content-center">
            <img
              src={`images/c_${campaignInfo.campaign.mode}_logo.png`}
              style={{ width: "30px", height: "30px" }}
              className="mr-3"
              alt={BPrc.getCampaignType(campaignInfo.campaign.mode)}
            />
            {campaignInfo.campaign.name}
          </div>
        }
        visible={showManage}
        style={{ width: "50vw" }}
        onHide={() => {
          setShowManage(false);
        }}
      >
        <div className="grid p-fluid">
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.campaign.status !== 1}
              label="Email QR"
              icon="pi pi-envelope"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                emailQR(campaignInfo.campaign.qr);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.campaign.status !== 1}
              label="Edit"
              icon="pi pi-pencil"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                editCampaign(campaignInfo.campaign);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.campaign.status !== 1}
              label="End"
              icon="pi pi-times"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                endCampaign(campaignInfo.campaign.qr);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.entryCount === 0}
              label="Entries"
              icon="pi pi-th-large"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                showCampaignEntries(campaignInfo.campaign.id);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.winnerCount === 0}
              label="Winners "
              icon="pi pi-money-bill"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                showCampaignWinners(campaignInfo.campaign.id);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={
                campaignInfo.campaign.mode !== 2 ||
                campaignInfo.campaign.status !== 1 ||
                campaignInfo.entryCount === 0
              }
              label="Run"
              icon="pi pi-caret-right"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                runCampaign(campaignInfo.campaign.qr);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.campaign.status !== 0}
              label="Archive"
              icon="pi pi-clock"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                archiveCampaign(campaignInfo.campaign.qr);
              }}
            />
          </div>
          <div className="col-4 pt-3 flex">
            <Button
              disabled={campaignInfo.campaign.status !== 2}
              label="Clone"
              icon="pi pi-clone"
              className="p-button-outlined p-button-info w-6 mr-2 flex flex-grow-1 "
              onClick={(e) => {
                setShowManage(false);
                CloneCampaign(campaignInfo.campaign.qr);
              }}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        dlgLabel="Display"
        position="top"
        header={campaignInfo.campaign.name}
        visible={showDetails}
        style={{ width: "50vw" }}
        onHide={() => {
          setShowDetails(false);
        }}
      >
        <div className="grid formgrid p-fluid">
          <div className="field col-12">
            <ul className="list-none p-0 m-0">
              <li className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap">
                <div className="text-500 w-6 md:w-2 font-medium">
                  {BPrc.getCampaignType(campaignInfo.campaign.mode)}
                </div>
                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                  {campaignInfo.campaign.limit === 0
                    ? "No spending limit"
                    : "Spending limit: " +
                      Tools.formatCurrency(campaignInfo.campaign.costlimit)}
                </div>
              </li>
            </ul>
          </div>
          <div className="field col-12 bg-blue-50 border-round border-2 border-dashed border-green-200">
            <div className="text-500 my-2 flex justify-content-center align-items-center font-semibold">
              Campaign's prize list
            </div>

            <ul className="list-none p-0 m-0">
              <li className="flex  bg-blue-150 align-items-center py-1 px-2 border-top-1 surface-border flex-wrap">
                <div className="text-500 w-6 md:w-2 font-bold">
                  {campaignInfo.campaign.mode === 0 ||
                  campaignInfo.campaign.mode === 2
                    ? "Chance"
                    : campaignInfo.campaign.mode === 1
                    ? "Stapms"
                    : "Discount"}
                </div>
                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 font-bold">
                  Prize
                </div>
                <div className="w-6 md:w-2 flex justify-content-end font-bold">
                  Remaining
                </div>
              </li>
              {campaignInfo.items.map((p, index) => (
                <li
                  className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap"
                  key={index}
                >
                  <div className="text-500 w-6 md:w-2 font-medium">
                    {p.odds}
                    {campaignInfo.campaign.mode !== 1 ? "%" : ""}
                  </div>
                  <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    {p.prize}
                    {p.price === 0 ? "" : " for $" + p.price}
                  </div>
                  <div className="w-6 md:w-2 flex justify-content-end">
                    {p.limits === 0 ? "No limits" : `${p.hits} of ${p.limits}`}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
      <div className="surface-card shadow-2 border-rounded p-3 ">
        <div className="flex border-bottom-1 surface-border pb-3">
          <img
            src={`images/c_${campaignInfo.campaign.mode}_logo.png`}
            style={{ width: "70px", height: "70px" }}
            className="mr-3"
            alt={BPrc.getCampaignType(campaignInfo.campaign.mode)}
          />
          <div className="flex flex-column justify-content-between">
            <span className="text-lg text-900 font-medium">
              {campaignInfo.campaign.name} -{" "}
              {BPrc.getCampaignType(campaignInfo.campaign.mode)}
              {campaignInfo.campaign.status === 1
                ? ""
                : campaignInfo.campaign.status === 0
                ? " [Closed]"
                : " [Archived]"}
            </span>

            {run && (
              <ul className="list-disc h-5rem">
                {campaignInfo.items.map((p, index) => (
                  <li key={index} className=" text-600 font-medium">
                    {p.prize}
                  </li>
                ))}
              </ul>
            )}
            {!run &&
              (campaignInfo.campaign.mode === 0 ||
                campaignInfo.campaign.mode === 2) && (
                <span className="text-600 font-medium">
                  {campaignInfo.entryCount === 0
                    ? "No entry, "
                    : campaignInfo.entryCount === 1
                    ? "One entry, "
                    : `${campaignInfo.entryCount} entries, `}

                  {campaignInfo.parties === 0
                    ? ""
                    : campaignInfo.parties === 1
                    ? "One participant, "
                    : `${campaignInfo.parties} participants, `}

                  {campaignInfo.campaign.mode === 2 &&
                  campaignInfo.campaign.status === 1
                    ? ""
                    : campaignInfo.winnerCount === 0
                    ? "no winner"
                    : campaignInfo.winnerCount === 1
                    ? "one winner"
                    : `${campaignInfo.winnerCount} winners`}
                </span>
              )}
            {!run && campaignInfo.campaign.mode === 1 && (
              <span className="text-600 font-medium">
                {campaignInfo.entryCount === 0
                  ? "No stamp, "
                  : campaignInfo.entryCount === 1
                  ? "One stamp, "
                  : `${campaignInfo.entryCount} stamps, `}

                {campaignInfo.parties === 0
                  ? ""
                  : campaignInfo.parties === 1
                  ? "One participant, "
                  : `${campaignInfo.parties} participants, `}
                {campaignInfo.winnerCount === 0
                  ? "no redeems"
                  : campaignInfo.winnerCount === 1
                  ? "one redeem"
                  : `${campaignInfo.winnerCount} redeems`}
              </span>
            )}
            {!run && (
              <div>
                <span className="text-sm text-900">
                  {campaignInfo.campaign.costlimit === 0
                    ? "No spending limits"
                    : `${Tools.currencyFormat(
                        campaignInfo.campaign.costlimit -
                          campaignInfo.campaign.cost
                      )} remaning budget`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-content-between pt-3">
          {!run && (
            <Button
              label="Details"
              icon="pi pi-info-circle"
              className="p-button-outlined p-button-info w-6 mr-2"
              onClick={(e) => setShowDetails(true)}
            />
          )}
          {!run && (
            <Button
              label="Manage"
              icon="pi pi-ellipsis-h"
              className="p-button-outlined p-button-info w-6 ml-2"
              onClick={(e) => setShowManage(true)}
            />
          )}
          {run && (
            // {run && <Link to={`/play/${data.campaign.qr}`}>Run</Link>}

            <Button
              label="Run"
              icon="pi pi-play"
              className="p-button-outlined p-button-info w-6 ml-2"
              onClick={(e) => navigate(`/play?qr=${campaignInfo.campaign.qr}`)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Campaign;
