import React, { useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

const GetNumber = ({ init, processFn }) => {
  const [number, setNumber] = useState(init);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kbOnChange = (input) => {
    setNumber(input);
  };

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid w-9">
        <div className="field col-12">
          <InputNumber
            id="num"
            className="w-full mb-3"
            mode="currency"
            currency="CAD"
            value={number}
            onChange={(e) => setNumber(e.value)}
          />
        </div>
        <div className="field col-12">
          <Button
            label="Enter"
            icon="pi pi-dollar"
            onClick={(e) => {
              processFn(number);
              setNumber(init);
            }}
            className="p-button-success"
          />
        </div>
        <div className="field col-12">
          <Keyboard
            //input={password}
            preventMouseDownDefault={true}
            onChange={kbOnChange}
            inputName="num"
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", ". 0 ", "{bksp}"],
            }}
            theme="hg-theme-default hg-layout-numeric numeric-theme"
          />
        </div>
      </div>
    </div>
  );
};

export default GetNumber;
