import React, { useState } from "react";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const StripeCheckout = ({ amount, processFn }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     "payment_intent_client_secret"
  //   );

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent.status) {
  //       case "succeeded":
  //         setMessage("Payment succeeded!");
  //         break;
  //       case "processing":
  //         setMessage("Your payment is processing.");
  //         break;
  //       case "requires_payment_method":
  //         setMessage("Your payment was not successful, please try again.");
  //         break;
  //       default:
  //         setMessage("Something went wrong.");
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe
      .confirmPayment({
        elements,
        redirect: "always",
        //redirect: "if_required",
        confirmParams: {
          // Make sure to change this to your payment completion page
          /*  
            payment_intent=pi_3KEaH2KaFPIAbW5N18lXjqe6&payment_intent_client_secret=pi_3KEaH2KaFPIAbW5N18lXjqe6_secret_VKVxnrwI87xPmA5WcihfubXNm&redirect_status=succeeded

            payment_intent                  =   pi_3KEaH2KaFPIAbW5N18lXjqe6
            payment_intent_client_secret    =   pi_3KEaH2KaFPIAbW5N18lXjqe6_secret_VKVxnrwI87xPmA5WcihfubXNm
            redirect_status                 =   succeeded 
        */
          return_url:
            window.location.protocol + "//" + window.location.host + "/payment",
        },
      })
      .then(function (result) {
        if (result.error) {
          if (
            result.error.type === "card_error" ||
            result.error.type === "validation_error"
          ) {
            processFn({ error: result.error.message });
          } else {
            processFn({ error: "An unexpected error occured." });
          }
        } else {
          processFn({ error: null, result });
        }
        setIsLoading(false);
      });
  };

  return (
    <form
      className="StripeForm"
      id="payment-form"
      onSubmit={handleSubmit}
      // style={{ width: "30vw", minWidth: "500px", alignSelf: "center" }}
    >
      {/* <span className="font-semibold text-green-500 shadow-5 p-2">
        Payment for {Utils.formatCurrency(amount / 100)}
      </span> */}
      <PaymentElement id="payment-element" className="xpt-3" />
      <button
        className="StripeButton mt-3"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">Pay now</span>
      </button>
    </form>
  );
};

export default StripeCheckout;
