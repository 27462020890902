import { Button } from "primereact/button";
import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AppContext } from "../../common/AppContext";
import POSAdminPrc from "./POSAdminPrc";
import Tools from "../../common/Tools";

const ItemSelector = ({ all, processFn }) => {
  const [dineIn, setDineIn] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [walkin, setWalkin] = useState(false);
  const { setError } = useContext(AppContext);

  const header = (
    <div className="grid">
      <div className="col-3 field">
        <label className="flex">Just Delivery</label>
        <InputSwitch
          className="flex"
          checked={delivery}
          onChange={(e) => {
            setDelivery(e.value);
            if (e.value) {
              setPickup(false);
              setDineIn(false);
              setWalkin(false);
            }
          }}
        />
      </div>
      <div className="col-3 field">
        <label className="flex">Just Pickup</label>
        <InputSwitch
          className="flex"
          checked={pickup}
          onChange={(e) => {
            setPickup(e.value);
            if (e.value) {
              setDelivery(false);
              setDineIn(false);
              setWalkin(false);
            }
          }}
        />
      </div>
      <div className="col-3 field">
        <label className="flex">Just Walkin</label>
        <InputSwitch
          className="flex"
          checked={walkin}
          onChange={(e) => {
            setWalkin(e.value);
            if (e.value) {
              setPickup(false);
              setDineIn(false);
              setDelivery(false);
            }
          }}
        />
      </div>
      <div className="col-3 field">
        <label className="flex">Just Dine In</label>
        <InputSwitch
          className="flex"
          checked={dineIn}
          onChange={(e) => {
            setDineIn(e.value);
            if (e.value) {
              setPickup(false);
              setDelivery(false);
              setWalkin(false);
            }
          }}
        />
      </div>
    </div>
  );

  const typesIn = (t) => {
    if (t !== POSAdminPrc.DINE_IN && dineIn) return false;
    if (t !== POSAdminPrc.WALK_IN && walkin) return false;
    if (t !== POSAdminPrc.DELIVER && delivery) return false;
    if (t !== POSAdminPrc.PICK_UP && pickup) return false;
    return true;
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card header={header} className="px-3 pt-1 shadow-3">
      <DataTable
        value={all.filter((a) => typesIn(a.order_type))}
        responsiveLayout="scroll"
      >
        <Column
          className="w-1"
          body={(order) => {
            return POSAdminPrc.getType(order.json);
          }}
          header="Type"
        ></Column>
        <Column className="w-1" field="order_number" header="#"></Column>
        <Column className="w-2" field="order_time" header="Time"></Column>
        <Column
          className="w-1"
          body={(order) => Tools.currencyFormat(order.total)}
          header="Total"
        ></Column>
        <Column
          className="w-1"
          field="json.server.name"
          header="Server"
        ></Column>
        <Column
          className="w-3"
          body={(order) => {
            let cnt = order.json.orderItems.length;
            return (
              <Accordion>
                <AccordionTab
                  header={
                    (cnt === 1 ? "One item: " : cnt + " items: ") +
                    (order.json.orderItems[0].menu == null
                      ? order.json.orderItems[0].deal.name
                      : order.json.orderItems[0].menu.name)
                  }
                >
                  <ul>
                    {order.json.orderItems.map((oi, index) => (
                      <li key={index}>
                        <h5>{oi.menu == null ? oi.deal.name : oi.menu.name}</h5>
                      </li>
                    ))}
                  </ul>
                </AccordionTab>
              </Accordion>
            );
          }}
          header="Summary"
        ></Column>
        <Column
          className="w-4"
          body={(order) => {
            let pre = order.canceled ? " CANCELED" : "";
            if (order.order_type === POSAdminPrc.DINE_IN) {
              return "Table " + order.json.table.name + pre;
            } else if (order.order_type === POSAdminPrc.WALK_IN) {
              return pre;
            } else {
              return `Customer: ${order.json.customer.name} , ${order.json.customer.phone} , ${order.json.customer.address} ${pre}`;
            }
          }}
          header="Info"
        ></Column>
        <Column
          body={(order) => {
            if (order.canceled) return "";
            let paid = POSAdminPrc.isPaid(order.json, 0);
            return (
              <Button
                icon={paid ? "pi pi-times" : "pi pi-replay"}
                className={
                  "p-button-rounded p-button-outlined" +
                  (paid ? " p-button-danger" : "")
                }
                onClick={(e) => {
                  if (order.closed) {
                    setError("You can't modify an order that is closed.");
                  } else {
                    processFn(order);
                  }
                }}
              />
            );
          }}
          header="Act"
        ></Column>
        <Column
          body={(order) => {
            if (POSAdminPrc.isPaid(order.json, 0))
              return (
                <Button
                  icon="pi pi-clone"
                  className="p-button-rounded p-button-outlined"
                  onClick={(e) => processFn({ ...order, id: 0 })}
                />
              );
            else return "";
          }}
          header="Clone"
        ></Column>
      </DataTable>
    </Card>
  );
};

export default ItemSelector;
