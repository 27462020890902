import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import Tools from "../../common/Tools";
import GetNumber from "../common/GetNumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SelectEntity from "../../common/CRUD/EntryForm";
import { confirmDialog } from "primereact/confirmdialog";
import { AdminContext } from "./POSAdminContextProvider";
import WScanner from "../../common/Utils/WScanner";
import POSAdminPrc from "./POSAdminPrc";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import Authentication from "../../common/Auth/Authentication";

const PaymentPage = ({ customer, rate, total, charges, processFn }) => {
  const { token, setError } = useContext(AppContext);
  const { discount } = useContext(AdminContext);

  const [cameraDisable, setCameraDisable] = useState(true);
  const scannerRef = useRef(null);

  const [remaining, setRemainig] = useState(total);
  const [payments, setPayments] = useState([]);

  const [payment, setPayment] = useState(null);
  const [points, setPoints] = useState(0);

  const [cardCharge, setCardCharge] = useState(false);
  const [discountUsed, setDiscountUsed] = useState(false);

  const [usedCoupon, setUsedCoupon] = useState(false);
  const [showGetCoupon, setShowGetCoupon] = useState(false);
  const processGetCoupon = (c) => {
    setUsedCoupon(true);
    setShowGetCoupon(false);

    let n = c.percentage ? (remaining * c.amount) / 100 : c.amount;
    setRemainig(remaining - n);
    setShowGetNumber(false);
    setPayments([...payments, { method: "P" + c.name, amount: n }]);
  };

  const [showGetNumber, setShowGetNumber] = useState(false);
  const processGetNumber = (n) => {
    let min_c = charges.find((c) => c.name === "min_charge");

    if (typeof min_c !== "undefined") min_c = parseFloat(min_c.value);
    else min_c = 0;

    if (payment === "T") {
      n = n * -1;
    }
    if ("VMADB".indexOf(payment) !== -1) {
      if (n < min_c) {
        setError(
          "Minimum charged to debit and credit cards is " +
            Tools.currencyFormat(min_c)
        );
        return;
      }
    }
    setRemainig(remaining - n);
    setShowGetNumber(false);
    setPayments([...payments, { method: payment, amount: n }]);
  };

  useEffect(() => {
    if (customer != null) {
      POSAdminPrc.getEntity(
        "Customer",
        customer.id,
        token,
        (message) => setError(message),
        (c) => {
          setPoints(c.points);
          if (c.party_id !== null && discount != null) {
            // Customer is a member and we business has a member discount
            addFund("X");
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (remaining < 0) {
      setPayments([...payments, { method: "T", amount: remaining }]);
      setRemainig(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remaining]);

  const addFund = (type) => {
    if (!cardCharge) {
      if (type === "B") {
        let deb_c = charges.find((c) => c.name === "debit_charge");
        if (typeof deb_c !== "undefined") deb_c = parseFloat(deb_c.value);
        else deb_c = 0;
        if (deb_c !== 0) {
          setPayments([...payments, { method: "I", amount: -1 * deb_c }]);
          setRemainig(remaining + deb_c);
          setCardCharge(true);
        }
      } else if ("VMAD".indexOf(type) !== -1) {
        let crd_c = charges.find((c) => c.name === "card_charge");
        if (typeof crd_c !== "undefined") crd_c = parseFloat(crd_c.value);
        else crd_c = 0;
        if (crd_c !== 0) {
          setPayments([...payments, { method: "I", amount: -1 * crd_c }]);
          setRemainig(remaining + crd_c);
          setCardCharge(true);
        }
      }
    }

    setPayment(type);

    if ("P".indexOf(type) !== -1) {
      if (usedCoupon) {
        setError(
          "You already used a coupon for this payment. One coupon per payment is allowed."
        );
      } else {
        setShowGetCoupon(true);
      }
    } else if ("R".indexOf(type) !== -1) {
      confirmDialog({
        header: "User Rewards",
        message: (
          <div>
            <p className="text-500">
              You have <span className="text-orange-500">{points}</span> points
              available to redeem for{" "}
              <span className="text-orange-500">
                {Tools.currencyFormat(points / rate.value)}
              </span>
              .
              <br />
              Do you want to use the reward points right now?
            </p>
          </div>
        ),
        className: "text-3xl",
        icon: "pi pi-info-circle text-3xl",
        acceptClassName: "text-2xl p-button-danger",
        rejectClassName: "text-2xl p-button-info",
        accept: () => {
          let paid = points / rate.value;
          if (paid > remaining) {
            paid = remaining;
          }
          setRemainig(remaining - paid);
          setShowGetNumber(false);
          setPayments([...payments, { method: type, amount: paid }]);
        },
        reject: () => {},
      });
    } else if ("X".indexOf(type) !== -1) {
      let paid = (discount.odds * total) / 100;
      setRemainig(remaining - paid);
      setPayments([...payments, { method: type, amount: paid }]);
      setDiscountUsed(true);
    } else {
      setShowGetNumber(true);
    }
  };

  const getW4 = (t) => {
    if (t == null) return "";
    if (t === "C") return "Cash";
    if (t === "V") return "Visa";
    if (t === "M") return "Master Card";
    if (t === "A") return "AMEX";
    if (t === "D") return "Discover";
    if (t === "B") return "Debit";
    if (t.indexOf("P") === 0) return t.substring(1);
    if (t === "R") return "Reward";
    if (t === "N") return "Remaining";
    if (t === "I") return "Card Fee";
    if (t === "T") return "Tip";
    if (t === "X") return "Member Dicount";
  };

  const GetMembership = () => {
    if (!cameraDisable) {
      scannerRef.current.start();
    }
  };

  return (
    <div className="flex justify-content-center mt-5">
      <ManagedDialog
        closeFn={setShowGetNumber}
        visible={showGetNumber}
        maximized={false}
        header="Enter the amount"
      >
        <GetNumber
          init={payment === "T" ? 0 : remaining}
          processFn={processGetNumber}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowGetCoupon}
        visible={showGetCoupon}
        maximized={false}
        header="Select the coupon"
      >
        <SelectEntity entity="Coupon" processFn={processGetCoupon} />
      </ManagedDialog>

      <WScanner
        ref={scannerRef}
        header="Membership QR Code"
        readyFn={() => {
          setCameraDisable(false);
        }}
        abortFn={() => {}}
        errorFn={() => {}}
        scanFn={(data) => {
          scannerRef.current.stop();
          // Logic goes here
          Authentication.checkParty(
            data,
            (meg) => {},
            (party) => {
              if (customer != null) {
                POSAdminPrc.saveCustomer(
                  { ...customer, party_id: party.id },
                  token,
                  (msg) => setError(msg),
                  () => {}
                );
              }
              addFund("X");
            }
          );
        }}
      />

      <div className="grid formgrid p-fluid w-12">
        <div className="field col-12 md:col-9">
          <div className="grid">
            <div className="field col-6 mb-6">
              <span className="text-blue-500 text-2xl">
                Total to pay : {Tools.formatCurrency(total)}
              </span>
            </div>

            <div className="field col-6 mb-6">
              <span className="text-orange-500 text-2xl">
                Remaining : {Tools.formatCurrency(remaining)}
              </span>
            </div>
            <div className="field col-12 md:col-3">
              <Button
                label="Cash"
                disabled={remaining <= 0}
                icon="far fa-money-bill-alt"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("C")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Debit"
                icon="far fa-credit-card"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("B")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Master Card"
                icon="fab fa-cc-mastercard"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("M")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Visa"
                icon="fab fa-cc-visa"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("V")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Amex"
                icon="fab fa-cc-amex"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("A")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Discover"
                icon="fab fa-cc-discover"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("D")}
              />
            </div>
            {discount != null && (
              <div className="field col-12 md:col-3">
                <Button
                  disabled={remaining <= 0 || discountUsed}
                  label="Member Discount"
                  icon="fas fa-percent"
                  className="text-2xl p-button-outlined"
                  onClick={(e) => GetMembership()}
                />
              </div>
            )}
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Coupon"
                icon="fas fa-stamp"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("P")}
              />
            </div>
            {customer != null && typeof rate !== "undefined" && (
              <div className="field col-12 md:col-3">
                <Button
                  disabled={remaining <= 0}
                  label="Reward"
                  icon="fas fa-gift"
                  className="text-2xl p-button-outlined"
                  onClick={(e) => addFund("R")}
                />
              </div>
            )}
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining <= 0}
                label="Tip"
                icon="fab fa-gratipay"
                className="text-2xl p-button-outlined"
                onClick={(e) => addFund("T")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <Button
                disabled={remaining !== 0}
                label="Finish"
                icon="fas fa-cash-register"
                className="text-2xl p-button-outlined"
                onClick={(e) => {
                  let reward = payments.find((p) => p.method === "R");
                  if (typeof reward !== "undefined") {
                    POSAdminPrc.rewardUse(
                      customer.id,
                      Math.floor(reward.amount * rate.value),
                      token,
                      (message) => setError(message),
                      () => {}
                    );
                  }
                  processFn(
                    payments.map((p) => ({
                      method: getW4(p.method),
                      amount: parseFloat(p.amount),
                    }))
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="field col-12 md:col-3">
          <DataTable
            value={payments.concat({ method: "N", amount: remaining })}
            responsiveLayout="scroll"
          >
            <Column
              className="w-8"
              body={(p) => getW4(p.method)}
              header="Paid by"
            ></Column>
            <Column
              className="w-2"
              body={(p) => (
                <span className={p.amount < 0 ? "text-pink-500" : ""}>
                  {Tools.formatCurrency(Math.abs(p.amount))}
                </span>
              )}
              header="Amount"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
