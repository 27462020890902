import { Dialog } from "primereact/dialog";
import Tools from "../Tools";

const ManagedDialog = ({
  closeFn,
  header,
  visible,
  maximized,
  style = {},
  children,
}) => {
  return (
    <Dialog
      className="ahpDialog"
      position={maximized ? undefined : "top"}
      visible={visible}
      closable={false}
      maximized={maximized}
      style={style}
      icons={closeFn == null ? "" : Tools.dialogCloseIcon(closeFn)}
      modal
      header={header}
    >
      {children}
    </Dialog>
  );
};

export default ManagedDialog;
