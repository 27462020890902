import React, { useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Tools from "../Tools";
import { AppContext } from "../../common/AppContext";
import { Navigate } from "react-router";
import { Link, useNavigate } from "react-router-dom";
//import ReactPasswordStrength from "react-password-strength";
import Authentication from "./Authentication";
import LoadingPage from "../Utils/LoadingPage";
import AppTools from "../../components/AppTools";

function LoginForm() {
  const [ready, setReady] = useState(true);
  //const [passIsValid, setPassIsValid] = useState(false);
  const [username, setUsername] = useState(Tools.getCookie("username"));
  const [password, setPassword] = useState(Tools.getCookie("password"));
  const [remember, setRemember] = useState(Tools.getCookie("remember"));
  const {
    token,
    setToken,
    setError,
    setProfile,
    setParty,
    initLookups,
    initConfigs,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const invalidData = () => {
    const tst = Tools.validateEmail(username);
    return !tst;
  };

  const goHome = () => {
    navigate("/");
  };

  const callLogin = () => {
    if (remember) {
      Tools.setCookie("username", username);
      Tools.setCookie("password", password);
      Tools.setCookie("remember", remember);
    } else {
      Tools.setCookie("username", null);
      Tools.setCookie("password", null);
      Tools.setCookie("remember", null);
    }
    setReady(false);
    Authentication.login(
      username,
      password,
      function (message) {
        setError(message);
        setReady(true);
      },
      function (token, pf) {
        setError(null);
        setReady(true);
        setProfile({ ...pf });
        setToken(token);
        initLookups(token);
        if (pf.role === AppTools.BUSINESS) {
          initConfigs(token);
        }
        // Add app logic after login here
        Tools.getParty(
          token,
          function (message) {
            setError(message);
          },
          function (party) {
            if (remember) Tools.setCookie("zKey", party.qr);
            else Tools.setCookie("zKey", null);
            setParty({ ...party });
          }
        );
        if (pf.role === AppTools.CONSUMER) {
        } else if (pf.role === AppTools.BUSINESS) {
        }
      }
    );
  };
  if (!ready) return <LoadingPage />;
  return token == null ? (
    <div className="flex align-items-center justify-content-center mt-3">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
          <span className="text-600 font-medium line-height-3">
            Don't have an account?
          </span>
          <Link
            to="/register"
            className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          >
            Create today!
          </Link>
        </div>

        <div>
          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText
            id="username"
            className="w-full mb-3"
            autoComplete="off"
            value={username}
            keyfilter="email"
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText
            className="w-full mb-3"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex align-items-center justify-content-between mb-6 mt-3">
            <div className="flex align-items-center">
              <Checkbox
                id="remember"
                name="remember"
                checked={remember}
                onChange={(e) => setRemember(e.checked)}
                binary
                className="mr-2"
              />
              <label htmlFor="rememberme">Remember me</label>
            </div>
            <Link
              to="/resetpassword"
              className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            >
              Forgot your password?
            </Link>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <Button
                label="Login"
                icon="pi pi-sign-in"
                disabled={!ready}
                onClick={callLogin}
                {...(invalidData() ? { disabled: true } : {})}
                className="w-full mr-3 p-button-success"
              />
            </div>
            <div className="field col">
              <Button
                label="Back"
                icon="pi pi-times"
                className="p-button-warning w-full mr-3"
                onClick={goHome}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <Navigate push to="/"></Navigate>
    </div>
  );
}

export default LoginForm;
