import Tools from "./Tools";

const AdminPrc = {
  doNothing: () => {},

  getAll: (entity, page, condition, token, url, onError, onSuccess) => {
    fetch(
      url == null
        ? process.env.REACT_APP_HOST_URL +
            "crudapi/" +
            entity +
            "?pName=" +
            page +
            (condition == null
              ? ""
              : `&condition=true&field=${condition.field}&value=${
                  condition.value == null ? "" : condition.value
                }`)
        : url +
            "&pName=" +
            page +
            (condition == null
              ? ""
              : `&condition=true&field=${condition.field}&value=${
                  condition.value == null ? "" : condition.value
                }`),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: null,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        onError("Error:", error);
      });
  },

  getAllEntities: (entity, token, onError, onSuccess) => {
    return AdminPrc.getEntity(entity, "ALL", token, onError, onSuccess);
  },

  getEntity: (entity, id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "crudapi/" + entity + "/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  findEntity: (entity, criteria, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "crudapi/find/" + entity, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(criteria),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  addOrUpdateEntity: (entity, obj, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "crudapi/" + entity, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },
};

export default AdminPrc;
