import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { AdminContext } from "./POSAdminContextProvider";

import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import LoadingPage from "../../common/Utils/LoadingPage";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import POSAdminPrc from "./POSAdminPrc";

const ModTree = ({ menu }) => {
  const { token, setError, setHint } = useContext(AppContext);
  const { modifiers, modTree, findModifier, addToRightChild, findModInMenu } =
    useContext(AdminContext);
  const [showSearch, setShowSearch] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [selectedMods, setSelectedMods] = useState([]);
  const [selectedSourceMods, setSelectedSourceMods] = useState([]);
  const [selectedMenuMods, setSelectedMenuMods] = useState([]);

  useEffect(() => {
    POSAdminPrc.getMenuMods(
      menu.id,
      token,
      (message) => setError(message),
      (data) => {
        let addToSellected = [];
        data.forEach((m) => {
          let modifier = findModifier(m.modifier_id, modifiers);
          if (typeof modifier.children === "undefined") {
            let mod = findModInMenu(m.modifier_id, modTree, modifier);
            let fs = addToSellected.find((a) => a.key === mod.key);
            if (typeof fs === "undefined") {
              addToSellected.push(mod);
            } else {
              addToRightChild(fs, mod);
            }
          } else {
          }
        });
        updateMMInfo(addToSellected, data);
        setSelectedMods(addToSellected);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMMInfo = (head, data) => {
    head.forEach((a) => {
      if (typeof a.children === "undefined") {
        let mod = data.find((m) => m.modifier_id === a.key);
        a.data = { ...mod, ...a.data };
      } else {
        updateMMInfo(a.children, data);
      }
    });
  };

  const filterOut = (key, head) => {
    let newHead = head.filter((a) => a.key !== key);
    if (head.length !== newHead.length) {
      let pos = 0;
      for (; pos !== head.length; pos++) {
        if (head[pos].key === key) break;
      }
      head.splice(pos, 1);
      POSAdminPrc.removeMenuModifier(
        menu.id,
        key,
        token,
        (message) => setError(message),
        () => {}
      );
    } else
      head.forEach((node) => {
        if (typeof node.children !== "undefined") filterOut(key, node.children);
      });
  };
  const removeFromMenu = () => {
    let mods = Object.keys(selectedMenuMods);

    if (mods.length === 0) {
      setError(
        "Please select items from modifiers list to remove from the menu"
      );
      return;
    }
    let newSelected = [...selectedMods];
    mods.forEach((key) => filterOut(parseInt(key), newSelected));
    setSelectedMenuMods([]);
    setSelectedMods(newSelected);
    setShowSave(true);
  };

  const setModChanged = (mod) => {
    mod.changed = true;
    if (typeof mod.children !== "undefined") {
      mod.children.forEach((m) => {
        setModChanged(m);
      });
    }
  };
  const addToMenu = () => {
    let mods = Object.keys(selectedSourceMods);

    if (mods.length === 0) {
      setError("Please select items from available list to add to the menu");
      return;
    }
    let addToSellected = [...selectedMods];
    mods.forEach((key) => {
      let modifier = findModifier(parseInt(key), modifiers);
      let mod = findModInMenu(parseInt(key), modTree, modifier);
      if (mod != null) {
        let fs = addToSellected.find((a) => a.key === mod.key);
        if (typeof fs === "undefined") {
          setModChanged(mod);
          setShowSave(true);
          addToSellected.push(mod);
        } else addToRightChild(fs, mod);
      }
    });
    setSelectedSourceMods([]);
    setSelectedMods(addToSellected);
  };

  const findInSelMods = (n, head) => {
    if (typeof head === "undefined") return null;
    let node = head.find((i) => i.key === n);
    if (typeof node === "undefined") {
      for (let i in head) {
        node = findInSelMods(n, head[i].children);
        if (node != null) {
          return node;
        }
      }
    } else {
      return node;
    }
    return null;
  };

  const priceTemplate = (node) => {
    return cellTemplate(node, "price");
  };
  const dblpriceTemplate = (node) => {
    if (typeof node.data.has_DBL !== "undefined" && node.data.has_DBL)
      return cellTemplate(node, "dbl_price");
  };
  const trppriceTemplate = (node) => {
    if (typeof node.data.has_TRP !== "undefined" && node.data.has_TRP)
      return cellTemplate(node, "trp_price");
  };
  const hlfpriceTemplate = (node) => {
    if (typeof node.data.has_HLF !== "undefined" && node.data.has_HLF)
      return cellTemplate(node, "hlf_price");
  };
  const defaultTemplate = (node) => {
    if (typeof node.children !== "undefined") return "";
    return (
      <span className={node.changed ? "text-pink-600" : ""}>
        {node.data.default_option ? "Yes" : "No"}
      </span>
    );
  };
  const nameTemplate = (node) => {
    return cellTemplate(node, "name");
  };

  const cellTemplate = (node, field) => {
    return (
      <span className={node.changed ? "text-pink-600" : ""}>
        {node.data[field]}
      </span>
    );
  };

  const cellEditor = (props) => {
    if (typeof props.node.children !== "undefined") return "";
    if (props.field === "default_option")
      return (
        <InputSwitch
          checked={props.node.data[props.field]}
          onChange={(e) => {
            let newS = JSON.parse(JSON.stringify(selectedMods));
            let node = findInSelMods(props.node.data.id, newS);
            node.data[props.field] = e.value;
            node.changed = true;
            setShowSave(true);
            setSelectedMods(newS);
          }}
        />
      );
    if (props.field === "dbl_price" && !props.node.data.has_DBL) return "";
    if (props.field === "trp_price" && !props.node.data.has_TRP) return "";
    if (props.field === "hlf_price" && !props.node.data.has_HLF) return "";
    return (
      <InputNumber
        mode="currency"
        currency="CAD"
        inputClassName="text-xs"
        //style={{ width: "10px" }}
        size={7}
        value={props.node.data[props.field]}
        onChange={(e) => {
          let newS = JSON.parse(JSON.stringify(selectedMods));
          let node = findInSelMods(props.node.data.id, newS);
          node.data[props.field] = e.value;
          node.changed = true;
          setShowSave(true);
          setSelectedMods(newS);
        }}
      />
    );
  };

  const getMMObject = (node) => {
    let obj = {
      menu_id: menu.id,
      modifier_id: node.key,
      price: 0,
      dbl_price: 0,
      trp_price: 0,
      hlf_price: 0,
      default_option: false,
    };
    if (typeof node.data.price !== "undefined") obj.price = node.data.price;
    if (typeof node.data.dbl_price !== "undefined")
      obj.dbl_price = node.data.dbl_price;
    if (typeof node.data.trp_price !== "undefined")
      obj.trp_price = node.data.trp_price;
    if (typeof node.data.hlf_price !== "undefined")
      obj.hlf_price = node.data.hlf_price;
    if (typeof node.data.default_option !== "undefined")
      obj.default_option = node.data.default_option;

    return obj;
  };

  const makeItFlat = (head, flat) => {
    head.forEach((node) => {
      flat.push(getMMObject(node));
      if (typeof node.children !== "undefined") makeItFlat(node.children, flat);
    });
  };
  const markAllUnchanged = (head) => {
    head.forEach((node) => {
      node.changed = false;
      if (typeof node.children !== "undefined") markAllUnchanged(node.children);
    });
  };
  const saveData = () => {
    let toBeSaved = [];
    makeItFlat(selectedMods, toBeSaved);
    POSAdminPrc.updateMenuModifier(
      toBeSaved,
      token,
      (message) => setError(message),
      () => {
        setHint("Menu is updated.");
        setShowSave(false);
        markAllUnchanged(selectedMods);
        setSelectedMods(JSON.parse(JSON.stringify(selectedMods)));
      }
    );
  };

  if (modTree == null) return <LoadingPage />;
  return (
    <div
      className="flex justify-content-around align-content-center"
      style={{ height: "750px" }}
    >
      <div className="flex  flex-column">
        <h5>All available Modifiers</h5>
        <TreeTable
          value={modTree}
          selectable={true}
          scrollable
          selectionMode="multiple"
          selectionKeys={selectedSourceMods}
          onSelectionChange={(e) => setSelectedSourceMods(e.value)}
          scrollHeight="550px"
          style={{ width: "400px" }}
          //className="w-5"
        >
          <Column
            field="name"
            header="Name"
            expander
            filter={showSearch}
          ></Column>
        </TreeTable>
      </div>
      <div className="flex flex-column">
        <div className="flex flex-grow-1 flex-column justify-content-center align-content-center">
          <Button
            icon="pi pi-search text-3xl "
            className="p-button-text "
            onClick={(e) => setShowSearch(!showSearch)}
          />
          <Button
            icon="pi pi-chevron-circle-right text-3xl "
            className="p-button-text "
            onClick={(e) => addToMenu()}
          />
          <Button
            icon="pi pi-chevron-circle-left text-3xl "
            className="p-button-text "
            onClick={(e) => removeFromMenu()}
          />
          <Button
            icon="pi pi-save text-3xl "
            className="p-button-text "
            onClick={(e) => saveData()}
            disabled={!showSave}
          />
        </div>{" "}
      </div>
      <div className="flex flex-column">
        <h5>Selected Modifiers</h5>
        <TreeTable
          value={selectedMods}
          draggable={true}
          selectionMode="multiple"
          selectionKeys={selectedMenuMods}
          onSelectionChange={(e) => setSelectedMenuMods(e.value)}
          scrollable
          scrollHeight="550px"
          style={{ width: "900px" }}
        >
          <Column
            field="name"
            header="Name"
            body={nameTemplate}
            expander
            style={{ width: "450px" }}
          ></Column>
          <Column
            field="price"
            editor={cellEditor}
            body={priceTemplate}
            header="Price"
          ></Column>
          <Column
            field="dbl_price"
            editor={cellEditor}
            body={dblpriceTemplate}
            header="2X Price"
          ></Column>
          <Column
            field="trp_price"
            editor={cellEditor}
            body={trppriceTemplate}
            header="3X Price"
          ></Column>
          <Column
            field="hlf_price"
            editor={cellEditor}
            body={hlfpriceTemplate}
            header="1/2 Price"
          ></Column>
          <Column
            field="default_option"
            body={defaultTemplate}
            editor={cellEditor}
            header="Default"
          ></Column>
        </TreeTable>
      </div>
    </div>
  );
};

export default ModTree;
