import React, { createContext, useState } from "react";
import POSAdminPrc from "../components/admin/POSAdminPrc";
import AdminPrc from "../common/AdminPrc";
import Tools from "./Tools";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [uiBlocked, setUIBlocked] = useState(false);
  const [logging] = useState(true);
  const [profile, setProfile] = useState(null);
  const [party, setParty] = useState(null);
  const [hideMenu, setHideMenu] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setErrorMsg] = useState(null);
  const [hint, setHintMsg] = useState(null);
  const [lookups, setLookups] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [nearBusinesses, setNearBusinesses] = useState(null);
  const [coordinate, setCoordinate] = useState({
    latitude: null,
    longitude: null,
  });

  let dPos = 0;

  const dPositions = [
    "center",
    "top",
    "bottom",
    "left",
    "right",
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
  ];
  const log = (...arg) => {
    if (logging) console.log(...arg);
  };

  const getNextDPos = () => {
    return dPositions[dPos++ % 9];
  };
  function setHint(msg) {
    setUIBlocked(false);
    setHintMsg(msg);
  }
  function setError(err) {
    setUIBlocked(false);
    if (err == null) setErrorMsg(null);
    else {
      if (typeof err.errorInfo === "undefined") {
        setErrorMsg(err);
      } else {
        setErrorMsg(err.errorInfo[2]);
      }
    }
  }

  function getConfig(key, setterFunc) {
    let subSet = null;
    subSet = configs.filter((l) => l.name.indexOf(key) === 0);

    setterFunc(subSet);
  }

  function getLookup(key, keyword, setterFunc) {
    let subSet = null;
    if (keyword === "*") {
      subSet = lookups.filter((l) => l.cat === key);
    } else {
      subSet = lookups.filter(
        (l) =>
          l.cat === key &&
          l.item.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      );
    }
    setterFunc(subSet);
  }

  function initLookups(token) {
    fetch(process.env.REACT_APP_HOST_URL + "getlookups", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          setLookups(data.data);
        } else {
          console.error("Error:", data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function initConfigs(token) {
    AdminPrc.getAllEntities(
      "CRUD$Config",
      token,
      (message) => setError(message),
      (data) => {
        POSAdminPrc.configCheck(token, data, "delivery", "2.50");
        POSAdminPrc.configCheck(token, data, "delivery_limit", "1000");
        POSAdminPrc.configCheck(token, data, "delivery_min", "20");
        POSAdminPrc.configCheck(token, data, "tax_HST", "13");
        POSAdminPrc.configCheck(token, data, "debit_charge", "13");
        POSAdminPrc.configCheck(token, data, "card_charge", "13");
        POSAdminPrc.configCheck(token, data, "min_charge", "13");
        POSAdminPrc.configCheck(token, data, "printer_kitchen", "");
        POSAdminPrc.configCheck(token, data, "printer_local", "");
        POSAdminPrc.configCheck(token, data, "printer_bar", "");
        POSAdminPrc.configCheck(token, data, "printer_drink", "");
        POSAdminPrc.configCheck(token, data, "message_top", "");
        POSAdminPrc.configCheck(token, data, "message_bottom", "");
        POSAdminPrc.configCheck(token, data, "store_address", "");
        POSAdminPrc.configCheck(token, data, "store_phone", "");
        POSAdminPrc.configCheck(token, data, "dollar_point", "100");
        POSAdminPrc.configCheck(token, data, "ws_server", "");
        POSAdminPrc.configCheck(token, data, "has_pickup", "yes");
        POSAdminPrc.configCheck(token, data, "has_delivery", "yes");
        POSAdminPrc.configCheck(token, data, "has_dinein", "no");
        //APrc.configCheck(token, data, "ws_server", "ws://localhost:8080/");
        setConfigs(data);
      }
    );
  }

  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        log,
        logging,
        error,
        hint,
        setError,
        setHint,
        lookups,
        getLookup,
        configs,
        getConfig,
        initLookups,
        initConfigs,
        profile,
        setProfile,
        party,
        setParty,
        uiBlocked,
        setUIBlocked,
        getNextDPos,
        hideMenu,
        setHideMenu,
        coordinate,
        setCoordinate,
        nearBusinesses,
        setNearBusinesses,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
