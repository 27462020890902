import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { AdminContext } from "./POSAdminContextProvider";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import POSAdminPrc from "./POSAdminPrc";

const GetUser = ({ closeFunc }) => {
  const { token, setError } = useContext(AppContext);
  const { user, setUser } = useContext(AdminContext);
  const [password, setPassword] = useState("");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callLogin = () => {
    if (user == null) {
      POSAdminPrc.logUser(
        password,
        token,
        (message) => setError(message),
        (aUser) => {
          setUser(aUser);
          closeFunc(aUser);
        }
      );
    } else {
      POSAdminPrc.getUser(
        password,
        token,
        (message) => setError(message),
        (aUser) => {
          closeFunc(aUser);
        }
      );
    }
  };

  const kbOnChange = (input) => {
    setPassword(input);
  };

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid m-5 shadow-2 m-3 p-3 w-9">
        <div className="field col-12">
          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Enter your Password
          </label>
          <Password
            id="password"
            className="w-full mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <Button label="Enter" icon="pi pi-sign-in" onClick={callLogin} />
        </div>
        <div className="field col-12">
          <Keyboard
            //input={password}
            preventMouseDownDefault={true}
            onChange={kbOnChange}
            inputName="password"
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", " 0 ", "{bksp}"],
            }}
            theme="hg-theme-default hg-layout-numeric numeric-theme"
          />
        </div>
      </div>
    </div>
  );
};

export default GetUser;
