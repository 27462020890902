import React, { useState, useContext, useEffect, Fragment } from "react";
import { AppContext } from "../../common/AppContext";
import { Stage, Layer, Rect, Text } from "react-konva";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { AdminContext } from "./POSAdminContextProvider";
import POSAdminPrc from "./POSAdminPrc";

const Layout = ({ editing = true, processFn = POSAdminPrc.doNothing }) => {
  const { user } = useContext(AdminContext);
  const { token, setError, setHint } = useContext(AppContext);
  const [tables, setTables] = useState([]);
  const [cnt, setCnt] = useState(0);
  const [eId, setEId] = useState(null);
  const [area, setArea] = useState(1);
  const [newId, setNewId] = useState(null);
  const [edit, setEdit] = useState(false);

  const addTable = () => {
    setCnt(cnt + 1);
    return {
      id: (cnt + 1).toString(),
      x: Math.random() * window.innerWidth * 0.75 - 50,
      y: Math.random() * window.innerHeight * 0.75 - 50,
      area: area,
      user: null,
      uuid: null,
      name: "Table " + (cnt + 1).toString(),
      isDragging: false,
    };
  };
  useEffect(() => {
    POSAdminPrc.getTables(
      token,
      (message) => setError(message),
      (data) => {
        let tbs = [];
        for (let i = 0; i !== data.length; i++) {
          let tb = addTable();
          tb.id = data[i].id;
          tb.name = data[i].name;
          tb.x = data[i].x;
          tb.y = data[i].y;
          tb.area = data[i].area;
          tb.user = data[i].user;
          tb.order_id = data[i].order_id;
          tb.uuid = data[i].uuid;
          tbs.push(tb);
        }
        setTables(tbs);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragStart = (e) => {
    const id = parseInt(e.target.id().substring(1));
    setTables(
      tables.map((tb) => {
        return {
          ...tb,
          isDragging: tb.id === id,
        };
      })
    );
  };
  const handleDragEnd = (e) => {
    const id = parseInt(e.target.id().substring(1));
    setTables(
      tables.map((tb) => {
        return {
          ...tb,
          x: tb.id === id ? e.target.x() : tb.x,
          y: tb.id === id ? e.target.y() : tb.y,
          isDragging: false,
        };
      })
    );
  };

  const handleDblClick = (tb) => {
    setEId(tb.name);
    setNewId(tb.name);
    setEdit(true);
  };

  return (
    <div className="flex justify-content-center">
      <Dialog
        dlgLabel="Display"
        position="top"
        visible={edit}
        onHide={() => setEdit(false)}
        modal
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        closable={false}
        showHeader={false}
        footer={
          <div className=" border-top-1 surface-border pt-3 flex">
            <Button
              icon="pi pi-times"
              onClick={() => {
                setEdit(false);
              }}
              label="Cancel"
              className="p-button-outlined w-6 mr-2"
            />
            <Button
              icon="pi pi-check"
              onClick={() => {
                setTables(
                  tables.map((tb) => {
                    return {
                      ...tb,
                      name: tb.name === eId ? newId : tb.name,
                    };
                  })
                );
                setEdit(false);
              }}
              label="Save"
              className="w-6 ml-2"
            />
          </div>
        }
      >
        <div className="flex flex-column align-items-center my-4">
          <div className="font-medium text-2xl text-900">
            Edit Table Information
          </div>
        </div>
        <label htmlFor="email" className="block text-900 font-medium mb-2">
          Table number or name
        </label>
        <InputText
          className="w-full mb-3"
          autoComplete="off"
          value={newId}
          onChange={(e) => setNewId(e.target.value)}
        />
      </Dialog>
      <div className="grid flex flex-column">
        <div className="field col-4 flex justify-content-center flex-wrap align-content-center">
          <span className="text-sm p-5">Area:{area}</span>
          <Slider
            min={1}
            max={5}
            className="py-5"
            value={area}
            onChange={(e) => setArea(e.value)}
            orientation="vertical"
          />
        </div>
        {editing && (
          <div className="field col-4 flex justify-content-center align-content-center pt-5">
            <Button
              icon="pi pi-plus-circle text-3xl"
              className="p-button-text"
              onClick={(e) => setTables([...tables, addTable()])}
            />
          </div>
        )}
        {editing && (
          <div className="field col-4 flex justify-content-center align-content-center pt-5">
            <Button
              icon="pi pi-save text-3xl"
              className="p-button-text"
              onClick={(e) => {
                let upTbs = tables
                  .filter((tb) => tb.area === area)
                  .map((tb) => {
                    return {
                      area,
                      name: tb.name,
                      uuid: tb.uuid,
                      user: tb.user == null ? null : tb.user.id,
                      x: Math.round(tb.x),
                      y: Math.round(tb.y),
                    };
                  });
                POSAdminPrc.updateTables(
                  upTbs,
                  area,
                  token,
                  (message) => setError(message),
                  () => {
                    setHint("Table information updated successfully");
                  }
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="grid formgrid p-fluid shadow-2 w-12">
        <div className="field col-12">
          <Stage
            width={window.innerWidth * 0.75}
            height={window.innerHeight * 2}
          >
            <Layer>
              {tables
                .filter((tb) => tb.area === area)
                .map((tb) => {
                  return (
                    <Fragment key={tb.id}>
                      <Text
                        id={tb.id}
                        fontSize={15}
                        text={`Tb ${tb.name}`}
                        //stroke="white"
                        x={tb.x}
                        y={tb.y - 20}
                        // id={`TX-${tb.id}`}
                      />
                      <Rect
                        id={"R" + tb.id}
                        x={tb.x}
                        y={tb.y}
                        width={100}
                        height={100}
                        opacity={0.8}
                        draggable={editing ? true : false}
                        shadowColor="black"
                        shadowBlur={10}
                        shadowOpacity={0.6}
                        shadowOffsetX={tb.isDragging ? 10 : 5}
                        shadowOffsetY={tb.isDragging ? 10 : 5}
                        scaleX={tb.isDragging ? 1.2 : 1}
                        scaleY={tb.isDragging ? 1.2 : 1}
                        fill={
                          tb.user == null
                            ? "green"
                            : tb.user.id === user.id
                            ? "blue"
                            : "red"
                        }
                        onDragStart={editing ? handleDragStart : null}
                        onDragEnd={editing ? handleDragEnd : null}
                        onDblClick={(e) => {
                          if (editing) handleDblClick(tb);
                        }}
                        onClick={editing ? null : (e) => processFn(tb)}
                      />
                      {tb.user !== null && (
                        <Text
                          id={"T2" + tb.id}
                          fontSize={15}
                          text={tb.user.name}
                          fill="white"
                          x={tb.x + 15}
                          y={tb.y + 7}
                          rotation={45}
                          onClick={editing ? null : (e) => processFn(tb)}
                          // id={`TX-${tb.id}`}
                        />
                      )}
                    </Fragment>
                  );
                })}
            </Layer>
          </Stage>
        </div>
      </div>
    </div>
  );
};

export default Layout;
