import React, { useEffect } from "react";
import { Button } from "primereact/button";
import POSAdminPrc from "./POSAdminPrc";

const OrderItemDealSelector = ({ type, deals, processFuc }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (d) => {
    switch (type) {
      case POSAdminPrc.DINE_IN:
        return d.available_dinein;
      case POSAdminPrc.WALK_IN:
        return d.available_walkin;
      case POSAdminPrc.PICK_UP:
        return d.available_pickup;
      case POSAdminPrc.DELIVER:
        return d.available_delivery;
      default:
        return false;
    }
  };

  return (
    <div className="grid p-fluid w-full">
      {deals
        .filter((d) => validate(d))
        .map((deal, index) => (
          <div
            className="flex flex-row flex-wrap col-12 md:col-3 justify-content-center"
            key={index}
          >
            <Button
              iconPos="top"
              icon="pi pi-check-circle text-3xl "
              label={deal.name}
              className="text-3xl flex h-4rem p-button-outlined m-2 h-6rem p-button-success"
              onClick={(e) => {
                processFuc(deal);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default OrderItemDealSelector;
