import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import LoadingPage from "../../common/Utils/LoadingPage";
import BPrc from "../business/BusinessPrc";
import StripePayment from "./StripePayment";

const StripePaymentPage = ({
  amount,
  campaign = null,
  email = null,
  phone = null,
  party = 0,
  pType = "Entry",
  processFn,
}) => {
  const [stripe, setStripe] = useState(null);
  const { token, setError } = useContext(AppContext);
  useEffect(() => {
    BPrc.createPayment(
      amount,
      campaign,
      email,
      phone,
      party,
      pType,
      token,
      (msg) => {
        processFn({
          error: "Can't create a payment",
        });
        setError("Can't create a payment");
      },
      (strip) => setStripe(strip)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (stripe == null) return <LoadingPage />;
  else
    return (
      <StripePayment
        strip_secret={stripe}
        processFn={processFn}
        amount={amount}
      />
    );
};

export default StripePaymentPage;
