import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";

const LocationSearchFInput = ({
  lbl = null,
  value,
  setValue = null,
  setLatLng = null,
  disabled = false,
}) => {
  const [address, setAddresss] = useState(value);
  const [addresses, setAddressses] = useState([]);

  const handleChange = (address) => {
    setAddresss(address);
    if (setValue == null) return;
    if (typeof address.description === "undefined") {
      setValue(address);
    } else {
      setValue(address.description);
    }
  };

  const findLatLng = () => {
    if (setLatLng != null) {
      if (address == null) return;
      let adr = address;
      if (typeof address.description !== "undefined") adr = address.description;
      else return;
      geocodeByAddress(adr)
        .then(function (results) {
          return getLatLng(results[0]);
        })
        .then(function (latLng) {
          setLatLng(latLng, adr);
        })
        .catch((error) => console.error("Error", error));
    }
  };
  const handleSelect = (address) => {
    setAddresss(address);
    if (setValue == null) return;
    if (typeof address.description === "undefined") {
      setValue(address);
    } else {
      setValue(address.description);
    }
  };

  const searchAddress = (event, suggestions) => {
    setTimeout(() => {
      setAddressses([...suggestions]);
    }, 250);
  };

  if (typeof google === "object")
    return (
      <PlacesAutocomplete
        value={
          address == null || typeof address.description === "undefined"
            ? address
            : address.description
        }
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{ componentRestrictions: { country: "ca" } }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <span className="p-float-label">
            <AutoComplete
              disabled={disabled}
              suggestions={addresses}
              field="description"
              completeMethod={(e) => searchAddress(e, suggestions)}
              {...getInputProps({
                className: "location-search-input",
              })}
              onBlur={() => {
                findLatLng();
              }}
            />
          </span>
        )}
      </PlacesAutocomplete>
    );
  else
    return (
      <InputText
        placeholder="Google Map is not loaded"
        value=""
        disabled={true}
      />
    );
};

export default LocationSearchFInput;
