import React, { useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import Tools from "../Tools";
import { Document, Page, Outline } from "react-pdf/dist/esm/entry.webpack5";
import { Message } from "primereact/message";

const PictureComp = ({
  id,
  token = null,
  onError,
  tag = "profile",
  avatar = true,
  inWidth = "250",
}) => {
  const inputFile = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  return (
    <div>
      <input
        type="file"
        id="file"
        accept={tag === "flyer" ? ".pdf" : ".jpg,.png,.jpeg"}
        ref={inputFile}
        style={{ display: "none" }}
        onInput={(e) => {
          //setPhotoUpload(true);
          Tools.uploadImage(
            e.target.files[0],
            id,
            tag,
            token,
            onError,
            function (data) {
              //setPhotoUpload(false);
            }
          );
          e.target.value = "";
        }}
      />
      {avatar && (
        <Avatar
          image={Tools.getImagePath(id, tag)}
          className="mr-2"
          onImageError={(e) => {
            e.target.src = Tools.getDefaultImage();
          }}
          size="xlarge"
          shape="circle"
          onLoad={() => {}}
          onClick={(e) => {
            inputFile.current.click();
          }}
        />
      )}
      {!avatar && tag === "flyer" && (
        <Document
          file={Tools.getImagePath(id, tag)}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          onClick={(e) => {
            if (token != null) inputFile.current.click();
          }}
          style={{ cursor: "pointer" }}
          width={inWidth}
          error={
            token != null ? (
              <Message
                severity="error"
                text="No file found, click here to add one."
              />
            ) : (
              <Message severity="warn" text="Nothing on file." />
            )
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
      {!avatar && tag !== "flyer" && (
        <img
          className="border-circle"
          style={{ cursor: "pointer" }}
          src={Tools.getImagePath(id, tag)}
          onError={(e) => {
            e.target.src = Tools.getNoImage();
          }}
          //preview={true}
          width={inWidth}
          alt="Click to edit"
          onClick={(e) => {
            inputFile.current.click();
          }}
        />
      )}
    </div>
  );
};

export default PictureComp;
