const schemaData = [];

const schemas = {
  CampaignWinReport: {
    showChildren: false,
    readonly: true,
    fields: [
      {
        type: "TXT",
        label: "When",
        field: "when",
        grid: true,
        width: "w-2",
        step: 0,
        mandatory: true,
        disabled: true,
        visible: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        width: "w-3",
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "TXT",
        label: "Prize",
        field: "prize",
        grid: true,
        width: "w-2",
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Amount",
        field: "amount",
        grid: true,
        width: "w-1",
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 3,
        mode: "currency",
        prefix: null,
        suffix: 0,
        currency: null,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Redeemed",
        field: "status",
        grid: true,
        width: "w-1",
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 3,
        onChange: (v) => {},
      },
    ],
  },
  CampaignEntryReport: {
    showChildren: false,
    readonly: true,
    fields: [
      {
        type: "TXT",
        label: "When",
        field: "last_entry",
        grid: true,
        width: "w-2",
        step: 0,
        mandatory: true,
        disabled: true,
        visible: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        width: "w-3",
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Counter",
        field: "counter",
        grid: true,
        width: "w-1",
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 3,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        currency: null,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Winner",
        field: "result",
        grid: true,
        width: "w-1",
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 3,
        onChange: (v) => {},
      },
    ],
  },
  Coupon: {
    package: "CRUD",
    showChildren: false,
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        visible: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Is Percentage",
        field: "percentage",
        grid: true,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Amount",
        field: "amount",
        grid: true,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 3,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        currency: null,
        onChange: (v) => {},
      },
    ],
  },
  Partner: {
    package: "CRUD",
    showChildren: false,
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        visible: true,
        cols: 3,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Surcharge",
        field: "amount",
        grid: true,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 3,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        currency: null,
        onChange: (v) => {},
      },
    ],
  },
  Config: {
    package: "CRUD",
    showChildren: false,
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        visible: true,
        cols: 2,
        onChange: (v) => {},
      },
      {
        type: "TXT",
        label: "Value",
        field: "value",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        cols: 2,
      },
    ],
  },
  User: {
    package: "CRUD",
    showChildren: false,
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        visible: true,
        cols: 2,
        onChange: (v) => {},
      },
      {
        type: "PAS",
        label: "Password",
        field: "password",
        grid: false,
        step: 0,
        mandatory: true,
        disabled: false,
        cols: 2,
      },
      {
        type: "TOG",
        label: "Active",
        field: "active",
        grid: true,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
      },
      {
        type: "TOG",
        label: "Admin",
        field: "admin",
        grid: true,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: (e) => !e.active,
        cols: 4,
      },
      {
        type: "TOG",
        label: "Manager",
        field: "manager",
        grid: true,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: (e) => !e.active,
        cols: 4,
      },
      {
        type: "TOG",
        label: "Driver",
        field: "driver",
        grid: false,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: (e) => !e.active,
        cols: 4,
      },
      {
        type: "TOG",
        label: "Kitchen",
        field: "kitchen",
        grid: false,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: (e) => !e.active,
        cols: 4,
      },
    ],
  },
  Modifier: {
    package: "CRUD",
    showChildren: true,
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "HID",
        field: "parent",
        hidden: true,
        value: null,
      },
      {
        type: "TOG",
        label: "Left/Right",
        field: "has_LR",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Half",
        field: "has_HLF",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Double",
        field: "has_DBL",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Tripple",
        field: "has_TRP",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Mandatory",
        field: "mandatory",
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },

      {
        type: "TOG",
        label: "Print",
        field: "print",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Kitchen Print",
        field: "print_kitchen",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Print Order",
        field: "print_order",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        currency: null,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Exclusive",
        field: "exclusive",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Group Key",
        field: "grouping_key",
        grid: false,
        step: 0,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        currency: null,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TXT",
        label: "Group Label",
        field: "grouping_label",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
    ],
    // actions: [
    //   {
    //     label: "Children ...",
    //     icon: "pi pi-th-large",
    //     command: () => {
    //       let data = schemaData.pop();
    //       schemaData.pop();
    //       let func = schemaData.pop();
    //       func("show child", { field: "parent", value: data.id });
    //     },
    //   },
    // ],
  },
  Menu: {
    package: "CRUD",
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        width: "w-5",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        cols: 2,
        onChange: (v) => {},
      },
      {
        type: "HID",
        field: "parent",
        hidden: true,
        value: null,
      },
      {
        type: "TOG",
        label: "Orderable",
        field: "orderable",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Price",
        field: "price",
        width: "w-2",
        grid: true,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        mode: "currency",
        prefix: null,
        suffix: 0,
        currency: "CAD",
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Modifier Price",
        field: "modifier_price",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        cols: 4,
        visible: (o) => o.orderable,
        mode: "currency",
        prefix: null,
        suffix: 0,
        currency: "CAD",
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Number of free options",
        field: "free_options",
        grid: false,
        step: 0,
        mandatory: false,
        visible: (o) => o.orderable,
        disabled: false,
        cols: 4,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        onChange: (v) => {},
      },
      {
        type: "DRP",
        label: "Pricing method",
        field: "price_model",
        newline: true,
        grid: false,
        step: 0,
        mandatory: (o) => o.orderable,
        visible: (o) => o.orderable,
        disabled: false,
        options: [
          { name: "Fixed Price", code: 1 },
          { name: "Price Table ", code: 2 },
          { name: "Price Model 1", code: 3 },
          { name: "Price Model 2", code: 4 },
        ],
        optionLabel: "name",
        optionValue: "code",
        cols: 4,
        changed: (v) => {},
      },

      {
        type: "TXT",
        label: "Price Table",
        field: "price_table",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Number of items",
        field: "deal_count",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Options have Left/Right",
        field: "has_LR",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        newline: true,
        label: "Available for walkin",
        field: "available_walkin",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for Pickup",
        field: "available_pickup",
        grid: false,
        step: 0,
        mandatory: false,
        visible: (o) => o.orderable,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for delivery",
        field: "available_delivery",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for dine in",
        field: "available_dinein",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for web",
        field: "available_web",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Show on kitchen monitor",
        field: "kitchen_monitor",
        newline: true,
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "CFG",
        label: "Printer",
        field: "printer",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        selector: "printer_",
        optionLabel: "name",
        optionValue: "code",
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Sequence in group",
        field: "sequence",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: (o) => o.orderable,
        cols: 4,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        onChange: (v) => {},
      },
    ],
    showChildren: true,
    actions: [
      {
        name: "Modifiers",
        icon: "pi pi-sliders-h",
        visible: (o) => o.orderable,
        // command: "manageMenuModifier",
        command: () => {
          let func = schemaData[2];
          let row = schemaData[1];
          func("manageMenuModifier", row);
        },
      },
      {
        name: "Price Exception",
        icon: "pi pi-dollar",
        visible: (o) => o.price !== 0,
        command: () => {
          let func = schemaData[2];
          let row = schemaData[1];
          func("manageMenuExceptions", row);
        },
      },
    ],
  },
  Deal: {
    package: "CRUD",
    fields: [
      {
        type: "TXT",
        label: "Name",
        field: "name",
        grid: true,
        step: 0,
        mandatory: true,
        disabled: false,
        cols: 2,
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Price",
        field: "price",
        grid: true,
        step: 0,
        newline: true,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        mode: "currency",
        prefix: null,
        suffix: 0,
        currency: "CAD",
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Discount",
        field: "discount",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: true,
        cols: 4,
        visible: true,
        mode: "currency",
        prefix: null,
        suffix: 0,
        currency: "CAD",
        onChange: (v) => {},
      },
      {
        type: "NUM",
        label: "Number of free options",
        field: "free_options",
        grid: false,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: false,
        cols: 4,
        mode: "decimal",
        prefix: null,
        suffix: 0,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        newline: true,
        label: "Available for walkin",
        field: "available_walkin",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for Pickup",
        field: "available_pickup",
        grid: false,
        step: 0,
        mandatory: false,
        visible: true,
        disabled: false,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for delivery",
        field: "available_delivery",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for dine in",
        field: "available_dinein",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Available for web",
        field: "available_web",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        onChange: (v) => {},
      },
      {
        type: "TOG",
        label: "Restrict options",
        field: "restrict_options",
        grid: false,
        step: 0,
        mandatory: false,
        disabled: false,
        visible: true,
        cols: 4,
        onChange: (v) => {},
      },
    ],
    showChildren: false,
    actions: [
      {
        name: "Menu Items",
        icon: "pi pi-sliders-h",
        visible: true,
        // command: "manageMenuModifier",
        command: () => {
          let func = schemaData[2];
          let row = schemaData[1];
          func("manageDealMenu", row);
        },
      },
    ],
  },
};

export { schemas, schemaData };
