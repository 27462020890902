import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import AdminPrc from "../../common/AdminPrc";
import { AppContext } from "../../common/AppContext";
import BusinessCampaigns from "../business/BusinessCampaigns";
import PictureComp from "../../common/Utils/PictureComp";
import { Dialog } from "primereact/dialog";
import ProfilePage from "../../common/Auth/ProfilePage";
import { Ripple } from "primereact/ripple";

const BOMain = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [business, setBusiness] = useState(null);
  const [bProfile, setBProfile] = useState(null);
  const { token, setError, setHint } = useContext(AppContext);
  const [editBusiness, setEditBusiness] = useState(false);

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => setEditBusiness(false)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => setEditBusiness(false)}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:p-8">
      <Dialog
        header={`Edit ${business == null ? "" : business.firstname}`}
        //maximized={true}
        //maximizable={true}
        visible={editBusiness}
        modal={true}
        // footer={renderFooter("displayBasic")}
        onHide={() => setEditBusiness(false)}
      >
        <ProfilePage inParty={business} inProfile={bProfile} />
      </Dialog>
      <Button
        label="Register a new business"
        icon="pi pi-star-o"
        type="button"
        className="mr-3 p-button-outlined p-button-success"
        onClick={(e) => {
          navigate("/bo_rb");
        }}
      ></Button>
      <hr />
      <InputText
        keyfilter="pnum"
        className="w-3 p-2"
        autoComplete="off"
        placeholder="Business phone number"
        value={phone}
        onBlur={(e) => {
          setPhone(phone.replace(/\D+/g, ""));
        }}
        onChange={(e) => setPhone(e.target.value)}
      />

      <Button
        label="Load Business"
        icon="pi pi-star-o"
        type="button"
        className="ml-3 p-button-outlined p-button-success"
        onClick={(e) => {
          setBusiness(null);
          AdminPrc.findEntity(
            "Party",
            { fields: ["phone"], values: [phone] },
            token,
            (msg) => setError(msg),
            (data) => {
              if (data.length === 0) {
                setBusiness(null);
                setError("No business is found");
                setPhone("");
              } else {
                setBusiness(data[0]);
                AdminPrc.findEntity(
                  "Profile",
                  { fields: ["id"], values: [data[0].profile_id] },
                  token,
                  (msg) => setError(msg),
                  (data) => {
                    if (data.length === 0) setBProfile(null);
                    else setBProfile(data[0]);
                  }
                );
              }
            }
          );
        }}
      ></Button>
      {business !== null && (
        <div>
          <hr />

          <div className="surface-ground px-2 py-4 md:px-3 lg:px-4">
            <div
              className="flex flex-wrap relative overflow-hidden"
              style={{
                borderRadius: "1rem",
                background:
                  "radial-gradient(100% 1126.49% at 100% 0%, rgba(106, 200, 212, 0.4) 0%, rgba(33, 33, 33, 0) 100%), #212121",
              }}
            >
              <div className="px-3 py-4">
                <span className="uppercase text-cyan-500 font-bold">
                  BUSINESS PROFILE
                </span>
                <div className="text-white text-medium text-5xl mt-3 mb-5">
                  {business.firstname}
                </div>
                <button
                  onClick={(e) => setEditBusiness(true)}
                  className="p-ripple appearance-none p-3 bg-cyan-500 hover:bg-cyan-300 cursor-pointer transition-colors font-medium gray-900 border-none p-component transition-colors transition-duration-150"
                  style={{ borderRadius: "35px" }}
                >
                  Edit Business Profile
                  <Ripple />
                </button>
              </div>

              <div
                className="static lg:absolute block mx-auto"
                style={{ top: "1rem", right: "1rem" }}
              >
                {bProfile != null && (
                  <PictureComp
                    id={bProfile.id}
                    token={token}
                    avatar={false}
                    inWidth="150"
                  />
                )}
              </div>
            </div>
          </div>
          <BusinessCampaigns inParty={business} />
        </div>
      )}
    </div>
  );
};

export default BOMain;
