import React, { createContext, useState } from "react";

export const AdminContext = createContext();

const POSAdminContextProvider = (props) => {
  const [menus, setMenus] = useState(null);
  const [deals, setDeals] = useState(null);
  const [user, setUser] = useState(null);
  const [modifiers, setModifiers] = useState(null);
  const [menuModifiers, setMenuModifiers] = useState(null);
  const [menuTree, setMenuTree] = useState(null);
  const [modTree, setModTree] = useState(null);
  const [discount, setDiscount] = useState(null);

  const findMenu = (m, head) => {
    if (typeof head === "undefined") return null;
    let menu = head.find((i) => i.id === m);
    if (typeof menu === "undefined") {
      for (let i in head) {
        menu = findMenu(m, head[i].children);
        if (menu != null) {
          return menu;
        }
      }
    } else {
      return menu;
    }
    return null;
  };

  const findModifier = (m, head) => {
    if (typeof head === "undefined") return null;
    let mod = head.find((i) => i.id === m);
    if (typeof mod === "undefined") {
      for (let i in head) {
        mod = findModifier(m, head[i].children);
        if (mod != null) {
          return mod;
        }
      }
    } else {
      return mod;
    }
    return null;
  };

  const addToRightChild = (parent, m) => {
    if (typeof m.children === "undefined" || m.children.length === 0) {
      // This mod has no child and is already selected, do nothing!
      return;
    }
    for (let i in m.children) {
      let mc = m.children[i];
      let nc = parent.children.find((c) => c.key === mc.key);
      if (typeof nc === "undefined") {
        // This mod was not found in the parent's children
        // just add it and you're good.
        parent.children.push(mc);
      } else {
        // I found the mod in parent's children set!
        // recuersive this logic forward!
        addToRightChild(nc, mc);
      }
    }
  };

  const findModInMenu = (key, parent, modifier) => {
    let s = parent.find((m) => m.key === key);
    if (typeof s !== "undefined")
      if (typeof s.children === "undefined")
        return {
          key: s.key,
          data: { ...modifier }, //, ...s.data.mod },
        };
      else
        return {
          key: s.key,
          data: { name: s.data.name }, //, ...s.data.mod },
          children: s.children,
        };

    for (let i in parent) {
      let m = parent[i];
      if (typeof m.children !== "undefined") {
        let mod = findModInMenu(key, m.children, modifier);
        if (mod != null) {
          return {
            key: m.key,
            data: { name: m.data.name }, //, ...m.data.mod },
            children: [mod],
          };
        }
      }
    }
    return null;
  };

  return (
    <AdminContext.Provider
      value={{
        menus,
        deals,
        setDeals,
        setMenus,
        modifiers,
        setModifiers,
        setMenuModifiers,
        menuModifiers,
        modTree,
        setModTree,
        menuTree,
        setMenuTree,
        user,
        setUser,
        findMenu,
        findModifier,
        addToRightChild,
        findModInMenu,
        discount,
        setDiscount,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default POSAdminContextProvider;
