import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  Fragment,
} from "react";
import { Dialog } from "primereact/dialog";
import Campaign from "./Campaign";
import { TabView, TabPanel } from "primereact/tabview";
import LocationSearchFInput from "../../common/Utils/LocationSearchFInput";
import { AppContext } from "../../common/AppContext";
import Tools from "../../common/Tools";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { confirmPopup } from "primereact/confirmpopup";
import BPrc from "./BusinessPrc";
import { useNavigate } from "react-router-dom";
import EntryForm from "../../common/CRUD/EntryForm";
import { InputSwitch } from "primereact/inputswitch";
import POSAdminPrc from "../admin/POSAdminPrc";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import AppTools from "../AppTools";

function BusinessCampaigns({ openAdd = false, inParty = null }) {
  const [addNew, setAddNew] = useState(openAdd);
  const [editPrize, setEditPrize] = useState(null);
  const [entity, setEntity] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const [eID, setEID] = useState(0);
  const [includeArchived, setIncludeArchived] = useState(false);
  const [showEPage, setShowEPage] = useState(false);
  const [business, setBusiness] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
  });
  const [campaign, setCampaign] = useState({
    name: "",
    mode: -1,
    run_interval: 0,
    entry_cost: 0.0,
    commission: 0,
    costlimit: 0.0,
    id: 0,
    entry: 0,
    qr: "",
  });
  const [prizes, setPrizes] = useState([]);
  const [prize, setPrize] = useState({
    fresh: true,
    name: "",
    odds: 0,
    cost: 0.0,
    max: 0,
  });

  const [campaigns, setCampaigns] = useState([]);

  const navigate = useNavigate();

  const { token, setError, setHint, party, setParty, profile } =
    useContext(AppContext);
  const op = useRef(null);

  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);

  const myParty = inParty == null ? party : inParty;

  const buildPrizes = (c = null) => {
    const items = campaigns.find((a) => a.campaign.id === c.id).items;
    const pz = [];
    items.forEach((i) => {
      pz.push({
        fresh: false,
        name: i.prize,
        odds: i.odds,
        cost: i.price,
        max: i.limits,
        id: i.id,
      });
    });
    return pz;
  };

  const emailQR = (qr) => {
    // BPrc.emailCampaignQR(
    //   qr,
    //   token,
    //   (message) => {
    //     setError(message);
    //   },
    //   () => {
    //     setHint("Campain's QR is sent to your email address.");
    //   }
    // );
    BPrc.emailCampaignQR2(qr, token);
    Tools.processEmail();
    setHint("Campain's QR is sent to your email address.");
  };

  const endCampaign = (qr) => {
    BPrc.endCampaign(
      qr,
      token,
      (message) => {
        setError(message);
      },
      () => {
        setHint("Campain is terminated.");
      }
    );
  };
  const showCampaignEntries = (id) => {
    setEntity("CampaignEntryReport");
    setEID(id);
    setShowEPage(true);
  };
  const showCampaignWinners = (id) => {
    setEntity("CampaignWinReport");
    setEID(id);
    setShowEPage(true);
  };

  const runCampaign = (qr) => {
    BPrc.runCampaign(
      qr,
      token,
      (message) => {
        setError(message);
      },
      (ws, updatedCampaignId) => {
        setHint("Campain is executed and there are " + ws.length + " winners.");
        let newcs = [...campaigns];
        let updated = newcs.find((c) => c.campaign.id === updatedCampaignId);
        updated.winnerCount = ws.length;
        updated.campaign.status = 0;
        setCampaigns([...newcs]);
        showCampaignWinners(updatedCampaignId);
      }
    );
  };

  const archiveCampaign = (qr) => {
    BPrc.archiveCampaign(
      qr,
      token,
      (message) => {
        setError(message);
      },
      () => {
        setHint("Campain is archived.");
        getCampaigns();
      }
    );
  };
  const CloneCampaign = (qr) => {
    BPrc.CloneCampaign(
      qr,
      token,
      (message) => {
        setError(message);
      },
      () => {
        setHint("Campain is Cloned.");
        getCampaigns();
      }
    );
  };

  const addOrEditCampaign = (c = null) => {
    setBusiness({
      id: myParty == null ? 0 : myParty.id,
      coordinate: myParty == null ? 0 : myParty.coordinate,
      name:
        myParty == null || myParty.firstname === null ? "" : myParty.firstname,
      address:
        myParty == null || myParty.address === null ? "" : myParty.address,
      email: profile == null ? "" : profile.username,
      phone: myParty == null || myParty.phone === null ? "" : myParty.phone,
    });
    setCampaign({
      name: c == null ? "" : c.name,
      mode: c == null ? -1 : c.mode,
      run_interval: c == null ? 0 : c.run_interval,
      costlimit: c == null ? 0.0 : c.costlimit,
      entry_cost: c == null ? 0.0 : c.entry_cost,
      commission: c == null ? 0.0 : c.commission,
      id: c == null ? 0 : c.id,
      qr: c == null ? "" : c.qr,
      entry: c == null ? 0 : c.entry,
    });
    setPrizes(c == null ? [] : buildPrizes(c));
    setPrize({
      fresh: true,
      name: "",
      odds: getRemainingOdds(),
      cost: 0.0,
      max: 0,
    });
    setStep(0);
    setAddNew(true);
  };

  const addCampaign = () => {
    addOrEditCampaign();
  };

  const getRemainingOdds = () => {
    if (campaign.mode !== 0 && campaign.mode !== 2) return 0;
    let total = prizes.reduce((a, b) => a + b.odds, 0);
    return 100 - total;
  };
  const getStyle1 = (index) => {
    if (step === index) {
      return "border-2 border-green-400 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1 mx-2";
    } else {
      if (maxStep < index)
        return "border-1 border-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1 mx-2";
      else
        return "border-1 border-blue-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1 mx-2";
    }
  };
  const getStyle2 = (index) => {
    if (step === index) {
      return "text-green-500";
    } else {
      if (maxStep < index) return "text-600";
      else return "text-blue-300";
    }
  };
  const validateStep = (forward = true) => {
    switch (step) {
      case 2:
        if (prizes.length === 0)
          return "Please add at least one prize or giveaway for your campaign.";
      // eslint-disable-next-line no-fallthrough
      case 1:
        if (!campaign.name)
          return "Campaign name is mandatory. Please provide a valid name.";
        if (campaign.mode === -1) return "Please select a campaign type.";
      // eslint-disable-next-line no-fallthrough
      case 0:
        if (!business.name)
          return "Business name is mandatory. Please provide a valid business name.";
        if (!business.phone || !Tools.validatePhone(business.phone))
          return "Business phone number is mandatory. Please provide a valid phone number.";
        if (!business.email || !Tools.validateEmail(business.email))
          return "Business email is mandatory. Please provide a valid email address.";
        if (!business.address || !business.coordinate)
          return "Business adress is mandatory. Please provide a valid address.";
      // eslint-disable-next-line no-fallthrough
      default:
        return null;
    }
  };
  const goToStep = (index) => {
    if (step > index) {
      // Go back, no validation needed!
    } else {
      let err = validateStep();
      if (err != null) {
        setError(err);
        return;
      }
    }
    if (maxStep < step) setMaxStep(index);
    setStep(index);
  };

  const getHeader = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={getStyle1(index)}>
            <i
              className={
                "far fa-building  text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 " +
                getStyle2(index)
              }
            ></i>
            <div>
              <div className={"font-medium mb-1 " + getStyle2(index)}>
                Step 1
              </div>
              <span className="text-600 text-sm  md:block">
                Business Basic Information
              </span>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={getStyle1(index)}>
            <i
              className={
                "pi pi-sun text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 " +
                getStyle2(index)
              }
            ></i>
            <div>
              <div className={"font-medium mb-1 " + getStyle2(index)}>
                Step 2
              </div>
              <span className="text-600 text-sm  md:block">
                Campaign Information
              </span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={getStyle1(index)}>
            <i
              className={
                "pi pi-tags text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 " +
                getStyle2(index)
              }
            ></i>
            <div>
              <div className={"font-medium mb-1 " + getStyle2(index)}>
                Step 3
              </div>
              <span className="text-600 text-sm  md:block">
                Prizes and Giveaways
              </span>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={getStyle1(index)}>
            <i
              className={
                "pi pi-upload text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 " +
                getStyle2(index)
              }
            ></i>
            <div>
              <div className={"font-medium mb-1 " + getStyle2(index)}>
                Step 4
              </div>
              <span className="text-600 text-sm  md:block">
                Review and Confirm
              </span>
            </div>
          </div>
        );
      default:
        return <h1>Not Found</h1>;
    }
  };

  const getCampaigns = () => {
    if (myParty != null) {
      BPrc.getMyCampaigns(
        myParty.qr,
        includeArchived,
        token,
        (message) => {
          setError(message);
        },
        (data) => {
          if (typeof data === "undefined") {
            setHint(
              "You don't have a campaign yet, define your first one now!"
            );
          } else {
            setCampaigns([...data]);
          }
        }
      );
    }
  };
  useEffect(() => {
    getCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeArchived]);

  if (addNew === false && myParty == null) {
    setHint(
      "Congratulations! You are almost there! An account was created for you and your temporary password is sent to be your email address. Please check your email to acticate your account and get the other information about your newly created campaign!"
    );
    navigate("/");
    return "";
  }
  return (
    <div className="md:p-6 lg:p-8">
      <ManagedDialog
        closeFn={setShowEPage}
        visible={showEPage}
        maximized={false}
        style={{ width: "75vw" }}
        header={
          entity === "CampaignEntryReport"
            ? "List of Campaign Entries"
            : "List of Campaign Winners"
        }
      >
        <EntryForm
          showTitle={false}
          inEntity={entity}
          canAdd={false}
          inCondition={{ field: "campaign_id", value: eID }}
        />
      </ManagedDialog>

      <div className="surface-card" style={{ minHeight: "20rem" }}>
        <div className="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
          <div className="mb-3 lg:mb-0">
            <div className="text-3xl font-medium text-900 mb-3">Campaigns</div>
            <div className="text-bluegray-500 text-xl mr-0 md:mr-3 flex align-items-center">
              <Button
                icon="pi pi-plus-circle text-4xl"
                className="p-button-text mx-2"
                onClick={(e) => {
                  addCampaign();
                }}
              />
              Add
            </div>
          </div>

          <Dialog
            dlgLabel="Display"
            position="top"
            header={
              campaign == null ? "Add a campaign" : `Edit ${campaign.name}`
            }
            visible={addNew}
            style={{ width: "75vw" }}
            onHide={() => {
              setAddNew(false);
              if (profile.role === AppTools.ADMIN) navigate("/");
              else navigate("/campaigns");
            }}
          >
            <TabView activeIndex={step} onTabChange={(e) => setStep(e.index)}>
              <TabPanel headerTemplate={getHeader(0)}>
                <p className="font-light text-indigo-500">
                  Please provide the basic information about your business. All
                  feilds are mandatory.
                </p>
                <div className="grid formgrid p-fluid mt-5">
                  <div className="field col-12 md:col-6">
                    <label className="font-medium text-orange-500">
                      Business Name
                    </label>
                    <span className="font-medium text-orange-800"> *</span>
                    <InputText
                      className="w-full mb-3"
                      autoComplete="off"
                      value={business.name}
                      onChange={(e) =>
                        setBusiness({ ...business, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label className="font-medium text-orange-500">
                      Address
                    </label>
                    <span className="font-medium text-orange-800"> *</span>
                    <LocationSearchFInput
                      value={business.address}
                      setLatLng={(latlng, v) => {
                        setBusiness({
                          ...business,
                          coordinate: latlng,
                          address: v,
                        });
                      }}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label className="font-medium text-orange-500">Phone</label>
                    <span className="font-medium text-orange-800"> *</span>
                    <InputText
                      keyfilter="pnum"
                      value={business.phone}
                      onChange={(e) => {
                        setBusiness({ ...business, phone: e.target.value });
                      }}
                      onBlur={(e) => {
                        setBusiness({
                          ...business,
                          phone: business.phone.replace(/\D+/g, ""),
                        });
                      }}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label className="font-medium text-orange-500">Email</label>
                    <span className="font-medium text-orange-800"> *</span>
                    <InputText
                      type="text"
                      keyfilter="email"
                      disabled={myParty !== null}
                      value={business.email}
                      onChange={(e) => {
                        setBusiness({ ...business, email: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel headerTemplate={getHeader(1)}>
                <div className="font-light text-indigo-500 mr-0 md:mr-3 flex align-items-center">
                  Please provide the basic information about your campaign. A
                  campaign has the following fields
                  <Button
                    icon="pi pi-info-circle"
                    className="p-button-text mx-2 p-button-warning"
                    onClick={(e) => op.current.toggle(e)}
                  />
                  <OverlayPanel ref={op} className="w-6">
                    <ul className="list-disc font-light text-indigo-500 text-xs">
                      <li>
                        <strong>Campaign name</strong>: Give your campaign a
                        meaningfull name, somthing catchy, something to
                        encourage your clients to participate.
                      </li>
                      <li>
                        <strong>Cost Limit</strong>: Setup a cost limit for your
                        campaign and system ensures that no your spending on
                        this campaign never exceeds this limit. This is a very
                        good way to get the most out of your campaign without
                        spending an arm and a leg.
                      </li>
                      <li>
                        <strong>Campaign Type</strong>: We support several
                        different campaign types. Depending on your marketing
                        strategy select a type that matches your goals:
                        <ul className="list-decimal">
                          <li>
                            <strong>Sping and Win</strong>: THis campaign helps
                            you to gamify your marketing campaign. Your clients
                            spin a wheel for a chance to win one of many prizes
                            you have selected for them to win!
                          </li>
                          <li>
                            <strong>Stamp</strong>: In this campaign your
                            clients get a stamp for each entry (purchase, visit
                            or however you decide it) and collect these stamps
                            for winning set prizes. Encaurage your clients to
                            spend and visit more!
                          </li>
                          <li>
                            <strong>Draw</strong>: A draw campaign is an
                            excellant way to get more business and visitors to
                            your business and events. People enter a draw tp win
                            set prizes a simple and proved strategy to increase
                            client engagment in your marketing schemes.
                          </li>
                          <li>
                            <strong>Discount</strong>: This simple campaign is
                            designed to help your clients to receive a set
                            discount on their transations. A perfect way to
                            build partnership with other businesses or attract
                            new clients.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </OverlayPanel>
                </div>
                <div className="grid formgrid p-fluid mt-2">
                  <div className="col-12 pb-2">
                    <label className="font-medium text-orange-500">
                      Campaign Type
                    </label>
                  </div>
                  <div className="col-12 md:col-3">
                    <RadioButton
                      disabled={campaign.id !== 0}
                      inputId="cType0"
                      name="cType"
                      value="0"
                      onChange={(e) => {
                        setCampaign({ ...campaign, mode: 0 });
                      }}
                      checked={campaign.mode === 0}
                    />
                    <label className="mx-2">Spin & Win</label>
                  </div>
                  <div className=" col-12 md:col-3">
                    <RadioButton
                      disabled={campaign.id !== 0}
                      inputId="cType1"
                      name="cType"
                      value="1"
                      onChange={(e) => {
                        setCampaign({ ...campaign, mode: 1 });
                      }}
                      checked={campaign.mode === 1}
                    />
                    <label className="mx-2">Stamp</label>
                  </div>
                  <div className="col-12 md:col-3">
                    <RadioButton
                      disabled={campaign.id !== 0}
                      inputId="cType2"
                      name="cType"
                      value="2"
                      onChange={(e) => {
                        setCampaign({ ...campaign, mode: 2 });
                      }}
                      checked={campaign.mode === 2}
                    />
                    <label className="mx-2">Draw</label>
                  </div>
                  <div className="col-12 md:col-3">
                    <RadioButton
                      disabled={campaign.id !== 0}
                      inputId="cType3"
                      name="cType"
                      value="3"
                      onChange={(e) => {
                        setCampaign({ ...campaign, mode: 3 });
                      }}
                      checked={campaign.mode === 3}
                    />
                    <label className="mx-2">Discount</label>
                  </div>
                  <div className="col-12 md:col-6 p-2 pt-3">
                    <label className="font-medium text-orange-500">
                      Campaign Name
                    </label>
                    <span className="font-medium text-orange-800"> *</span>
                    <InputText
                      className="w-full mt-2"
                      autoComplete="off"
                      value={campaign.name}
                      onChange={(e) =>
                        setCampaign({ ...campaign, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-6 md:col-3 p-2 pt-3">
                    <label className="font-medium text-orange-500">
                      Spending Limit
                    </label>
                    <InputNumber
                      className="w-full mt-2"
                      mode="currency"
                      currency="CAD"
                      value={campaign.costlimit}
                      onChange={(e) => {
                        setCampaign({ ...campaign, costlimit: e.value });
                      }}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                  {campaign.mode !== 3 && campaign.mode !== 1 && (
                    <div className="col-6 md:col-3 p-2 pt-3">
                      <label className="font-medium text-orange-500">
                        Participation Cost
                      </label>
                      <InputNumber
                        className="w-full mt-2"
                        mode="currency"
                        currency="CAD"
                        value={campaign.entry_cost}
                        onChange={(e) => {
                          setCampaign({ ...campaign, entry_cost: e.value });
                        }}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  )}
                  {campaign.mode === 3 && (
                    <div className="col-6 md:col-3 p-2 pt-3">
                      <label className="font-medium text-orange-500">
                        Commission
                      </label>
                      <InputNumber
                        className="w-full mt-2"
                        prefix="%"
                        min={1}
                        mode="decimal"
                        value={campaign.commission}
                        onChange={(e) => {
                          setCampaign({ ...campaign, commission: e.value });
                        }}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  )}
                  {campaign.mode === 2 && (
                    <Fragment>
                      <div className="col-12 p-2 pt-3">
                        <label className="font-medium text-orange-500">
                          Run Interval
                        </label>
                      </div>
                      <div className="col-12 md:col-3">
                        <RadioButton
                          inputId="iType0"
                          name="iType"
                          value="0"
                          onChange={(e) => {
                            setCampaign({ ...campaign, run_interval: 0 });
                          }}
                          checked={campaign.run_interval === 0}
                        />
                        <label className="mx-2">Manual</label>
                      </div>
                      <div className="col-12 md:col-3">
                        <RadioButton
                          inputId="iType1"
                          name="iType"
                          value="1"
                          onChange={(e) => {
                            setCampaign({ ...campaign, run_interval: 1 });
                          }}
                          checked={campaign.run_interval === 1}
                        />
                        <label className="mx-2">Hourly</label>
                      </div>
                      <div className="col-12 md:col-3">
                        <RadioButton
                          inputId="iType2"
                          name="iType"
                          value="2"
                          onChange={(e) => {
                            setCampaign({ ...campaign, run_interval: 2 });
                          }}
                          checked={campaign.run_interval === 2}
                        />
                        <label className="mx-2">Twice Daily</label>
                      </div>
                      <div className="col-12 md:col-3">
                        <RadioButton
                          inputId="iType3"
                          name="iType"
                          value="3"
                          onChange={(e) => {
                            setCampaign({ ...campaign, run_interval: 3 });
                          }}
                          checked={campaign.run_interval === 3}
                        />
                        <label className="mx-2">Daily</label>
                      </div>
                    </Fragment>
                  )}
                  <div className="p-2 pt-3 col-12">
                    <label className="font-medium text-orange-500">
                      Entry limits (How often someone can enter this campaign?)
                    </label>
                  </div>
                  <div className="col-12 md:col-3">
                    <RadioButton
                      inputId="eType0"
                      name="eType"
                      value="0"
                      onChange={(e) => {
                        setCampaign({ ...campaign, entry: 0 });
                      }}
                      checked={campaign.entry === 0}
                    />
                    <label className="mx-2">Open</label>
                  </div>
                  <div className="col-12 md:col-3">
                    <RadioButton
                      inputId="eType1"
                      name="eType"
                      value="1"
                      onChange={(e) => {
                        setCampaign({ ...campaign, entry: 1 });
                      }}
                      checked={campaign.entry === 1}
                    />
                    <label className="mx-2">Only Once</label>
                  </div>
                  <div className=" col-12 md:col-3">
                    <RadioButton
                      inputId="eType2"
                      name="eType"
                      value="2"
                      onChange={(e) => {
                        setCampaign({ ...campaign, entry: 2 });
                      }}
                      checked={campaign.entry === 2}
                    />
                    <label className="mx-2">Once Daily</label>
                  </div>
                </div>
              </TabPanel>
              <TabPanel headerTemplate={getHeader(2)}>
                <p className="font-light text-indigo-500 mr-0 md:mr-3 flex align-items-center">
                  Your {BPrc.getCampaignType(campaign.mode)} campaign needs some
                  giveaways now.
                </p>
                <div className="grid formgrid p-fluid">
                  <div className="field col-12 md:col-8">
                    <div className="grid formgrid p-fluid">
                      <div className="field col-12 md:col-6 flex flex-wrap align-content-end">
                        <label className="font-medium text-orange-500">
                          Prize Description
                        </label>
                        <span className="font-medium text-orange-800"> *</span>
                        <InputText
                          className="w-full"
                          autoComplete="off"
                          value={prize.name}
                          onChange={(e) =>
                            setPrize({ ...prize, name: e.target.value })
                          }
                        />
                      </div>
                      {(campaign.mode === 0 || campaign.mode === 2) && (
                        <div className="field col-12 md:col-6 flex flex-wrap align-content-end ">
                          <label className="font-medium text-orange-500">
                            Odds: What are the odds for players to win this
                            prize?
                          </label>
                          <InputNumber
                            prefix="%"
                            min={1}
                            mode="decimal"
                            value={prize.odds}
                            onChange={(e) => {
                              setPrize({ ...prize, odds: e.value });
                            }}
                            onFocus={(e) => e.target.select()}
                          />
                        </div>
                      )}
                      {campaign.mode === 1 && (
                        <div className="field col-12 md:col-6 flex flex-wrap align-content-end ">
                          <label className="font-medium text-orange-500">
                            Stamps: Number of stamps needed to win this prize?
                          </label>
                          <InputNumber
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            decrementButtonClassName="p-button-danger"
                            incrementButtonClassName="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            mode="decimal"
                            min={1}
                            value={prize.odds}
                            onChange={(e) => {
                              setPrize({ ...prize, odds: e.value });
                            }}
                            onFocus={(e) => e.target.select()}
                          />
                        </div>
                      )}
                      {campaign.mode === 3 && (
                        <div className="field col-12 md:col-6 flex flex-wrap align-content-end ">
                          <label className="font-medium text-orange-500">
                            Discount: What is the target discount?
                          </label>
                          <InputNumber
                            prefix="%"
                            min={1}
                            max={100}
                            value={prize.odds}
                            onChange={(e) => {
                              setPrize({ ...prize, odds: e.value });
                            }}
                            onFocus={(e) => e.target.select()}
                          />
                        </div>
                      )}
                      {campaign.mode !== 1 && (
                        <div className="field col-12 md:col-6 flex flex-wrap align-content-end ">
                          <label className="font-medium text-orange-500">
                            Quantity: How many of this prize should be awarded.
                          </label>
                          <InputNumber
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            decrementButtonClassName="p-button-danger"
                            incrementButtonClassName="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            value={prize.max}
                            mode="decimal"
                            min={0}
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => {
                              setPrize({ ...prize, max: e.value });
                            }}
                          />
                        </div>
                      )}
                      <div className="field col-12 md:col-6 flex flex-wrap align-content-end ">
                        <label className="font-medium text-orange-500">
                          Cost: To maintain the campaign's maximum spending.
                        </label>
                        <InputNumber
                          mode="currency"
                          disabled={campaign.costlimit === 0}
                          currency="CAD"
                          value={prize.cost}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) => {
                            setPrize({ ...prize, cost: e.value });
                          }}
                        />
                      </div>
                      <div className="field col-12 flex align-content-end justify-content-end ">
                        {editPrize != null && (
                          <Button
                            icon="pi pi-trash"
                            label="Remove"
                            className="p-button-rounded p-button-outlined p-button-danger mx-5"
                            onClick={(event) => {
                              confirmPopup({
                                target: event.currentTarget,
                                message:
                                  "Are you sure you want to remove this prize?",
                                className: "text-3xl",
                                icon: "pi pi-info-circle text-3xl",
                                acceptClassName: "text-2xl p-button-danger",
                                rejectClassName: "text-2xl p-button-info",
                                accept: () => {
                                  const found = prizes.find(
                                    (p) => p.name === editPrize
                                  );
                                  if (typeof found === "undefined") {
                                    setError(
                                      "Prize can not be located. Please select the prize from right table and try again."
                                    );
                                    return;
                                  }
                                  const newPz = prizes.filter(
                                    (p) => p.name !== prize.name
                                  );
                                  setPrizes(newPz);
                                  setPrize({
                                    fresh: true,
                                    name: "",
                                    odds: getRemainingOdds(),
                                    cost: 0.0,
                                    max: 0,
                                  });
                                  setEditPrize(null);
                                },
                                reject: () => {},
                              });
                            }}
                          />
                        )}
                        {editPrize != null && (
                          <Button
                            label="Update"
                            icon="pi pi-check"
                            className="p-button-rounded p-button-outlined p-button-success"
                            onClick={(e) => {
                              const found = prizes.find(
                                (p) =>
                                  (typeof p.id !== "undefined" &&
                                    p.id === prize.id) ||
                                  p.name === editPrize
                              );
                              if (found === null) {
                                setError(
                                  "Prize can not be located. Please select the prize from right table and try again."
                                );
                                return;
                              }
                              const newPz = prizes.filter(
                                (p) => p.name !== found.name
                              );
                              setPrizes([...newPz, prize]);
                              setPrize({
                                fresh: true,
                                name: "",
                                odds: getRemainingOdds(),
                                cost: 0.0,
                                max: 0,
                              });
                              setEditPrize(null);
                            }}
                          />
                        )}
                        {editPrize == null && (
                          <Button
                            icon="pi pi-plus"
                            label="Add"
                            className="p-button-rounded p-button-outlined p-button-success"
                            onClick={(e) => {
                              if (!prize.name) {
                                setError(
                                  "Prize description is mandatory. Please provide a valid description."
                                );
                                return;
                              }
                              if (campaign.mode === 0 || campaign.mode === 2) {
                                let ntot = getRemainingOdds() - prize.odds;
                                if (ntot < 0 || prize.odds < 1) {
                                  setError(
                                    "Total odds of winning all prizes defined in a campaign can not exceed 100%."
                                  );
                                  return;
                                }
                              } else if (campaign.mode === 1) {
                                if (!prize.odds || prize.odds < 1) {
                                  setError(
                                    "Please enter a valid value for number of stamps needed to win this prize."
                                  );
                                  return;
                                }
                              } else if (campaign.mode === 3) {
                                if (!prize.odds || prize.odds < 1) {
                                  setError(
                                    "Please enter a valid discount percentage for this prize."
                                  );
                                  return;
                                }
                              }
                              setPrizes([...prizes, prize]);
                              setPrize({
                                fresh: true,
                                name: "",
                                odds:
                                  campaign.mode === 0 || campaign.mode === 2
                                    ? getRemainingOdds() - prize.odds
                                    : 0,
                                cost: 0.0,
                                max: 0,
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field col-12 md:col-4 bg-blue-50 border-round border-2 border-dashed border-green-200">
                    <div className="text-500 my-2 flex justify-content-center font-semibold">
                      Campaign's prize list
                    </div>
                    <ul className="list-none p-0 m-0">
                      {prizes.map((p, index) => (
                        <li
                          className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap"
                          key={index}
                        >
                          <div className="text-500 w-6 md:w-2 font-medium">
                            {p.odds}
                            {campaign.mode !== 1 ? "%" : ""}
                          </div>
                          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {p.name}
                            {p.cost === 0 ? "" : " for $" + p.cost}
                          </div>
                          <div className="w-6 md:w-2 flex justify-content-end">
                            <Button
                              label="Edit"
                              icon="pi pi-pencil"
                              className="p-button-text"
                              onClick={(e) => {
                                setPrize({ ...p });
                                setEditPrize(p.name);
                              }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </TabPanel>
              <TabPanel headerTemplate={getHeader(3)}>
                <p className="font-light text-indigo-500 mr-0 md:mr-3 flex align-items-center">
                  Review your campaign and make sure all information is correct
                  then hit save.
                </p>
                <div className="grid formgrid p-fluid">
                  <div className="field col-12 md:col-8">
                    <ul className="list-none p-0 m-0">
                      <li className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">
                          Business
                        </div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                          {business.name} - {business.address} -{" "}
                          {business.phone} - {business.email}
                        </div>
                        <div className="w-6 md:w-2 flex justify-content-end">
                          <Button
                            label="Edit"
                            icon="pi pi-pencil"
                            className="p-button-text"
                            onClick={(e) => {
                              setStep(0);
                            }}
                          />
                        </div>
                      </li>{" "}
                      <li className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-6 md:w-2 font-medium">
                          Campaign
                        </div>
                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                          {BPrc.getCampaignType(campaign.mode)} -{" "}
                          {campaign.name}
                          {campaign.costlimit === 0
                            ? ""
                            : " Spending limit: " +
                              Tools.formatCurrency(campaign.costlimit)}
                        </div>
                        <div className="w-6 md:w-2 flex justify-content-end">
                          <Button
                            label="Edit"
                            icon="pi pi-pencil"
                            className="p-button-text"
                            onClick={(e) => {
                              setStep(1);
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="field col-12 md:col-4 bg-blue-50 border-round border-2 border-dashed border-green-200">
                    <div className="text-500 my-2 flex justify-content-center align-items-center font-semibold">
                      Campaign's prize list
                      <Button
                        icon="pi pi-pencil"
                        className="p-button-text"
                        onClick={(e) => {
                          setStep(2);
                        }}
                      />
                    </div>

                    <ul className="list-none p-0 m-0">
                      {prizes.map((p, index) => (
                        <li
                          className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap"
                          key={index}
                        >
                          <div className="text-500 w-6 md:w-2 font-medium">
                            {p.odds}
                            {campaign.mode !== 1 ? "%" : ""}
                          </div>
                          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            {p.name}
                            {p.cost === 0 ? "" : " for $" + p.cost}
                          </div>
                          <div className="w-6 md:w-2 flex justify-content-end"></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </TabPanel>
            </TabView>

            <div className="grid formgrid p-fluid mt-5">
              <div className="col-6 ">
                {step !== 0 && (
                  <Button
                    label="Back"
                    className="w-auto p-button-warning"
                    icon="pi pi-chevron-circle-left"
                    onClick={(e) => goToStep(step - 1)}
                  />
                )}
              </div>
              <div className="col-6 flex justify-content-end">
                {step !== 3 && (
                  <Button
                    label="Next"
                    className="w-auto p-button-info"
                    icon="pi pi-chevron-circle-right"
                    iconPos="right"
                    onClick={(e) => goToStep(step + 1)}
                  />
                )}
                {step === 3 && (
                  <Button
                    label="Save"
                    className="w-auto p-button-success"
                    icon="pi pi-check-circle"
                    iconPos="right"
                    onClick={(e) => {
                      BPrc.registerOrUpdateBusiness(
                        business,
                        (message) => {
                          setError(message);
                        },
                        (data, tk) => {
                          setParty(data);
                          BPrc.addOrUpdateCampaign(
                            campaign,
                            prizes,
                            token == null ? tk : token,
                            (message) => {
                              setError(message);
                            },
                            (data) => {
                              Tools.processEmail();
                              setAddNew(false);
                              setHint("Campaign is updated!");
                              getCampaigns();
                              if (profile.role === AppTools.ADMIN)
                                navigate("/");
                              else navigate("/campaigns");
                            }
                          );
                        }
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </Dialog>

          <span className="p-input-icon-left w-full md:w-auto pb-2">
            Archived & Closed
            <InputSwitch
              className="ml-2"
              checked={includeArchived}
              onChange={(e) => setIncludeArchived(e.value)}
            />
          </span>
          <span className="p-input-icon-left w-full md:w-auto">
            <i className="pi pi-search"></i>
            <InputText
              placeholder="Search"
              value={searchTxt}
              className="w-full md:w-auto"
              onChange={(e) => setSearchTxt(e.target.value)}
            />
          </span>
        </div>

        <div className="grid grid-nogutter">
          {campaigns
            .filter(
              (c) =>
                searchTxt.length === 0 ||
                c.campaign.name.indexOf(searchTxt) !== -1
            )
            .map((c, index) => (
              <Campaign
                campaignInfo={c}
                key={index}
                token={token}
                setError={setError}
                setHint={setHint}
                editCampaign={addOrEditCampaign}
                emailQR={emailQR}
                endCampaign={endCampaign}
                runCampaign={runCampaign}
                archiveCampaign={archiveCampaign}
                showCampaignEntries={showCampaignEntries}
                showCampaignWinners={showCampaignWinners}
                CloneCampaign={CloneCampaign}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessCampaigns;
