import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";

const CustomerMemo = ({ processFn }) => {
  const [note, setNote] = useState("");

  return (
    <div className="grid formgrid p-fluid">
      <div className="field col-12">
        <label className="block text-900 font-medium mb-2">Message</label>
        <InputTextarea
          className="w-full"
          value={note}
          rows={5}
          cols={30}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
      <div className="field col-3 col-offset-9">
        <Button
          icon="pi pi-check-circle"
          label="Send"
          className="p-button-info p-button-outlined"
          onClick={(e) => processFn(note)}
        />
      </div>
    </div>
  );
};

export default CustomerMemo;
