import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { AppContext } from "../AppContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authentication from "./Authentication";

const ActivatePage = (props) => {
  const [working, setWorking] = useState(true);
  const [severity, setSeverity] = useState("info");
  let [searchParams] = useSearchParams();
  const [msg, setMsg] = useState(
    "Your account is being activated please wait ..............."
  );
  const { setError } = useContext(AppContext);

  const email = searchParams.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    setWorking(false);
    Authentication.activate(
      email,
      function (message) {
        setError(message);
        setSeverity("error");
        setMsg("Your account can not be activated, please contact admin.");
      },
      function () {
        setSeverity("success");
        setMsg(
          "Your account now is activated, please use the login page to continue."
        );
      }
    );
    setTimeout(() => {
      navigate("/");
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title="Account Activation">
      <Message severity={severity} text={msg} className="p-d-flex" />
      {working && <ProgressSpinner className="p-d-flex" />}
    </Card>
  );
};

export default ActivatePage;
