import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import POSAdminPrc from "./POSAdminPrc";

const OrderTypeSelector = ({ processFuc }) => {
  const { configs } = useContext(AppContext);

  const [hasDinein] = useState(
    configs.find((c) => c.name === "has_dinein").value === "yes"
  );
  const [hasPickup] = useState(
    configs.find((c) => c.name === "has_pickup").value === "yes"
  );
  const [hasDelivery] = useState(
    configs.find((c) => c.name === "has_delivery").value === "yes"
  );
  useEffect(() => {
    if (!hasDinein && !hasPickup && !hasDelivery) {
      processFuc(POSAdminPrc.WALK_IN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid m-5 w-9">
        <div className="field col-12 md:col-3">
          <Button
            label="Walk In"
            className="text-3xl flex p-button-outlined m-2 p-button-help h-6rem"
            onClick={(e) => processFuc(POSAdminPrc.WALK_IN)}
          />
        </div>
        {hasDelivery && (
          <div className="field col-12 md:col-3">
            <Button
              label="Delivery"
              className="text-3xl flex p-button-outlined m-2 p-button-danger h-6rem"
              onClick={(e) => processFuc(POSAdminPrc.DELIVER)}
            />
          </div>
        )}
        {hasPickup && (
          <div className="field col-12 md:col-3">
            <Button
              label="Pick up"
              className="text-3xl flex p-button-outlined m-2 p-button-warning h-6rem"
              onClick={(e) => processFuc(POSAdminPrc.PICK_UP)}
            />
          </div>
        )}
        {hasDinein && (
          <div className="field col-12 md:col-3">
            <Button
              label="Dine In"
              className="text-3xl flex p-button-outlined m-2 p-button-info h-6rem"
              onClick={(e) => processFuc(POSAdminPrc.DINE_IN)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderTypeSelector;
