import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import POSAdminPrc from "./POSAdminPrc";
import Tools from "../../common/Tools";

const KitchenPage = () => {
  const { token, setError } = useContext(AppContext);
  const [orderList, setOrderList] = useState([]);
  const [now, setNow] = useState(null);

  const getOrders = () => {
    POSAdminPrc.loadOrders(
      0,
      token,
      (message) => setError(message),
      (orders) => {
        //let os = orders.map((o) => o.json.replace("\\", ""));
        setOrderList(
          orders.map((o) => {
            return { ...o, json: JSON.parse(o.json) };
          })
        );
      }
    );
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [now]);

  useEffect(() => {
    getOrders();
    const timer1 = setInterval(function () {
      setNow(new Date());
    }, 1000);
    const timer2 = setInterval(function () {
      getOrders();
    }, 10 * 1000);
    return () => {
      clearInterval(timer1);
      clearInterval(timer2);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const needsKitchenAttention = (o) => {
    for (let i = 0; i !== o.json.orderItems.length; i++)
      if (
        o.json.orderItems[i].seq !== -1 &&
        o.json.orderItems[i].count > o.json.orderItems[i].served
      )
        return true;

    return false;
  };

  const orderDetail = (order) => {
    if (order.order_type === POSAdminPrc.DINE_IN) {
      return "T: " + order.json.table.name;
    } else if (order.order_type === POSAdminPrc.WALK_IN) {
      return "#" + order.order_number;
    } else {
      return `#" + ${order.order_number}, ${order.json.customer.phone} `;
    }
  };

  const timeLapsed = (o) => {
    let secs = (new Date().getTime() - Date.parse(o.order_time)) / 1000;
    return (
      <span
        className={
          secs < 60
            ? "text-600"
            : secs < 120
            ? "text-yellow-500"
            : "text-pink-500"
        }
      >
        {Tools.secondsToDhms(secs)}
      </span>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="grid grid-nogutter">
          {orderList
            .filter((o) => needsKitchenAttention(o))
            .map((o, index) => (
              <div key={index} className="col-12 md:col-6 p-3">
                <div className="surface-card shadow-2 border-rounded p-3">
                  <div className="flex border-bottom-1 surface-border pb-3">
                    <div className="flex flex-column justify-content-between">
                      <span className="text-lg text-900 font-medium">
                        {POSAdminPrc.getType(o.json)} {orderDetail(o)}
                      </span>
                      <span className="text-sm text-600 font-medium">
                        Time lapsed: {timeLapsed(o)}
                      </span>
                      <span className="text-sm text-400 font-medium">
                        Server: {o.json.server.name}
                      </span>
                    </div>
                    <Button
                      label="Served"
                      icon="pi pi-check-square"
                      className="p-button-outlined p-button-success w-6 ml-5 p-button-lg"
                      onClick={(e) => {
                        o.json.made = true;
                        o.json.orderItems.forEach((oi) => {
                          oi.served = oi.count;
                        });
                        POSAdminPrc.saveOrder(
                          { ...o.json, id: o.id },
                          token,
                          (message) => setError(message),
                          (order) => {
                            getOrders();
                          }
                        );
                      }}
                    />
                  </div>
                  <ul className="list-disc">
                    {o.json.orderItems
                      .filter(
                        (oi) =>
                          oi.menu != null &&
                          oi.seq !== -1 &&
                          oi.count > oi.served
                      )
                      .map((oi, index2) => (
                        <li
                          key={index2}
                          className="mb-3 border-dashed border-bottom-1 border-x-none border-top-none pb-2"
                        >
                          <span className="text-900 mr-3">{oi.menu.name}</span>
                          <Chip
                            key="COUNT"
                            label={oi.count - oi.served}
                            className="mr-2 mb-1 text-800 bg-green-200"
                          />
                          {oi.options.map((op, index3) => (
                            <Chip
                              key={index3}
                              label={POSAdminPrc.getOptionLabel(op)}
                              className="mr-2 mb-1"
                            />
                          ))}
                          <Chip
                            key="NOTE"
                            label={oi.note}
                            className="mr-2 mb-1 text-800 bg-blue-200"
                          />
                          <Button
                            label="Served"
                            icon="pi pi-check-square"
                            className="p-button-outlined p-button-info ml-5"
                            onClick={(e) => {
                              oi.served++;
                              let needsDisplay = false;
                              o.json.orderItems.forEach((oi) => {
                                if (oi.served < oi.count) needsDisplay = true;
                              });
                              if (!needsDisplay) o.json.made = true;
                              POSAdminPrc.saveOrder(
                                { ...o.json, id: o.id },
                                token,
                                (message) => setError(message),
                                (order) => {
                                  getOrders();
                                }
                              );
                            }}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default KitchenPage;
