import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import BPrc from "../business/BusinessPrc";
import Tools from "../../common/Tools";
import WScanner from "../../common/Utils/WScanner";
import AppTools from "../AppTools";

const ResultPage = () => {
  const { setError, setHint, party, profile, token } = useContext(AppContext);
  const scannerRef = useRef(null);

  const [iType, setIType] = useState("Phone");
  const options = ["Phone", "Email"];
  const [cameraDisable, setCameraDisable] = useState(true);
  const [entry1, setEntry1] = useState("");
  const [entry2, setEntry2] = useState("");
  const navigate = useNavigate();

  const [wins, setWins] = useState([]);
  const [stamps, setStamps] = useState([]);
  const [customer, setCustomer] = useState(
    party == null ? null : profile.role === AppTools.CONSUMER ? party : null
  );

  useEffect(() => {
    if (customer !== null) {
      checkResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkResult = () => {
    BPrc.getResult(
      party == null || profile.role === AppTools.BUSINESS ? null : party.qr,
      party == null
        ? null
        : profile.role === AppTools.BUSINESS
        ? party.qr
        : null,
      entry1.length === 0 ? null : entry1,
      entry2.length === 0 ? null : entry2,
      (msg) => {
        setError(msg);
        setCustomer(null);
        setWins([]);
      },
      (aCustomer, aBusiness, winningResult, stampResult) => {
        if (aCustomer != null) {
          setCustomer(aCustomer);
        }
        setStamps(
          stampResult.map((i) => {
            return {
              id: i.id,
              customerQR: i.party.qr,
              business: i.campaign.party.firstname,
              customer: aCustomer.firstname + " " + aCustomer.lastname,
              campaign: i.campaign.name,
              counter: i.counter,
              items: i.campaign.items,
              campaignQR: i.campaign.qr,
            };
          })
        );
        setWins(
          winningResult.map((i) => {
            let dt = new Date(i.created_at);
            let dt2 = new Date(i.redeemed_at);

            return {
              id: i.id,
              prizeQR: i.qr,
              customerQR: i.party.qr,
              business: i.campaign.party.firstname,
              customer: aCustomer.firstname + " " + aCustomer.lastname,
              campaign: i.campaign.name,
              prize: i.campaign_item.prize,
              status: i.status,
              redeemed_at: Tools.formatDate(dt2),
              when: Tools.formatDate(dt),
            };
          })
        );
      }
    );
  };

  const whenTemp = (rowData) => {
    return <span className="ztext-xs">{rowData.when}</span>;
  };

  const actionTemp = (rowData) => {
    if (party === null || profile.role === AppTools.CONSUMER) {
      return (
        <Button
          label="Send"
          className="p-button-sm"
          onClick={(e) => {
            BPrc.sendPrize(
              (party !== null && profile.role === AppTools.CONSUMER) ||
                iType === "Email"
                ? "Email"
                : "SMS",
              rowData.customerQR,
              rowData.prizeQR,
              (msg) => setError(msg),
              () => {
                Tools.processEmail();
                setHint(
                  "Prize information is sent to your " +
                    ((party !== null && profile.role === AppTools.CONSUMER) ||
                    iType === "Email"
                      ? "email"
                      : "phone") +
                    ". It might take up to five minutes to get this."
                );
              }
            );
          }}
        />
      );
    } else {
      if (rowData.status === 0)
        return (
          <Button
            icon="fas fa-trophy"
            className="p-button-sm"
            onClick={(e) => {
              redeemP(rowData.prizeQR, rowData.id);
            }}
          />
        );
      else {
        return rowData.redeemed_at;
      }
    }
  };

  const actionTemp2 = (rowData) => {
    let canRedeem = false;
    let options = [];
    rowData.items.forEach((i) => {
      if (i.odds < rowData.counter) {
        canRedeem = true;
        options.push({
          campaign: rowData.campaignQR,
          customer: rowData.customerQR,
          item: i.id,
          label: i.prize,
        });
      }
    });

    if (canRedeem)
      return options.map((o) => (
        <Button
          label={o.label}
          className="p-button-sm mx-1 p-button-success p-button-outlined"
          onClick={(e) => {
            redeemS(o.campaign, o.item, o.customer);
          }}
        />
      ));
    else return "";
  };

  const redeemS = (campaign, item, customer) => {
    BPrc.claimSPrize(
      campaign,
      item,
      customer,
      token,
      (msg) => setError(msg),
      (prize) => {
        checkResult();
        setHint(prize.prize + " was redeemed.");
      }
    );
  };

  const redeemP = (pqr, id = -1) => {
    BPrc.claimPrize(
      pqr,
      token,
      (msg) => setError(msg),
      () => {
        if (id !== -1) {
          let newW = [...wins];
          newW.forEach((w) => {
            if (w.id === id) {
              let dt = new Date();
              w.status = 1;
              w.redeemed_at = Tools.formatDate(dt);
            }
          });
          setWins(newW);
        }
        setHint("Prize is redeemed.");
      }
    );
  };

  const actionHeaderTemp = (rowData) => {
    if (party !== null && profile.role === AppTools.BUSINESS) return "Redeem";
    if (
      (party !== null && profile.role === AppTools.CONSUMER) ||
      iType === "Email"
    )
      return <i className="pi pi-envelope"></i>;
    return <i className="pi pi-phone"></i>;
  };
  const actionHeaderTemp2 = (rowData) => {
    if (party !== null && profile.role === AppTools.BUSINESS) return "Redeem";
    return "";
  };

  const partyTemp = (rowData) => {
    if (party === null || profile.role === AppTools.CONSUMER)
      return rowData.business;
    return rowData.customer;
  };
  const partyHeaderTemp = (rowData) => {
    if (party === null || profile.role === AppTools.CONSUMER) return "Business";
    return "Customer";
  };

  return (
    <div className="grid surface-section md:p-6">
      <div className="col-12 flex justify-content-center mt-2">
        <WScanner
          ref={scannerRef}
          header="Prize QR Code"
          readyFn={() => {
            setCameraDisable(false);
          }}
          abortFn={() => {}}
          errorFn={() => {}}
          scanFn={(data) => {
            scannerRef.current.stop();
            navigate("/showprize?qr=" + data);
          }}
        />
        <span className="flex text-900 font-bold md:text-2xl mb-3">
          {`Check Result ${
            party != null && profile.role === AppTools.BUSINESS
              ? "& Redeem prizes"
              : ""
          }`}
        </span>
      </div>
      <div className="col-12">
        {party == null && (
          <span>
            If you have a profile please login to check all your campaign
            results. You can also use the email address or phone number you used
            to enter a campaign to lookup your results. Please use the{" "}
            <span className="font-semibold font-italic text-indigo-300">
              Send
            </span>{" "}
            to receive the prize QR code and details via SMS or email. You need
            this QR code to redeem your prize.
          </span>
        )}
        {party != null && profile.role === AppTools.CONSUMER && (
          <span>
            If you have a profile please login to check all your campaign
            results. You can also use the email address or phone number you used
            to enter a campaign to lookup your results. Please use the{" "}
            <span className="font-semibold font-italic text-indigo-300">
              Send
            </span>{" "}
            to receive the prize QR code and details via SMS or email. You need
            this QR code to redeem your prize.
          </span>
        )}
        {party != null && profile.role === AppTools.BUSINESS && (
          <span>
            Lookup customer's using their phone number or email address used
            during entering the campaign to redeem the winning prizes. Use the
            camera button to scan a prize QR code.
          </span>
        )}
      </div>
      {(party === null || profile.role === AppTools.BUSINESS) && (
        <Fragment>
          <div className="flex md:flex-nowrap flex-wrap col-12 align-content-center">
            {party != null && profile.role === AppTools.BUSINESS && (
              <Button
                disabled={cameraDisable}
                icon="pi pi-camera"
                className="block font-bold white-space-nowrap p-button-outlined mx-2 mt-2"
                onClick={(e) => scannerRef.current.start()}
              />
            )}
            <SelectButton
              className="mx-2 mt-2"
              value={iType}
              options={options}
              onChange={(e) => {
                setWins([]);
                setEntry1("");
                setEntry2("");
                setCustomer(null);
                setIType(e.value);
              }}
            />
            {iType === "Phone" && (
              <InputText
                keyfilter="pnum"
                className="w-9 md:w-3 mx-2 mt-2"
                value={entry1}
                onChange={(e) => {
                  setEntry1(e.target.value);
                }}
                onBlur={(e) => {
                  setEntry1(entry1.replace(/\D+/g, ""));
                }}
              />
            )}
            {iType === "Email" && (
              <InputText
                className="w-9 md:w-3 mx-2 mt-2"
                type="text"
                keyfilter="email"
                value={entry2}
                onChange={(e) => {
                  setEntry2(e.target.value);
                }}
              />
            )}
            <Button
              icon="pi pi-search"
              className="block font-bold white-space-nowrap p-button-outlined mx-2 mt-2"
              onClick={(e) => checkResult()}
            />
          </div>
          {customer !== null && (
            <div className="col-12 flex justify-content-center p-5">
              <div className="flex">
                <span className="text-orange-700 text-2xl">
                  {customer.firstname} {customer.lastname}
                </span>
              </div>
            </div>
          )}
        </Fragment>
      )}
      {wins.length !== 0 && (
        <div className="col-12">
          <DataTable value={wins} responsiveLayout="stack">
            <Column field="prize" header="Prize"></Column>
            <Column field="campaign" header="Campaign"></Column>
            <Column
              body={partyTemp}
              header={partyHeaderTemp}
              hidden={party != null && profile.role === AppTools.BUSINESS}
            ></Column>
            <Column body={whenTemp} header="Time"></Column>
            <Column body={actionTemp} header={actionHeaderTemp}></Column>
          </DataTable>
        </div>
      )}
      {stamps.length !== 0 && (
        <Fragment>
          <div className="col-12 flex justify-content-center pt-5">
            <span className="text-bluegray-500 text-xl">Stamps</span>
          </div>
          <div className="col-12">
            <DataTable value={stamps} responsiveLayout="stack">
              <Column field="counter" header="Stamps"></Column>
              <Column field="campaign" header="Campaign"></Column>
              <Column
                field="business"
                header="Business"
                hidden={party != null && profile.role === AppTools.BUSINESS}
              ></Column>
              <Column
                body={actionTemp2}
                header={actionHeaderTemp2}
                hidden={party == null || profile.role === AppTools.CONSUMER}
              ></Column>
            </DataTable>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ResultPage;
