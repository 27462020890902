import React, { useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { AppContext } from "../../common/AppContext";
import { useNavigate } from "react-router-dom";
import Authentication from "./Authentication";

const LogoutPage = () => {
  const { setError, token, setToken, setProfile, setParty } =
    useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      Authentication.logout(
        token,
        function (message) {
          setError(message);
        },
        function () {
          setToken(null);
          setProfile(null);
          setParty(null);
          navigate("/");
        }
      );
    }, 500);
  });

  return (
    <Card title="System Logout">
      <Message
        severity="info"
        text="Please wait while system is logging out ........"
        className="p-d-flex"
      />
      <ProgressSpinner className="p-d-flex" />
    </Card>
  );
};

export default LogoutPage;
