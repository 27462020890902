import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { Button } from "primereact/button";
import LoadingPage from "../Utils/LoadingPage";
import { Dialog } from "primereact/dialog";
import CRUDForm from "./CRUDForm";
import { schemas, schemaData } from "./Schema";
import Tools from "../Tools";
import SelectEntity from "./SelectEntity";
import AdminPrc from "../AdminPrc";
import ManagedDialog from "../Utils/ManagedDialog";

const EntryForm = ({
  showTitle = true,
  inEntity,
  inCondition = null,
  methods = null,
  canAdd = true,
}) => {
  const { token, setError } = useContext(AppContext);
  const [data, setData] = useState(null);
  const [obj, setObj] = useState(null);
  const [addEdit, setAddEdit] = useState(false);
  const [showChild, setShowChild] = useState(false);
  const schema = schemas[inEntity].fields;
  const [condition, setCondition] = useState(null);

  const [eMenu, setEMenu] = useState([]);
  const [showEAction, setShowEAction] = useState(false);
  const processEAction = (act) => {
    setShowEAction(false);
    act.command();
  };

  const setupAndShowEMenu = (row) => {
    let menu = [];

    setObj({ ...row });

    menu.push({
      name: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setAddEdit(true);
      },
    });

    if (schemas[inEntity].showChildren) {
      menu.push({
        name: "Children ...",
        icon: "pi pi-th-large",
        command: () => {
          let row = schemaData[1];
          setCondition({ field: "parent", value: row.id });
          setShowChild(true);
        },
      });
    }

    if (typeof schemas[inEntity].actions !== "undefined") {
      schemas[inEntity].actions.forEach((a) => {
        if (typeof a.visible === "boolean") {
          if (a.visible) {
            menu.push(a);
          }
        } else if (typeof a.visible === "function") {
          if (a.visible(row)) {
            menu.push(a);
          }
        }
      });
    }

    setEMenu(menu);

    schemaData.length = 0;
    schemaData.push(data);
    schemaData.push(row);
    schemaData.push(callMethods);

    if (menu.length === 1) {
      menu[0].command();
    } else {
      setShowEAction(true);
    }
  };

  // const deleteRecord = () => {
  //   let r = schemaData[1];
  //   confirmDialog({
  //     message: `Are you sure you want to ${
  //       r.deleted ? "undelete" : "delete"
  //     } this record?`,
  //     className: "text-3xl",
  //     icon: "pi pi-info-circle text-3xl",
  //     acceptClassName: "text-2xl p-button-danger",
  //     rejectClassName: "text-2xl p-button-info",
  //     accept: () => {
  //       AdminPrc.addOrUpdateEntity(
  //         inEntity,
  //         { ...r, deleted: !r.deleted },
  //         token,
  //         (message) => setError(message),
  //         () => {
  //           let oldData = schemaData[0];
  //           let newData = oldData.data.map((row) =>
  //             row.id === r.id ? { ...row, deleted: !r.deleted } : row
  //           );
  //           setData({ ...oldData, data: newData });
  //           setHint("Your record is marked deleted.");
  //         }
  //       );
  //     },
  //   });
  // };

  useEffect(() => {
    AdminPrc.getAll(
      (typeof schemas[inEntity].package === "undefined"
        ? ""
        : schemas[inEntity].package + "$") + inEntity,
      inCondition == null
        ? inEntity + "P0"
        : inEntity + "P" + (inCondition.value === null ? 0 : inCondition.value),
      inCondition,
      token,
      null,
      (message) => setError(message),
      (indata) => {
        setData(indata);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewData = (url) => {
    AdminPrc.getAll(
      (typeof schemas[inEntity].package === "undefined"
        ? ""
        : schemas[inEntity].package + "$") + inEntity,
      inCondition == null
        ? inEntity + "P0"
        : inEntity + "P" + (inCondition.value === null ? 0 : inCondition.value),
      inCondition,
      token,
      url,
      (message) => setError(message),
      (indata) => setData(indata)
    );
  };
  const getTableRow = (row) => {
    return schema.map((s, index) =>
      s.grid ? (
        <div className={"text-800 w-1 font-medium " + s.width} key={index}>
          {s.type === "TOG" ? (row[s.field] ? "Yes" : "No") : row[s.field]}
        </div>
      ) : (
        ""
      )
    );
  };

  const callMethods = (method, someObject) => {
    if (methods == null) return;
    let it = methods.find((m) => m.name === method);
    if (typeof it !== "undefined") {
      it.func(someObject);
    } else {
    }
  };

  if (data == null) return <LoadingPage />;
  return (
    <div className="flex flex-grow-1 justify-content-center w-full">
      <ManagedDialog
        closeFn={setShowEAction}
        visible={showEAction}
        maximized={false}
        header="Select an action"
      >
        <SelectEntity objectSelection={eMenu} processFn={processEAction} />
      </ManagedDialog>

      <Dialog
        dlgLabel="Display"
        position="top"
        visible={addEdit}
        closable={false}
        modal
        style={{ width: "90vw" }}
        header={obj == null ? "Add new " + inEntity : "Edit/Update"}
        icons={Tools.dialogCloseIcon(setAddEdit)}
        // position={getNextDPos()}
      >
        <CRUDForm
          step={0}
          schema={schema}
          eCls={
            (typeof schemas[inEntity].package === "undefined"
              ? ""
              : schemas[inEntity].package + "$") + inEntity
          }
          eId={obj == null ? 0 : obj.id}
          eObj={obj}
          eName={inEntity}
          hidFields={inCondition == null ? {} : { parent: inCondition.value }}
          // setObj={setObj}
          token={token}
          // setError={setError}
          saveFunction={(o) => {
            setAddEdit(false);
            let newData;
            if (obj !== null)
              newData = data.data.map((row) => (row.id === o.id ? o : row));
            else newData = [...data.data, o];
            setData({ ...data, data: newData });
          }}
        />
      </Dialog>
      <Dialog
        dlgLabel="Display"
        position="top"
        visible={showChild}
        //maximized={true}
        closable={false}
        style={{ width: "90vw" }}
        modal
        icons={Tools.dialogCloseIcon(setShowChild)}
        header={obj == null ? "" : "CRUD Operator - Parent: " + obj.name}
        // position={getNextDPos()}
      >
        <EntryForm
          inEntity={inEntity}
          inCondition={condition}
          methods={methods}
        />
      </Dialog>
      <div className="grid formgrid p-fluid w-11">
        {showTitle && (
          <div className="field col-12 flex justify-content-center text-2xl text-600 font-bold">
            {inEntity}
          </div>
        )}
        <div className="field col-12">
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              {schema.map((s, index) =>
                s.grid ? (
                  <div
                    className={"text-800 w-1 font-medium " + s.width}
                    key={index}
                  >
                    {s.label}
                  </div>
                ) : (
                  ""
                )
              )}
            </li>
            {data.data.map((row, index) => (
              <li
                key={index}
                className={
                  "flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap " +
                  (row.deleted ? "bg-pink-300" : "")
                }
              >
                {!schemas[inEntity].readonly && (
                  <div className="flex justify-content-start">
                    <Button
                      icon="pi pi-ellipsis-h"
                      iconPos="left"
                      className="p-button-text"
                      onClick={(e) => {
                        setupAndShowEMenu(row);
                      }}
                    />
                  </div>
                )}
                {getTableRow(row)}
              </li>
            ))}
          </ul>
        </div>

        <div className="field col-4">
          {data.prev_page_url != null && (
            <Button
              label="Previous Page"
              icon="far fa-hand-point-left"
              iconPos="top"
              className="p-button-outlined p-button-primary"
              onClick={(e) => {
                getNewData(data.prev_page_url);
              }}
            />
          )}
        </div>
        {canAdd && (
          <div className="field col-4">
            <Button
              label="Add New"
              icon="far fa-plus-square"
              iconPos="top"
              onClick={(e) => {
                setObj(null);
                setAddEdit(true);
              }}
              className="p-button-outlined p-button-success"
            />
          </div>
        )}
        <div className="field col-4">
          {data.next_page_url != null && (
            <Button
              label="Next Page"
              icon="far fa-hand-point-right"
              iconPos="top"
              className="p-button-outlined p-button-primary"
              onClick={(e) => {
                getNewData(data.next_page_url);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EntryForm;
