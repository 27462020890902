import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { InputNumber } from "primereact/inputnumber";

const OrderItemMemo = ({ oi, processFuc }) => {
  const [note, setNote] = useState(oi.note == null ? "" : oi.note);
  const [cost, setCost] = useState(oi.extra_cost);

  return (
    <div className="grid formgrid p-fluid">
      <div className="field col-12">
        <label className="block text-900 font-medium mb-2">Note</label>
        <InputText
          className="w-full"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
      <div className="field col-12">
        <label className="block text-900 font-medium mb-2">
          Cost Adjustment
        </label>
        <InputNumber
          className="w-full"
          mode="currency"
          currency="CAD"
          value={cost}
          onChange={(e) => {
            setCost(e.value);
          }}
        />
      </div>
      <div className="field col-3 col-offset-9">
        <Button
          icon="pi pi-check-circle"
          label="Update"
          className="p-button-info p-button-outlined"
          onClick={(e) => processFuc(note, cost)}
        />
      </div>
    </div>
  );
};

export default OrderItemMemo;
