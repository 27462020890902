import React, { useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { InputText } from "primereact/inputtext";

const GetText = ({ init = null, processFn, mode = 0 }) => {
  const [text, setText] = useState(
    init !== null ? init : mode !== 1 ? "" : "0"
  );
  const numbers =
    mode === 2
      ? ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 {ent}"]
      : mode === 1
      ? ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 {ent}", " . "]
      : ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 {abc}"];

  const [layoutName, setLayoutName] = useState("default");
  useEffect(() => {
    if (mode !== 0) {
      kbHandleNumbers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kbOnChange = (input) => {
    setText(input);
  };

  const kbOnKeyPress = (button) => {
    if (button === "{ent}") {
      processFn(text);
      setText(init);
    }
    if (button === "{shift}" || button === "{lock}") kbHandleShift();
    if (button === "{numbers}" || button === "{abc}") kbHandleNumbers();
  };

  const kbHandleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const kbHandleNumbers = () => {
    setLayoutName(layoutName === "default" ? "numbers" : "default");
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setText(input);
  };

  return (
    <div className="flex justify-content-center" style={{ maxWidth: "500px" }}>
      <div className="grid formgrid p-fluid w-12">
        <div className=" col-12">
          <InputText
            id="txt"
            readOnly
            className="w-full mb-3"
            value={text}
            onChange={(e) => onChangeInput(e)}
          />
        </div>
        {/* <div className=" col-12">
          <Button
            label="Enter"
            icon="pi pi-check-square"
            onClick={(e) => {
              processFn(text);
              setText(init);
            }}
            className="p-button-success"
          />
        </div> */}
        <div className=" col-12">
          <Keyboard
            //input="txt"
            preventMouseDownDefault={true}
            onChange={kbOnChange}
            onKeyPress={kbOnKeyPress}
            inputName="txt"
            mergeDisplay={true}
            layoutName={layoutName}
            layout={{
              default: [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "{shift} z x c v b n m {backspace}",
                "@ .com .ca @gmai.com @yahoo.com",
                "{numbers} {space} . {ent}",
              ],
              shift: [
                "Q W E R T Y U I O P",
                "A S D F G H J K L",
                "{shift} Z X C V B N M {backspace}",
                ".com .ca @gmai.com @yahoo.com",
                "{numbers} {space} {ent}",
              ],
              numbers: numbers,
            }}
            display={{
              "{numbers}": "123",
              "{ent}": "↵",
              "{escape}": "esc ⎋",
              "{tab}": "tab ⇥",
              "{backspace}": "⌫",
              "{capslock}": "caps lock ⇪",
              "{shift}": "⇧",
              "{controlleft}": "ctrl ⌃",
              "{controlright}": "ctrl ⌃",
              "{altleft}": "alt ⌥",
              "{altright}": "alt ⌥",
              "{metaleft}": "cmd ⌘",
              "{metaright}": "cmd ⌘",
              "{abc}": "ABC",
            }}
            buttonTheme={[
              {
                className: "bg-blue-200 amir",
                buttons: "@",
              },
              {
                className: "bg-green-100",
                buttons: "{ent}",
              },
              {
                className: "bg-indigo-100",
                buttons: "{abc} {numbers}",
              },
              {
                className: "bg-pink-100",
                buttons: "{backspace}",
              },
              {
                className: "bg-bluegray-100",
                buttons: "{space}",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default GetText;
