import { Avatar } from "primereact/avatar";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Tools from "../../common/Tools";
import PictureComp from "../../common/Utils/PictureComp";
import BPrc from "./BusinessPrc";
import { SocialIcon } from "react-social-icons";

function BusinessPage() {
  let [searchParams] = useSearchParams();
  const { state } = useLocation();
  const qr = searchParams.get("qr");

  const options =
    state.business.options === null || state.business.length === 0
      ? []
      : state.business.options.split(",");
  useEffect(() => {
    // The location of Uluru
    const uluru = {
      lat: Number(state.business.lat),
      lng: Number(state.business.lng),
    };
    // The map, centered at Uluru
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 14,
      center: uluru,
    });
    // The marker, positioned at Uluru
    const marker = new window.google.maps.Marker({
      position: uluru,
      map: map,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid grid-nogutter surface-section text-800 mt-2">
      <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
          <Avatar
            image={Tools.getImagePath(state.business.profile_id, "profile")}
            className="mr-2"
            size="xlarge"
            onImageError={(e) => {
              e.target.src = Tools.getNoImage();
            }}
            shape="circle"
          />
          <span className="block text-6xl font-bold mb-1">
            {state.business.firstname}
          </span>
          <div className="text-xl text-primary font-bold mb-3">
            Address:{" "}
            <a
              href={"http://maps.google.com/?q=" + state.business.address}
              target="_blank"
              rel="noreferrer"
            >
              {state.business.address}
            </a>
          </div>
          <p className="mt-0 mb-4 text-700 line-height-3">
            Phone:{" "}
            <a
              href={"tel:" + state.business.phone}
              target="_blank"
              rel="noreferrer"
            >
              {Tools.formatPhoneNumber(state.business.phone)}
            </a>
          </p>
          {options.map((o, index) =>
            o.length > 5 ? (
              <SocialIcon key={index} url={o} className="m-2" />
            ) : (
              ""
            )
          )}
        </section>
        <div className="mt-0 mb-4 text-700 line-height-3"></div>
      </div>
      <div className="col-12 md:col-6 overflow-hidden  border-round border-4 border-dotted border-pink-200 p-2">
        <div id="map" style={{ height: "100%", width: "100%" }}></div>
      </div>
      <div className="col-12 px-6 overflow-hidden text-3xl pb-2">
        Flyer/Brochure/Business Card
      </div>
      <div className="flex justify-content-center flex-wrap col-12 px-6 overflow-hidden text-3xl pb-2">
        <div class="flex align-items-center justify-content-center w-full">
          <PictureComp
            id={state.business.profile_id}
            avatar={false}
            inWidth="100%"
            tag="flyer"
          />
        </div>
      </div>
      <div className="col-12 px-6 overflow-hidden text-3xl pb-2">Campaigns</div>
      {state.business.campaigns.map((campaign, index) => (
        <div
          key={index}
          className="col-12 md:col-6 px-6 text-center md:text-left flex align-items-center text-xl "
        >
          <ul className="list-none p-5 m-0 border-round border-2 border-dashed border-green-200 w-12">
            {BPrc.getCampaignType(campaign.mode)} - {campaign.name}
            {campaign.items.map((p, index) => (
              <li
                className="flex align-items-center py-1 px-2 border-top-1 surface-border flex-wrap"
                key={index}
              >
                <div className="text-500 w-6 md:w-2 font-medium">
                  {p.odds}
                  {campaign.mode !== 1 ? "%" : ""}
                </div>
                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                  {p.prize}
                </div>
                <div className="w-6 md:w-2 flex justify-content-end"></div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default BusinessPage;
