import React, { useEffect } from "react";
import StripePaymentPage from "../components/common/StripePaymentPage";

function TsPage() {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StripePaymentPage
      amount={125}
      campaign={2}
      processFn={(pf) => console.log(pf)}
    />
  );
}

export default TsPage;
