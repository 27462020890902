import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Tools from "../../common/Tools";
import { AppContext } from "../../common/AppContext";
import ModTree from "./ModTree";
import { AdminContext } from "./POSAdminContextProvider";
import MenuTree from "./MenuTree";
import Layout from "./Layout";
import OrderProcess from "./OrderProcess";
import GetUser from "./GetUser";
import KitchenPage from "./KitchenPage";
import LoadingPage from "../../common/Utils/LoadingPage";
import useWebSocket from "react-use-websocket";
import ExceptionPage from "./ExceptionPage";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Authentication from "../../common/Auth/Authentication";
import AdminPrc from "../../common/AdminPrc";
import POSAdminPrc from "./POSAdminPrc";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import EntryForm from "../../common/CRUD/EntryForm";

const POSAdmin = (props) => {
  const navigate = useNavigate();
  const { token, setError, setHint, configs, setToken, setProfile, profile } =
    useContext(AppContext);
  const {
    setModifiers,
    setModTree,
    setMenus,
    deals,
    setDeals,
    setMenuTree,
    user,
    setUser,
    setDiscount,
  } = useContext(AdminContext);

  let [searchParams] = useSearchParams();

  const [socketUrl, setSocketUrl] = useState(null);
  const { lastMessage } = useWebSocket(socketUrl, {
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const loadTb = searchParams.get("table");
  // const loadTb = props.location.search.substr(7);

  const [oIDToLoad, setOIDToLoad] = useState(null);
  const [busy, setBusy] = useState(null);

  const [showPage, setShowPage] = useState(false);
  const [title, setTitle] = useState("");

  const [messages, setMessages] = useState([]);

  const [showLayout, setShowLayout] = useState(false);
  const [showOrderScr, setShowOrderScr] = useState(false);
  const [showKitchenScr, setShowKitchenScr] = useState(false);

  const [entity, setEntity] = useState(null);
  const [condition, setCondition] = useState(null);
  const [methods, setMethods] = useState(null);

  const [menu, setMenu] = useState(null);
  const [showMenuModifier, setShowMenuModifier] = useState(false);

  const [deal, setDeal] = useState(null);
  const [showDealMenu, setShowDealMenu] = useState(false);

  const [showGetUser, setShowGetUser] = useState(false);

  const [showException, setShowException] = useState(false);
  const processSetMenuException = (data) => {
    //setMenu({ ...menu, exception: data });
    setShowException(false);
    AdminPrc.addOrUpdateEntity(
      "CRUD$Menu",
      { ...menu, exception: data },
      token,
      (message) => setError(message),
      () => {
        setHint(menu.name + "'s exception table is updated.");
      }
    );
  };

  const manageMenuModifier = (mn) => {
    setMenu(mn);
    setShowMenuModifier(true);
  };

  const manageMenuExceptions = (mn) => {
    setMenu(mn);
    setShowException(true);
  };

  const manageDealMenu = (dl) => {
    setDeal(dl);
    setShowDealMenu(true);
  };

  const getMenuData = (m) => {
    let mu = { key: m.id, data: { name: m.name, menu: m } };
    if (typeof m.children === "undefined") return mu;
    mu.children = [];
    m.children.forEach((men) => {
      mu.children.push(getModData(men));
    });
    mu.children.sort((a, b) =>
      a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
    );
    return mu;
  };

  const getModData = (m) => {
    let md = { key: m.id, data: { name: m.name, mod: m } };
    if (typeof m.children === "undefined") return md;
    md.children = [];
    m.children.forEach((mod) => {
      md.children.push(getModData(mod));
    });
    md.children.sort((a, b) =>
      a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
    );
    return md;
  };

  const setTimeInTitle = () => {
    let now = new Date();
    setTitle(
      now.toDateString() +
        " / " +
        now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    if (Tools.getCookie("STATION").length === 0) {
      let randID = Math.floor(Math.random() * 1000) + 1234;
      Tools.setCookie("STATION", randID);
    }

    setTimeInTitle();
    setMethods([
      { name: "manageMenuModifier", func: manageMenuModifier },
      { name: "manageDealMenu", func: manageDealMenu },
      { name: "manageMenuExceptions", func: manageMenuExceptions },
    ]);

    POSAdminPrc.getDiscountCampaign(
      token,
      (msg) => {
        setDiscount(null);
      },
      (discount) => {
        setDiscount(discount);
      }
    );

    POSAdminPrc.getModTree(
      token,
      (message) => setError(message),
      (data) => {
        setModifiers(data);
        let modData = [];
        modData = data.map((m) => getModData(m));
        setModTree(
          modData.sort((a, b) =>
            a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
          )
        );
      }
    );
    POSAdminPrc.getMenuTree(
      token,
      (message) => setError(message),
      (data) => {
        setMenus(data);
        let menuData = [];
        menuData = data.map((m) => getMenuData(m));
        setMenuTree(
          menuData.sort((a, b) =>
            a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
          )
        );
      }
    );

    if (loadTb != null && loadTb.length !== 0) {
      setUser({
        active: true,
        admin: false,
        driver: false,
        id: 0,
        kitchen: false,
        manager: false,
        name: "Table",
      });
      POSAdminPrc.getTableOrder(
        loadTb,
        token,
        (msg) => {
          setError(msg);
          Authentication.logout(
            token,
            (msg) => setError(msg),
            () => {
              setToken(null);
              setProfile(null);
            }
          );
        },
        (oId) => {
          setOIDToLoad(oId);
          setShowOrderScr(true);
        }
      );
    }

    if (deals == null)
      POSAdminPrc.getDeals(
        token,
        (message) => setError(message),
        (data) => {
          data.forEach((deal) => {
            deal.deal_menus.sort((d1, d2) => d1.group_id - d2.group_id);
          });
          setDeals(data);
        }
      );

    const timer = setInterval(function () {
      setTimeInTitle();
    }, 60 * 10000);

    document.addEventListener(
      "keydown",
      function (e) {
        if (e.key === "Enter") {
          toggleFullScreen();
        }
      },
      false
    );

    setHint("Please press the Enter key to go full screen.");
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configs.length === 0) return;
    let sw = configs.find((c) => c.name === "ws_server");
    if (typeof sw === "undefined") return;
    if (socketUrl !== sw.value && sw.value !== null && sw.value.length !== 0)
      setSocketUrl(sw.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  useEffect(() => {
    if (user != null && user.kitchen) {
      setShowKitchenScr(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (lastMessage != null) {
      let m = JSON.parse(lastMessage.data);
      if (m.from !== Tools.getCookie("STATION")) {
        setMessages([...messages, m]);
        setHint("You have new messages!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  if (profile == null) {
    navigate("/");
    return "";
  }
  if (busy != null) return <LoadingPage message={busy} />;
  return (
    <div className="flex justify-content-center">
      <Dialog
        dlgLabel="Display"
        position="top"
        header="Attention Requested"
        visible={messages.length !== 0}
        closable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {messages.length !== 0 && (
          <div
            className="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between"
            style={{
              borderRadius: "1rem",
              background:
                "linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)",
            }}
          >
            <div className="pr-6">
              <div className="text-blue-100 font-medium text-xl mb-3">
                Incoming message from table{" "}
                <span className="text-pink-700">{messages[0].table}</span> for{" "}
                <span className="text-pink-700">{messages[0].server}</span>
              </div>
              <div className="text-white font-medium text-5xl">
                {messages[0].msg}
              </div>
            </div>
            <div className="mt-4 mr-auto md:mt-0 md:mr-0">
              <Button
                label="Acknowledge"
                className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"
                onClick={(e) => {
                  let newMs = [...messages];
                  newMs.shift();
                  setMessages([...newMs]);
                }}
              />
            </div>
          </div>
        )}
      </Dialog>
      <ManagedDialog
        closeFn={setShowGetUser}
        visible={showGetUser}
        maximized={false}
        header="Please login"
      >
        <GetUser
          closeFunc={(aUser) => {
            setShowGetUser(false);
            setHint("Welcome back " + aUser.name);
          }}
        />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowException}
        visible={showException}
        maximized={false}
        style={{ width: "60vw" }}
        header="Day/Time price exception table"
      >
        <ExceptionPage item={menu} processFn={processSetMenuException} />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowLayout}
        visible={showLayout}
        maximized={true}
        header="Use the slider to select up to five different layouts."
      >
        <Layout />
      </ManagedDialog>
      <ManagedDialog
        closeFn={null} //setShowOrderScr
        visible={showOrderScr}
        maximized={true}
        header={
          "Order Screen, Server => " +
          (user == null ? "No Server" : user.name) +
          " - " +
          title
        }
      >
        <OrderProcess
          closeFn={oIDToLoad == null ? setShowOrderScr : null}
          orderID={oIDToLoad == null ? 0 : oIDToLoad}
        />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowMenuModifier}
        visible={showMenuModifier}
        maximized={false}
        style={{ width: "90vw" }}
        header="Menu Modifire"
      >
        <ModTree menu={menu} />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowDealMenu}
        visible={showDealMenu}
        maximized={false}
        style={{ width: "90vw" }}
        header="Deal Menu"
      >
        <MenuTree deal={deal} />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowPage}
        visible={showPage}
        maximized={false}
        style={{ width: "90vw" }}
        header="CRUD Operator"
      >
        <EntryForm
          inEntity={entity}
          inCondition={condition}
          methods={methods}
        />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowKitchenScr}
        visible={showKitchenScr}
        maximized={true}
        header="Kitchen Monitor"
      >
        <KitchenPage />
      </ManagedDialog>
      <div className="grid formgrid p-fluid m-5 shadow-2 m-3 p-3 w-12">
        <div className="field col-12 flex justify-content-center">
          <span className="text-2xl font-medium text-600">P.O.S.</span>
        </div>
        {user === null && (
          <div className="field col-12 md:col-3">
            <Button
              label="Login"
              icon="text-4xl pi pi-sign-in text-pink-800"
              iconPos="top"
              className="text-4xl p-button-outlined ripple"
              onClick={(e) => {
                setShowGetUser(true);
              }}
            />
          </div>
        )}
        {user != null && user.active && user.admin && user.manager && (
          <div className="field col-12 md:col-3">
            <Button
              label="Config"
              icon="text-4xl pi pi-cog text-yellow-900"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Config");
                setCondition(null);
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Users"
              icon="text-4xl pi pi-users text-green-500"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("User");
                setCondition(null);
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Modifiers"
              icon="text-4xl pi pi-palette text-pink-500"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Modifier");
                setCondition({ field: "parent", value: null });
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Menus"
              icon="text-4xl pi pi-book text-indigo-300"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Menu");
                setCondition({ field: "parent", value: null });
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Deals"
              icon="text-4xl pi pi-dollar text-green-500"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Deal");
                setCondition(null);
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Layout"
              icon="text-4xl pi pi-map text-yellow-400"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setShowLayout(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Cupons"
              icon="text-4xl pi pi-ticket text-green-300"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Coupon");
                setCondition(null);
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active || !user.admin || !user.manager) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Partners"
              icon="text-4xl far fa-handshake text-pink-800"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setEntity("Partner");
                setCondition(null);
                setShowPage(true);
              }}
            />
          </div>
        )}
        {!(user == null || !user.active) && (
          <div className="field col-12 md:col-3">
            <Button
              label="Order"
              icon="text-4xl pi pi-shopping-cart  text-pink-300"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setShowOrderScr(true);
              }}
            />
          </div>
        )}
        {user != null && user.kitchen && (
          <div className="field col-12 md:col-3">
            <Button
              label="Kitchen"
              icon="text-4xl fab fa-hotjar text-yellow-300"
              iconPos="top"
              disabled={user == null || !user.active}
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setShowKitchenScr(true);
              }}
            />
          </div>
        )}
        {user != null && user.active && (
          <div className="field col-12 md:col-3">
            <Button
              label="Cash Out"
              iconPos="top"
              icon="text-4xl fas fa-cash-register text-orange-500"
              disabled={user == null || !user.active}
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setBusy("Cashing out ....");
                POSAdminPrc.cashOut(
                  configs,
                  user.id,
                  token,
                  (msg) => setError(msg),
                  () => {
                    setUser(null);
                    setBusy(null);
                  }
                );
              }}
            />
          </div>
        )}
        {user != null && user.active && user.manager && (
          <div className="field col-12 md:col-3">
            <Button
              label="Day End"
              iconPos="top"
              icon="text-4xl fas fa-hourglass-end text-orange-500"
              disabled={user == null || !user.active}
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setBusy("Runing Day End Process ....");

                POSAdminPrc.dayEnd(
                  configs,
                  token,
                  (msg) => setError(msg),
                  () => {
                    setUser(null);
                    setBusy(null);
                  }
                );
              }}
            />
          </div>
        )}
        {user != null && (
          <div className="field col-12 md:col-3">
            <Button
              label="Logout"
              icon="text-4xl pi pi-sign-out text-green-500"
              iconPos="top"
              className="p-button-outlined text-4xl"
              onClick={(e) => {
                setUser(null);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default POSAdmin;
