import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { AppContext } from "../../common/AppContext";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
//import ReactPasswordStrength from "react-password-strength";
import Authentication from "./Authentication";

function PasswordChangeForm() {
  //const [passIsValid, setPassIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const { setError, setHint, token } = useContext(AppContext);
  const navigate = useNavigate();

  const invalidData = () => {
    const tst = password_confirmation === password;
    return !tst;
  };
  const goHome = () => {
    navigate("/");
  };

  const callPasswordChange = () => {
    Authentication.changePassword(
      password,
      token,
      function (message) {
        setError(message);
      },
      function () {
        setHint("Your password is changed successfuly.");
        navigate("/");
      }
    );
  };

  return token !== null ? (
    <div className="flex align-items-center justify-content-center mt-3">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">
            Change Password
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          {/* <ReactPasswordStrength
            minLength={8}
            changeCallback={(a, b) => {
              setPassword(a.password);
              setPassIsValid(a.isValid);
            }}
            inputProps={{ autoComplete: "off", className: "w-full" }}
          /> */}
          <InputText
            className="w-full mb-3"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label
            htmlFor="password"
            className="block text-900 font-medium mb-2 mt-3"
          >
            Confirm Password
          </label>
          <InputText
            className="w-full mb-3"
            type="password"
            id="password_confirmation"
            value={password_confirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <div className="formgrid grid">
            <div className="field col">
              <Button
                label="Sign up"
                icon="pi pi-user-plus"
                className="p-button-success w-full"
                onClick={callPasswordChange}
                {...(invalidData() ? { disabled: true } : {})}
              />
            </div>
            <div className="field col">
              <Button
                label="Back"
                icon="pi pi-times"
                className="p-button-warning w-full"
                onClick={goHome}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Card title="Password change">
      <Message
        severity="success"
        text="Your password is updated successfully."
      />
    </Card>
  );
}

export default PasswordChangeForm;
