import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

const OrderItemMenuOptionAttr = ({ optDtl, user, updateOption }) => {
  return (
    <Card title="Attributes">
      <div className="grid formgrid p-fluid">
        {false && (
          <div className="field col-12">
            <label className="block text-900 font-medium mb-2">
              Miscellaneous item
            </label>
            <InputText
              className="w-full"
              value={optDtl.etc_item}
              onChange={(e) => {
                updateOption({ ...optDtl, etc_item: e.target.value });
              }}
            />
          </div>
        )}
        {user.admin &&
          (optDtl.menuMod.price !== 0 ||
            (optDtl.dbl && optDtl.menuMod.dbl_price !== 0) ||
            (optDtl.trp && optDtl.menuMod.trp_price !== 0)) && (
            <div className="field col-12">
              <label className="block text-900 font-medium mb-2">Free </label>
              <InputSwitch
                checked={optDtl.free}
                onChange={(e) => {
                  updateOption({ ...optDtl, free: !optDtl.free });
                }}
              />
            </div>
          )}
        {optDtl.modifier.has_HLF && (
          <div className="field col-3">
            <label className="block text-900 font-medium mb-2">Easy </label>
            <InputSwitch
              checked={optDtl.easy}
              onChange={(e) => {
                let no = { ...optDtl };
                no.easy = !no.easy;
                if (no.easy) {
                  no.dbl = false;
                  no.trp = false;
                  no.hlf = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        {optDtl.modifier.has_HLF && (
          <div className="field col-3">
            <label className="block text-900 font-medium mb-2">Half </label>
            <InputSwitch
              checked={optDtl.hlf}
              onChange={(e) => {
                let no = { ...optDtl };
                no.hlf = !no.hlf;
                if (no.hlf) {
                  no.dbl = false;
                  no.trp = false;
                  no.easy = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        {optDtl.modifier.has_DBL && (
          <div className="field col-3">
            <label className="block text-900 font-medium mb-2">Double</label>
            <InputSwitch
              checked={optDtl.dbl}
              onChange={(e) => {
                let no = { ...optDtl };
                no.dbl = !no.dbl;
                if (no.dbl) {
                  no.easy = false;
                  no.trp = false;
                  no.hlf = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        {optDtl.modifier.has_TRP && (
          <div className="field col-3">
            <label className="block text-900 font-medium mb-2">Triple</label>
            <InputSwitch
              checked={optDtl.trp}
              onChange={(e) => {
                let no = { ...optDtl };
                no.trp = !no.trp;
                if (no.trp) {
                  no.easy = false;
                  no.dbl = false;
                  no.hlf = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        <div className="col-12"></div>
        {optDtl.modifier.has_LR && (
          <div className="field col-6">
            <label className="block text-900 font-medium mb-2">On Left</label>
            <InputSwitch
              checked={optDtl.left}
              onChange={(e) => {
                let no = { ...optDtl };
                no.left = !no.left;
                if (no.left) {
                  no.right = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        {optDtl.modifier.has_LR && (
          <div className="field col-6">
            <label className="block text-900 font-medium mb-2">On Right</label>
            <InputSwitch
              checked={optDtl.right}
              onChange={(e) => {
                let no = { ...optDtl };
                no.right = !no.right;
                if (no.right) {
                  no.left = false;
                }
                updateOption(no);
              }}
            />
          </div>
        )}
        <div className="field col-12">
          <label className="block text-900 font-medium mb-2">Add Note</label>
          <InputText
            className="w-full"
            value={optDtl.note}
            onChange={(e) => {
              updateOption({ ...optDtl, note: e.target.value });
            }}
          />
        </div>
        <div className="field col-12">
          <Button
            icon="pi pi-trash"
            label="Remove"
            className="p-button-danger p-button-outlined"
            onClick={(e) => updateOption({ ...optDtl, delete: true })}
          />
        </div>
        {/* <div className="field col-12">
              <Keyboard
                input={optDtl.etc_item}
                preventMouseDownDefault={true}
                onChange={kbOnChange}
                inputName="password"
                layout={{
                  default: ["1 2 3", "4 5 6", "7 8 9", " 0 ", "{bksp}"],
                }}
                theme="hg-theme-default hg-layout-numeric numeric-theme"
              />
            </div> */}
      </div>
    </Card>
  );
};

export default OrderItemMenuOptionAttr;
