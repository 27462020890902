import Tools from "../../common/Tools";
import AdminPrc from "../../common/AdminPrc";

const POSAdminPrc = {
  seqNames: [
    "1st item",
    "2nd item",
    "3rd item",
    "4th item",
    "5th item",
    "6th item",
    "7th item",
    "8th item",
    "9th item",
    "10th item",
    "11th item",
  ],

  // OrderType
  DINE_IN: "DI",
  WALK_IN: "WI",
  PICK_UP: "PI",
  DELIVER: "DL",

  // Commands
  ADD_ORDER_ITEM_CMD: "ADD_ITEM",
  EDIT_ORDER_ITEM_CMD: "EDIT_ITEM",
  MEMO_ORDER_ITEM_CMD: "MEMO_ITEM",
  INC_ORDER_ITEM_CMD: "INC_ITEM",
  CLONE_ORDER_ITEM_CMD: "CLN_ITEM",
  DEC_ORDER_ITEM_CMD: "DEC_ITEM",
  DEL_ORDER_ITEM_CMD: "DEL_ITEM",
  UPDATE_ORDER_ITEM_OPTIONS: "UPDATE_ORDER_ITEM_OPTIONS",
  GET_ORDER_TYPE: "GET_ORDER_TYPE",
  DISCAD_ORDER_CHANGES_CMD: "CANCEL_CHANGES_CMD",
  DISCAD_ORDER_CMD: "CANCEL_CMD",
  SHOW_CUSTOMER_CMD: "SHOE_CUST_CMD",
  LOAD_ORDER_HIS_CMD: "LOAD_CUST_OH_CMD",
  LOAD_LAST_ORDER_CMD: "LOAD_CUST_LO_CMD",
  START: "START",
  SELECT_FROM_MENU_ITEMS: "SELECT_MENU_ITEM",
  SELECT_FROM_DEAL_ITEMS: "SELECT_DEAL_ITEM",
  LOAD_ORDER_CMD: "LOAD_OR_CMD",
  SAVE_ORDER_CMD: "SAVE_OR_CMD",
  CHANGE_TABLE_CMD: "CHANGE_TB_CMD",
  ADD_REMOVE_GUEST_CMD: "ADD_GU_CMD",
  CHANGE_GUEST_CMD: "CHANGE_GS_CMD",
  BILL_OPTIONS_CMD: "BILL_OPT_CMD",
  PRINT_CMD: "PRINT_CMD",
  PAY_CMD: "PAY_CMD",
  SET_GUEST_CMD: "SET_GS_CMD",
  SELECT_PARTNER_CMD: "SELECT_PARTNER_CMD",
  CANCEL_ORDER_CMD: "CANCEL_ORDER_CMD",

  doNothing: () => {},

  getModTree: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/modTree", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getMenuMods: (id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/modifiers/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getMenuTree: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/menuTree", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getDeals: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/deals", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getDealMenus: (id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/menus/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  rewardUse: (cid, points, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/rewards", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ id: cid, points }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getTableOrder: (tb, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/tableorder/" + tb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.oid);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  checkTableUser: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/tableuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.tUser);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  updateMenuModifier: (mmData, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/updatemm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ mm: mmData }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  removeMenuModifier: (menu_id, mod_id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/removemm", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ menu_id: menu_id, modifier_id: mod_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  updateDealMenu: (dmData, deal_id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/updatedm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ dm: dmData, deal_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getNextId: (key, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/nextid/" + key, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  loadOrders: (customer, token, onError, onSuccess) => {
    fetch(
      process.env.REACT_APP_HOST_URL +
        "posapi/orders" +
        (customer === 0 ? "" : "?customer=" + customer),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: null,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.orders);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  loadOrder: (oid, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/order/" + oid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.order);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  addCustomer: (phone, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/customer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ phone }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.customer);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  saveCustomer: (customer, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/customer", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ ...customer }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  strLeftRight: (s1, s2, len = 42) => {
    let sp = "";
    let s = s1 + sp + s2;
    while (s.length < 42) {
      sp += " ";
      s = s1 + sp + s2;
    }
    return s;
  },

  getOptionLabel: (opt) => {
    let res = opt.modifier.name;
    let ops = [];
    if (opt.free) ops.push("Free");
    if (opt.easy) ops.push("Easy");
    if (opt.hlf) ops.push("Half");
    if (opt.dbl) ops.push("Double");
    if (opt.trp) ops.push("Triple");
    if (opt.left) ops.push("On Left ");
    if (opt.right) ops.push("On Right ");
    if (opt.note.length !== 0) ops.push("NOTE :" + opt.note);
    if (ops.length !== 0) res = res + " [" + ops.toString() + "]";
    return res;
  },

  loadUserOrders: (user, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/cashout/" + user, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.orders, data.user, data.timein, data.logs);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  saveOrder: (order, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/saveOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ ...order }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.order);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  findCustomer: (phone, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/customer/" + phone, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.customer);
        } else {
          onSuccess(null);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getTables: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/tables", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  updateTables: (tbData, area, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/updatetb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ tb: tbData, area }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  setTableServer: (tb_id, user_id, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/tbserver", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ tb_id, user_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  changeTableServer: (
    tb1_id,
    tb2_id,
    user_id,
    order_id,
    token,
    onError,
    onSuccess
  ) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/tbserverchg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ tb1_id, tb2_id, user_id, order_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getUser: (password, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/user/" + password, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  logUser: (password, token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  getMenuModifiers: (token, onError, onSuccess) => {
    fetch(process.env.REACT_APP_HOST_URL + "posapi/menumodifiers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.data);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System Error");
      });
  },

  buildFinancials: (order, guest, configs) => {
    if (order.orderItems.length === 0) {
      order.finances = order.finances.filter((f) => false);
      return { total: 0, grandTotal: 0 };
    }
    order.finances = order.finances.filter((f) => f.type !== "B");
    let total = 0.0;
    let tax = 0.0;
    let delivery = 0.0;
    let delivery_surcharge = 0.0;
    let partner =
      order.partner == null ? 1 : 1 + parseFloat(order.partner.amount) / 100;
    order.orderItems.forEach((oi, index) => {
      if (guest === 0 || oi.guest === guest) {
        let base =
          (oi.menu == null ? oi.deal.price : POSAdminPrc.getMenuPrice(oi)) *
          partner;
        let extra = 0.0;
        let aTotal = 0.0;

        // if (oi.seq === -1) {
        extra = POSAdminPrc.getExtraCost(index, order.orderItems) * partner;
        aTotal = POSAdminPrc.getTotal(index, order.orderItems) * partner;
        // } else {
        // extra = APrc.getExtraCost(oi, free_options);
        // aTotal = APrc.getTotal(oi, free_options);
        //      }
        order.finances.push({
          key: "oi-" + index + "_base",
          amount: base,
          show: false,
          guest,
          type: "B",
        });
        order.finances.push({
          key: "oi-" + index + "_total",
          amount: aTotal,
          show: false,
          guest,
          type: "B",
        });
        order.finances.push({
          key: "oi-" + index + "_extra",
          amount: extra,
          show: false,
          guest,
          type: "B",
        });
        total += aTotal;
      }
    });

    order.finances.push({
      key: "Total",
      amount: total,
      show: true,
      guest,
      type: "B",
      row: 1,
    });

    let taxSet = configs
      .filter((e) => e.name.startsWith("tax_"))
      .map((t) => ({
        name: t.name.substring(4),
        amount: (parseFloat(t.value) * total) / 100,
      }));
    tax = taxSet.reduce((a, b) => a.amount + b.amount);

    taxSet.forEach((t) => {
      order.finances.push({
        key: t.name,
        amount: t.amount,
        show: true,
        guest,
        type: "B",
        row: 2,
      });
    });

    order.finances.push({
      key: "Sub Total",
      amount: total + tax.amount,
      show: true,
      guest,
      type: "B",
      row: 3,
    });

    if (
      order.type === POSAdminPrc.DELIVER &&
      (order.partner == null || order.partner.id === 0)
    ) {
      let d = parseFloat(configs.find((c) => c.name === "delivery").value);
      let d_l = parseFloat(
        configs.find((c) => c.name === "delivery_limit").value
      );
      let d_m = parseFloat(
        configs.find((c) => c.name === "delivery_min").value
      );
      if (total < d_l) {
        delivery = d;
        order.finances.push({
          key: "Delivery",
          amount: d,
          show: true,
          guest,
          type: "B",
          row: 4,
        });
        if (total < d_m) {
          delivery_surcharge = d_m - total;
          order.finances.push({
            key: "Delivery Sur.",
            amount: d_m - total,
            show: true,
            guest,
            type: "B",
            row: 5,
          });
        }
      }
    }

    order.finances.push({
      key: "Grand Total",
      amount: total + tax.amount + delivery + delivery_surcharge,
      show: true,
      guest,
      type: "B",
      row: 10,
    });

    return {
      total: total,
      grandTotal: total + tax.amount + delivery + delivery_surcharge,
    };
  },

  getType: (order) => {
    switch (order.type) {
      case "":
        return "Waiting .....";
      case POSAdminPrc.WALK_IN:
        return "Walking";
      case POSAdminPrc.DELIVER:
        return "Delivery";
      case POSAdminPrc.PICK_UP:
        return "Pickup";
      case POSAdminPrc.DINE_IN:
        return "Dine In";
      default:
        return "Unknown";
    }
  },

  getExtraCost: (index, items) => {
    let amount = 0.0;
    let free_cnt = 0;
    let oi = items[index];
    let free_options = 0;

    if (oi.deal == null) {
      free_options = oi.menu.free_options;
    } else {
      free_options = oi.deal.free_options;
    }

    let options = oi.options;
    let extra_cost = oi.extra_cost;

    if (oi.seq === -1 && oi.deal == null) {
      let children = items.filter((i) => i.parent === items[index].id);
      // Get all their options and extra costs
      children.forEach((i) => {
        options = [...options, ...i.options];
        extra_cost += i.extra_cost;
      });
    } else if (oi.seq === -1 && oi.deal != null) {
      let children = items.filter((i) => i.parent === items[index].id);
      // Get all their options and extra costs
      children.forEach((i, cIndex) => {
        //options = [...options, ...i.options];
        extra_cost += i.extra_cost;
        extra_cost += i.dm.price;
        extra_cost += POSAdminPrc.getExtraCost(index + cIndex + 1, items);
      });
    }

    if (oi.menu != null && oi.deal != null) extra_cost += oi.dm.price;

    options.forEach((op) => {
      if (op.dbl) {
        if (op.menuMod.dbl_price !== 0) {
          if (free_cnt === free_options - 1) {
            amount += op.menuMod.price;
            free_cnt++;
          } else if (free_cnt <= free_options - 2) {
            free_cnt += 2;
          } else {
            free_cnt += 2;
            amount += op.menuMod.dbl_price;
          }
        }
      } else if (op.trp) {
        if (op.menuMod.trp_price !== 0) {
          if (free_cnt === free_options - 1) {
            amount += op.menuMod.dbl_price;
            free_cnt++;
          } else if (free_cnt === free_options - 2) {
            amount += op.menuMod.price;
            free_cnt += 2;
          } else if (free_cnt <= free_options - 3) {
            free_cnt += 3;
          } else {
            free_cnt += 3;
            amount += op.menuMod.trp_price;
          }
        }
      } else if (op.hlf) {
        if (op.menuMod.hlf_price !== 0) {
          if (free_cnt < free_options) {
            amount += op.menuMod.hlf_price;
          }
          free_cnt++;
        }
      } else if (op.menuMod.price !== 0) {
        if (op.menuMod.price === oi.menu.modifier_price) {
          if (free_cnt >= free_options) {
            amount += op.menuMod.price;
          }
          free_cnt++;
        } else {
          amount += op.menuMod.price;
        }
      }
    });
    return amount + extra_cost;
  },

  getMenuPrice: (oi) => {
    if (oi.menu.exception == null) return oi.menu.price;
    let exception = JSON.parse(oi.menu.exception);
    let oits = oi.timestamp;
    if (typeof oits === "string") oits = new Date(Date.parse(oits));
    let day = oits.getDay();
    let time = oits.getHours() + ":" + oits.getMinutes();
    if (
      (exception.weekday[day] ||
        (!exception.weekday[0] &&
          !exception.weekday[1] &&
          !exception.weekday[2] &&
          !exception.weekday[3] &&
          !exception.weekday[4] &&
          !exception.weekday[5] &&
          !exception.weekday[6])) &&
      time >= exception.fromTime &&
      time <= exception.toTime
    ) {
      return exception.price;
    }
    return oi.menu.price;
  },

  getTotal: (index, items) => {
    let oi = items[index];
    let total = 0;
    if (oi.master) {
      total = POSAdminPrc.getExtraCost(index, items);
      total += oi.menu == null ? oi.deal.price : POSAdminPrc.getMenuPrice(oi);
    }
    return total * oi.count;
  },

  isPaid: (order, guest) => {
    if (order.finances.length === 0) return false;
    let b = order.finances.filter(
      (f) =>
        f.show &&
        (guest === 0 || f.guest === guest) &&
        [10, 11, 8].includes(f.row)
    );
    b = b.reduce((t, f) => t + f.amount, 0);
    let p = order.finances.filter(
      (f) => f.show && (guest === 0 || f.guest === guest) && f.row >= 20
    );
    p = -1 * p.reduce((t, f) => t + f.amount, 0);
    if (b !== 0 && b === p) return true;
    return false;
  },

  configCheck: (token, data, name, value) => {
    if (typeof data.find((a) => a.name === name) === "undefined") {
      data.push({ name, value });
      AdminPrc.addOrUpdateEntity(
        "CRUD$Config",
        { id: 0, name, value },
        token,
        () => {},
        () => {}
      );
    }
  },
};
export default POSAdminPrc;
