import React, { useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { AppContext } from "../../common/AppContext";
import { Link, useNavigate } from "react-router-dom";
import Tools from "../Tools";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import LoadingPage from "../Utils/LoadingPage";
//import ReactPasswordStrength from "react-password-strength";
import Authentication from "./Authentication";
import AppTools from "../../components/AppTools";

function RegisterForm() {
  //const [passIsValid, setPassIsValid] = useState(false);
  const [busy, setBusy] = useState(false);
  const [role, setRole] = useState(AppTools.CONSUMER);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [registered, setRegistered] = useState(false);

  const { setError } = useContext(AppContext);
  const navigate = useNavigate();

  const invalidData = () => {
    const tst =
      Tools.validateEmail(username) &&
      //passIsValid &&
      password_confirmation === password;
    return !tst;
  };
  const goHome = () => {
    navigate("/");
  };

  const callSignup = () => {
    setBusy(true);
    Authentication.signup(
      username,
      password,
      password_confirmation,
      role,
      function (message) {
        setError(message);
        setRegistered(false);
        setBusy(false);
      },
      function () {
        Tools.processEmail();
        setRegistered(true);
        setBusy(false);
      }
    );
  };

  if (busy)
    return (
      <LoadingPage message="Please wait while we are registering you ..." />
    );
  else
    return !registered ? (
      <div className="flex align-items-center justify-content-center mt-3">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Join {process.env.REACT_APP_DEPLOYMENT_NAME}
            </div>
            <span className="text-600 font-medium line-height-3">
              Already have an account?
            </span>
            <Link
              to="/login"
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >
              Sign in
            </Link>
          </div>

          <div className="text-center">
            <div className="grid">
              <div className="col-12 lg:col-6">
                <div
                  className={
                    role !== AppTools.CONSUMER
                      ? "shadow-2 border-round border-2 cursor-pointer surface-card border-transparent"
                      : "shadow-2 border-round border-2 cursor-pointer surface-card border-blue-500"
                  }
                  onClick={() => setRole(AppTools.CONSUMER)}
                >
                  <div className="p-3">
                    <div className="text-900 text-xl mb-3 font-medium">
                      I am a consumer
                    </div>
                    <RadioButton
                      name="role"
                      value={0}
                      onChange={(e) => setRole(AppTools.CONSUMER)}
                      checked={role === AppTools.CONSUMER}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <div
                  className={
                    role !== AppTools.BUSINESS
                      ? "shadow-2 border-round border-2 cursor-pointer surface-card border-transparent"
                      : "shadow-2 border-round border-2 cursor-pointer surface-card border-blue-500"
                  }
                  onClick={() => setRole(AppTools.BUSINESS)}
                >
                  <div className="p-3">
                    <div className="text-900 text-xl mb-3 font-medium">
                      I am a business
                    </div>
                    <RadioButton
                      name="role"
                      value={1}
                      onChange={(e) => setRole(AppTools.BUSINESS)}
                      checked={role === AppTools.BUSINESS}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              className="w-full mb-3"
              id="username"
              autoComplete="off"
              value={username}
              keyfilter="email"
              onChange={(e) => setUsername(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password - Min 8 is length, must include Uperrcase, Lowercase,
              Numbers and Special characters.
            </label>
            <InputText
              className="w-full mb-3"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <ReactPasswordStrength
              minLength={8}
              changeCallback={(a, b) => {
                setPassword(a.password);
                setPassIsValid(a.isValid);
              }}
              inputProps={{ autoComplete: "off", className: "w-full" }}
            /> */}

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2 mt-3"
            >
              Confirm Password
            </label>
            <InputText
              className="w-full mb-3"
              type="password"
              id="password_confirmation"
              value={password_confirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />

            <div className="formgrid grid">
              <div className="field col">
                <Button
                  label="Sign up"
                  icon="pi pi-user-plus"
                  className="p-button-success w-full"
                  disabled={busy}
                  onClick={callSignup}
                  {...(invalidData() ? { disabled: true } : {})}
                />
              </div>
              <div className="field col">
                <Button
                  label="Back"
                  icon="pi pi-times"
                  className="p-button-warning w-full"
                  onClick={goHome}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Card title="Account Activation">
        <Message
          severity="success"
          text="You've been registered successfully, Pleasae check your email to activate your account so you can login."
        />
      </Card>
    );
}

export default RegisterForm;
