import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import LocationSearchFInput from "../../common/Utils/LocationSearchFInput";
import { InputNumber } from "primereact/inputnumber";
import POSAdminPrc from "./POSAdminPrc";

const CustomerPage = ({ needAddress = false, customer, processFn }) => {
  const { token, setError } = useContext(AppContext);
  const [cust, setCust] = useState({ ...customer });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid m-5 shadow-2 m-3 p-3 w-9">
        <div className="field col-12 md:col-4">
          <label className="font-medium">Phone</label>
          <InputText
            className="w-full mb-3"
            autoComplete="off"
            readOnly
            value={cust.phone}
            onChange={(e) => {}}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="font-medium">Email</label>
          <InputText
            type="text"
            keyfilter="email"
            value={cust.email}
            onChange={(e) => {
              setCust({ ...cust, email: e.target.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="font-medium">Name</label>
          <InputText
            className="w-full mb-3"
            autoComplete="off"
            value={cust.name}
            onChange={(e) => {
              setCust({ ...cust, name: e.target.value });
            }}
          />
        </div>
        {typeof google === "object" && (
          <div className="field col-12 md:col-6">
            <label className="font-medium">Address</label>
            <LocationSearchFInput
              value={cust.address}
              setLatLng={(latlng, v) => {
                setCust({
                  ...cust,
                  coordinate: latlng,
                  address: v,
                });
              }}
            />
          </div>
        )}
        <div className="field col-12 md:col-6">
          <label className="font-medium">Note</label>
          <InputText
            className="w-full mb-3"
            autoComplete="off"
            value={cust.note}
            onChange={(e) => {
              setCust({ ...cust, note: e.target.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="font-medium">One time note</label>
          <InputText
            className="w-full mb-3"
            autoComplete="off"
            value={cust.one_note}
            onChange={(e) => {
              setCust({ ...cust, one_note: e.target.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label className="font-medium">Alert</label>
          <InputText
            className="w-full mb-3"
            autoComplete="off"
            value={cust.alert}
            onChange={(e) => {
              setCust({ ...cust, alert: e.target.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="font-medium">Points</label>
          <InputNumber
            autoComplete="nope"
            readOnly
            mode="decimal"
            value={cust.points}
            onChange={(e) => {
              setCust({ ...cust, points: e.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="font-medium">Credit</label>
          <InputNumber
            autoComplete="nope"
            readOnly
            mode="currency"
            currency="CAD"
            value={cust.credit}
            onChange={(e) => {
              setCust({ ...cust, credit: e.value });
            }}
          />
        </div>
        <div className="field col-12 md:col-4">
          <label className="font-medium">Extra Delivery Charge</label>
          <InputNumber
            autoComplete="nope"
            readOnly
            mode="currency"
            currency="CAD"
            value={cust.extra_delivery_charge}
            onChange={(e) => {
              setCust({ ...cust, extra_delivery_charge: e.value });
            }}
          />
        </div>
        <div className="field col-12"></div>
        <div className="field col-3 flex align-content-center align-items-center">
          <div className="grid">
            <div className="field col-12">
              <label className="font-medium">Delivery location</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="house"
                name="type"
                value="H"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "H"}
              />
              <label className="font-medium ml-2 mt-1">House</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="apartment"
                name="type"
                value="A"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "A"}
              />
              <label className="font-medium ml-2 mt-1">Apartment</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="townhouse"
                name="type"
                value="T"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "T"}
              />
              <label className="font-medium ml-2 mt-1">Townhouse</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="business"
                name="type"
                value="B"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "B"}
              />
              <label className="font-medium ml-2 mt-1">Business</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="school"
                name="type"
                value="S"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "S"}
              />
              <label className="font-medium ml-2 mt-1">School</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="hospital"
                name="type"
                value="O"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "O"}
              />
              <label className="font-medium ml-2 mt-1">Hospital</label>
            </div>
            <div className="mb-2 col-12 ">
              <RadioButton
                inputId="hotel"
                name="type"
                value="L"
                onChange={(e) => {
                  setCust({ ...cust, type: e.value });
                }}
                checked={cust.type === "L"}
              />
              <label className="font-medium ml-2 mt-1">Hotel</label>
            </div>
          </div>
        </div>
        {cust.type === "H" && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="field col-12">
                <label className="font-medium">Delivery At</label>
              </div>
              <div className="mb-2 col-12 ">
                <RadioButton
                  inputId="frontDoor"
                  name="deliveryMethod"
                  value="1"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "1"}
                />
                <label className="font-medium ml-2 mt-1">Front Door</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="sideDoor"
                  name="deliveryMethod"
                  value="2"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "2"}
                />
                <label className="font-medium ml-2 mt-1">Side Door</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="backDoor"
                  name="deliveryMethod"
                  value="3"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "3"}
                />
                <label className="font-medium ml-2 mt-1">Back Door</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="backyard"
                  name="deliveryMethod"
                  value="4"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "4"}
                />
                <label className="font-medium ml-2 mt-1">Backyard</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="garage"
                  name="deliveryMethod"
                  value="5"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "5"}
                />
                <label className="font-medium ml-2 mt-1">Garage</label>
              </div>
            </div>
          </div>
        )}
        {(cust.type === "A" || cust.type === "T") && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="mb-2 col-12">
                <label className="font-medium">
                  {cust.type === "A" ? "Apartment #" : "Unit #"}
                </label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.apartment_number}
                  onChange={(e) => {
                    setCust({ ...cust, apartment_number: e.target.value });
                  }}
                />
              </div>
              <div className="mb-2 col-12">
                <label className="font-medium">Buzzer #</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.buzz_number}
                  onChange={(e) => {
                    setCust({ ...cust, buzz_number: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {cust.type === "B" && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="field col-12">
                <label className="font-medium">Delivery At</label>
              </div>
              <div className="mb-2 col-12 ">
                <RadioButton
                  inputId="ofrontDoor"
                  name="deliveryMethod"
                  value="6"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "6"}
                />
                <label className="font-medium ml-2 mt-1">Front Door</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="mainoffice"
                  name="deliveryMethod"
                  value="7"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "7"}
                />
                <label className="font-medium ml-2 mt-1">Main Office</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="backenterance"
                  name="deliveryMethod"
                  value="8"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "8"}
                />
                <label className="font-medium ml-2 mt-1">Back Entrance</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="sideentrance"
                  name="deliveryMethod"
                  value="9"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "9"}
                />
                <label className="font-medium ml-2 mt-1">Side Entrance</label>
              </div>
              <div className="mt-2 col-12">
                <label className="font-medium ml-2 mt-1">Extension</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.extension}
                  onChange={(e) => {
                    setCust({ ...cust, extension: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {cust.type === "S" && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="mb-2 col-12">Orderd By</div>
              <div className="mb-2 col-6">
                <RadioButton
                  inputId="student"
                  name="isStudent"
                  value="1"
                  onChange={(e) => {
                    setCust({ ...cust, student: e.value });
                  }}
                  checked={cust.student === "1"}
                />
                <label className="font-medium ml-2 mt-1">Student</label>
              </div>
              <div className="mb-2 col-6">
                <RadioButton
                  inputId="teacher"
                  name="isStudent"
                  value="0"
                  onChange={(e) => {
                    setCust({ ...cust, student: e.value });
                  }}
                  checked={cust.student === "0"}
                />
                <label className="font-medium ml-2 mt-1">Teacher</label>
              </div>
              <div className="mb-4 col-12"></div>
              <div className="mb-2 col-12">Deliver To</div>
              <div className="mb-2 col-6">
                <RadioButton
                  inputId="class"
                  name="isCLass"
                  value="1"
                  onChange={(e) => {
                    setCust({ ...cust, classroom: e.value });
                  }}
                  checked={cust.classroom === "1"}
                />
                <label className="font-medium ml-2 mt-1">Classroom</label>
              </div>
              <div className="mb-2 col-6">
                <RadioButton
                  inputId="office"
                  name="isCLass"
                  value="0"
                  onChange={(e) => {
                    setCust({ ...cust, classroom: e.value });
                  }}
                  checked={cust.classroom === "0"}
                />
                <label className="font-medium ml-2 mt-1">Main Office</label>
              </div>
            </div>
          </div>
        )}
        {cust.type === "O" && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="field col-12">
                <label className="font-medium">Delivery At</label>
              </div>
              <div className="mb-2 col-12 ">
                <RadioButton
                  inputId="mainfloor"
                  name="deliveryMethod"
                  value="10"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "10"}
                />
                <label className="font-medium ml-2 mt-1">Main Floor</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="emergency"
                  name="deliveryMethod"
                  value="11"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "11"}
                />
                <label className="font-medium ml-2 mt-1">Emergency</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="north"
                  name="deliveryMethod"
                  value="12"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "12"}
                />
                <label className="font-medium ml-2 mt-1">North</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="south"
                  name="deliveryMethod"
                  value="13"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "13"}
                />
                <label className="font-medium ml-2 mt-1">South</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="east"
                  name="deliveryMethod"
                  value="14"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "14"}
                />
                <label className="font-medium ml-2 mt-1">East</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="west"
                  name="deliveryMethod"
                  value="15"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "15"}
                />
                <label className="font-medium ml-2 mt-1">West</label>
              </div>
              <div className="mt-2 col-6">
                <label className="font-medium ml-2 mt-1">Floor</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.floor}
                  onChange={(e) => {
                    setCust({ ...cust, floor: e.target.value });
                  }}
                />
              </div>
              <div className="mt-2 col-6">
                <label className="font-medium ml-2 mt-1">Wing</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.wing}
                  onChange={(e) => {
                    setCust({ ...cust, wing: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {cust.type === "L" && (
          <div className="field col-9 flex align-content-center align-items-center">
            <div className="grid">
              <div className="field col-12">
                <label className="font-medium">Delivery At</label>
              </div>
              <div className="mb-2 col-12 ">
                <RadioButton
                  inputId="frontlobby"
                  name="deliveryMethod"
                  value="16"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "16"}
                />
                <label className="font-medium ml-2 mt-1">Front Lobby</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="frontdesk"
                  name="deliveryMethod"
                  value="17"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "17"}
                />
                <label className="font-medium ml-2 mt-1">Front Desk</label>
              </div>
              <div className="mb-2 col-12">
                <RadioButton
                  inputId="room"
                  name="deliveryMethod"
                  value="18"
                  onChange={(e) => {
                    setCust({ ...cust, delivery_method: e.value });
                  }}
                  checked={cust.delivery_method === "18"}
                />
                <label className="font-medium ml-2 mt-1">Room</label>
              </div>
              <div className="mt-2 col-4">
                <label className="font-medium ml-2 mt-1">Room Number</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.room}
                  onChange={(e) => {
                    setCust({ ...cust, room: e.target.value });
                  }}
                />
              </div>
              <div className="mt-2 col-4">
                <label className="font-medium ml-2 mt-1">Cell Number</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.cell}
                  onChange={(e) => {
                    setCust({ ...cust, cell: e.target.value });
                  }}
                />
              </div>
              <div className="mt-2 col-4">
                <label className="font-medium ml-2 mt-1">Name</label>
                <InputText
                  className="w-full mb-3"
                  autoComplete="off"
                  value={cust.gname}
                  onChange={(e) => {
                    setCust({ ...cust, gname: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="field col-12">
          <Button
            icon="pi pi-save"
            className="p-button-outlined p-button-success p-button-lg"
            label="Save & Continue"
            onClick={(e) => {
              if (
                needAddress &&
                (typeof cust.coordinate === "undefined" ||
                  cust.coordinate == null)
              ) {
                setError("Please select a valid address.");
                return;
              }
              POSAdminPrc.saveCustomer(
                cust,
                token,
                (message) => setError(message),
                () => {
                  processFn();
                }
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerPage;
