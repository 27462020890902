import Tools from "../Tools";

const Authentication = {
  login: (username, password, onError, onSuccess) => {
    const data = { username, password };

    fetch(process.env.REACT_APP_HOST_URL + "auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.token, data.profile);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  logout: (token, onError, onSuccess) => {
    const data = { token };
    if (token == null) return;

    fetch(process.env.REACT_APP_HOST_URL + "auth/logout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  retrieveLogin: (token, onError, onSuccess) => {
    const data = {};

    fetch(process.env.REACT_APP_HOST_URL + "auth/retrieveLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.profile, data.party);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  autoLogin: (zKey, onError, onSuccess) => {
    const data = {};

    fetch(process.env.REACT_APP_HOST_URL + "auth/autologin/" + zKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.token, data.party);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  checkParty: (zKey, onError, onSuccess) => {
    const data = {};

    fetch(process.env.REACT_APP_HOST_URL + "auth/checkParty/" + zKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.party);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  customerLogin: (loadTb, onError, onSuccess) => {
    const data = {};

    fetch(process.env.REACT_APP_HOST_URL + "auth/customerlogin/" + loadTb, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.token, data.party, data.business);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  signup: (
    username,
    password,
    password_confirmation,
    role,
    onError,
    onSuccess
  ) => {
    const data = { username, password, password_confirmation, role };

    fetch(process.env.REACT_APP_HOST_URL + "auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess(data.profile);
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System failure");
      });
  },

  activate: (email, onError, onSuccess) => {
    const data = { email };

    fetch(process.env.REACT_APP_HOST_URL + "auth/activate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System error.");
      });
  },

  resetPassword: (email, onError, onSuccess) => {
    const data = { email };

    fetch(process.env.REACT_APP_HOST_URL + "auth/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System error.");
      });
  },

  changePassword: (password, token, onError, onSuccess) => {
    const data = { password, token };

    fetch(process.env.REACT_APP_HOST_URL + "profile", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === Tools.OK) {
          onSuccess();
        } else {
          onError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        onError("System error.");
      });
  },
};

export default Authentication;
