import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { Button } from "primereact/button";
import AdminPrc from "../AdminPrc";
import { schemas } from "./Schema";

const SelectEntity = ({
  entity,
  objectSelection = null,
  simpleSelection = null,
  processFn,
  additionalInfo = null,
}) => {
  const { token, setError } = useContext(AppContext);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    if (simpleSelection == null && objectSelection == null) {
      AdminPrc.getAllEntities(
        (typeof schemas[entity].package === "undefined"
          ? ""
          : schemas[entity].package + "$") + entity,
        token,
        (message) => setError(message),
        (data) => setEntities(data)
      );
    } else if (simpleSelection != null) {
      setEntities(simpleSelection.map((s) => ({ name: s })));
    } else {
      setEntities([...objectSelection]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center" style={{ width: "650px" }}>
      <div className="grid formgrid p-fluid w-12">
        {entities.map((c, index) => (
          <div className="field col-12 md:col-6" key={index}>
            <Button
              label={c.name}
              icon={typeof c.icon === "undefined" ? null : c.icon}
              iconPos="top"
              className="text-2xl p-button-outlined w-12 h-6rem"
              onClick={(e) => processFn(c, additionalInfo)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectEntity;
