import React, { useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const GetPhone = ({ processFn }) => {
  const [phone, setPhone] = useState("");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kbOnChange = (input) => {
    setPhone(input);
  };

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid m-5 shadow-2 m-3 p-3 w-9">
        <div className="field col-12">
          <InputText
            id="phone"
            className="w-full mb-3"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="field col-12">
          <Button
            label="Find Customer"
            icon="pi pi-search"
            onClick={(e) => {
              processFn(phone);
              setPhone("");
            }}
          />
        </div>
        <div className="field col-12">
          <Keyboard
            //input={password}
            preventMouseDownDefault={true}
            onChange={kbOnChange}
            inputName="phone"
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", " 0 ", "{bksp}"],
            }}
            theme="hg-theme-default hg-layout-numeric numeric-theme"
          />
        </div>
      </div>
    </div>
  );
};

export default GetPhone;
