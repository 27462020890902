import React, { useEffect } from "react";
import { Button } from "primereact/button";
import POSAdminPrc from "./POSAdminPrc";

const OrderSourceSelector = ({ processFuc, showHistory = false }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="grid formgrid p-fluid m-5 w-9">
        <div className="field col-12 md:col-6">
          <Button
            label="From Menu"
            className="text-3xl flex p-button-outlined m-2 p-button-success h-6rem"
            onClick={(e) => processFuc(POSAdminPrc.SELECT_FROM_MENU_ITEMS)}
          />
        </div>
        <div className="field col-12 md:col-6">
          <Button
            label="From Deals"
            className="text-3xl flex p-button-outlined m-2 p-button-info h-6rem"
            onClick={(e) => processFuc(POSAdminPrc.SELECT_FROM_DEAL_ITEMS)}
          />
        </div>
        {showHistory && (
          <div className="field col-12 md:col-6">
            <Button
              label="Last Order"
              className="text-3xl flex p-button-outlined m-2 p-button-primary h-6rem"
              onClick={(e) => processFuc(POSAdminPrc.LOAD_LAST_ORDER_CMD)}
            />
          </div>
        )}
        {showHistory && (
          <div className="field col-12 md:col-6">
            <Button
              label="History"
              className="text-3xl flex p-button-outlined m-2 p-button-warning h-6rem"
              onClick={(e) => processFuc(POSAdminPrc.LOAD_ORDER_HIS_CMD)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSourceSelector;
