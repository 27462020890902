import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

const LoadingPage = ({ message = " Please wait ... " }) => {
  return (
    <div className="card">
      <div className="flex justify-content-center flex-column flex-nowrap card-container">
        <div className="flex align-items-center justify-content-center">
          <h1>{message}</h1>
        </div>
        <div className="flex align-items-center justify-content-center">
          <ProgressSpinner
            className="flex"
            style={{ width: "250px", height: "250px" }}
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration=".5s"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
