import { Button } from "primereact/button";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import LoadingPage from "../../common/Utils/LoadingPage";
import Tools from "../../common/Tools";
import BPrc from "./BusinessPrc";
import { useSearchParams, useNavigate } from "react-router-dom";
import AppTools from "../AppTools";

const ShowPrize = (props) => {
  const { token, setError, profile, party, setHint } = useContext(AppContext);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const qr = searchParams.get("qr");
  const [data, setData] = useState(null);
  useEffect(() => {
    BPrc.getPrizeInfo(
      qr,
      (message) => {
        setError(message);
        navigate("/");
      },
      (info) => {
        setData(info);
      }
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data == null) return <LoadingPage />;
  return (
    <div className="flex justify-content-center pt-5">
      <div className="surface-card p-4 shadow-2 border-round">
        <div className="font-medium text-3xl text-900 mb-3">
          Prize Information
        </div>
        <div className="text-500 mb-5">
          Thank you for using Play Rewards campaign and congradulations on your
          {data.prize.campaign.mode === 3 ? " earned discount" : " win"}. <br />
          Here is the details about your{" "}
          {data.prize.campaign.mode === 3 ? " discount" : " prize"}.
        </div>
        <ul className="list-none p-0 m-0">
          <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Winner</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {data.prize.party.firstname.length >= 0
                ? data.prize.party.firstname + " " + data.prize.party.lastname
                : "Guest"}
            </div>
            <div className="w-6 md:w-2 flex justify-content-end"></div>
          </li>
          <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Business</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {data.business}
            </div>
            <div className="w-6 md:w-2 flex justify-content-end"></div>
          </li>
          <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Campagin</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {data.prize.campaign.name}{" "}
            </div>
            <div className="w-6 md:w-2 flex justify-content-end"></div>
          </li>
          <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">
              {data.prize.campaign.mode === 3 ? "Discount" : "Prize"}
            </div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
              {data.prize.campaign.mode === 3
                ? data.prize.campaign_item.odds + "%"
                : data.prize.campaign_item.prize}
            </div>
            <div className="w-6 md:w-2 flex justify-content-end"></div>
          </li>
          <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Status</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
              {data.prize.status === 1 ? "Claimed" : "Not claimed yet"}
            </div>
            <div className="w-6 md:w-2 flex justify-content-end">
              {data.prize.status === 0 &&
                profile != null &&
                profile.role === AppTools.BUSINESS &&
                party.id === data.prize.campaign.party_id && (
                  <Button
                    label="Claim"
                    icon="fas fa-hand-holding-usd"
                    className="p-button-outlined"
                    onClick={(e) =>
                      BPrc.claimPrize(
                        qr,
                        token,
                        (message) => setError(message),
                        () => {
                          setData({
                            ...data,
                            prize: { ...data.prize, status: 1 },
                          });
                          setHint("Prize is claimed now!");
                        }
                      )
                    }
                  />
                )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShowPrize;
